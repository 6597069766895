export const IMAGE_HEIGHT_LIST = 150;
export const IMAGE_WIDTH_LIST = 200;
export const IMAGE_HEIGHT_CHECK = 300;
export const IMAGE_WIDTH_CHECK = 400;
export const IMAGE_RATIO = 4 / 3;
export const WEIGHT_ENTITY = 'g';
export const VOLUME_ENTITY = 'ml';
export const TEMPERATURE_ENTITY = '°C';
export const MAX_INTEGER = 2147483647;
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const DEFAULT_LINE_ID = '-1';
