import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { helpDialogOpenSelector } from '../../../store';
import { irisSpacing } from '../../../theme';

import { HelpInfoComponent } from './help-info.component';

const PREFIX = 'HelpDialogComponent';

const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

export const HelpDialogComponent = () => {
  const isOpen = useSelector(helpDialogOpenSelector);
  return (
    <StyledDialog open={isOpen} maxWidth="md" className={classes.dialog} data-testid="helpDialog">
      <DialogContent>
        <HelpInfoComponent />
      </DialogContent>
    </StyledDialog>
  );
};
