import { createSelector } from 'reselect';

import { AppState } from '..';

export const authenticationStateSelector = (state: AppState) => state.authentication;
export const loggedInUserSelector = createSelector(
  authenticationStateSelector,
  (state) => state.loggedInUser
);
export const isLoggedInSelector = createSelector(loggedInUserSelector, (user) => !!user);
export const loginFailedSelector = createSelector(
  authenticationStateSelector,
  (state) => state.loginFailed
);
export const loginUndefinedErrorSelector = createSelector(
  authenticationStateSelector,
  (state) => state.undefinedError
);
export const loginRoleInsufficientSelector = createSelector(
  authenticationStateSelector,
  (state) => state.insufficientUserRole
);

export const changePasswordAfterResetFailedSelector = createSelector(
  authenticationStateSelector,
  (state) => state.changePasswordAfterResetFailed
);

export const changePasswordAfterResetSelector = createSelector(
  authenticationStateSelector,
  (state) => state.changePasswordAfterReset
);

export const changePasswordSuccessfulSelector = createSelector(
  authenticationStateSelector,
  (state) => state.changePasswordSuccessful
);

export const profileInfoSelector = createSelector(
  authenticationStateSelector,
  (state) => state.profileInfo
);
