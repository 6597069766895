import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { License } from '../../model';
import { fetchLicense, licenseSelector } from '../../store';

export const LicenseInfoComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['license']);
  const license: License | false | undefined = useSelector(licenseSelector);

  useEffect(() => {
    license === undefined && dispatch(fetchLicense());
  }, [dispatch, license]);

  return (
    <Box>
      <Typography>
        {license ? `${t('licenseInfoText')} ${license.customerName}` : t('noLicenseInfoText')}
      </Typography>
    </Box>
  );
};
