import {
  ProductionRunCheckExecution,
  ProductionRunCheckExecutionWeighingSampleResult,
} from '../../model';

export enum WeightsActionType {
  checkWeightInit = 'checkWeights/init',
  checkWeightFetch = 'checkWeight/fetch',
  checkWeightFetchSuccess = 'checkWeight/fetch/success',
  checkWeightFetchFailure = 'checkWeight/fetch/failure',
  checkWeightClear = 'checkWeight/clear',

  checkWeightExecute = 'measurement/execute',
  checkWeightExecuteSuccess = 'measurement/execute/success',
  checkWeightExecuteFailure = 'measurement/execute/failure',

  checkWeightSamplesFetch = 'measurements/fetchAll/',
  checkWeightSamplesFetchSuccess = 'measurements/fetchAll/success',
  checkWeightSamplesFetchFailure = 'measurements/fetchAll/failure',

  checkWeightSampleDelete = 'measurement/delete',
  checkWeightSampleDeleteSuccess = 'measurement/delete/success',
  checkWeightSampleDeleteFailure = 'measurement/delete/failure',

  checkWeightStateReset = 'checkWeightState/reset',
}

export const initCheckWeights = (sampleSize: number) => ({
  type: WeightsActionType.checkWeightInit,
  payload: { sampleSize },
});

export const fetchCheckWeight = (
  sampleIndex: number,
  productionRunId: string,
  executionId: string,
  scaleId: string
) => ({
  type: WeightsActionType.checkWeightFetch,
  payload: { sampleIndex, productionRunId, executionId, scaleId },
});

export const fetchCheckWeightSuccess = (
  sampleIndex: number,
  weight: ProductionRunCheckExecutionWeighingSampleResult
) => ({
  type: WeightsActionType.checkWeightFetchSuccess,
  payload: { sampleIndex, weight },
});

export const fetchCheckWeightFailure = (e: any) => ({
  type: WeightsActionType.checkWeightFetchFailure,
  payload: { e },
});

export const clearCheckWeight = (sampleIndex: number) => ({
  type: WeightsActionType.checkWeightClear,
  payload: { sampleIndex },
});

export const executeCheckWeight = (
  sampleNumber: number,
  productionRunId: string,
  executionId: string,
  scaleId: string,
  isTareWeighing: boolean
) => ({
  type: WeightsActionType.checkWeightExecute,
  payload: { sampleNumber, productionRunId, executionId, scaleId, isTareWeighing },
});

export const executeCheckWeightSuccess = (
  sampleNumber: number,
  weight: ProductionRunCheckExecutionWeighingSampleResult,
  isTaring: boolean
) => ({
  type: WeightsActionType.checkWeightExecuteSuccess,
  payload: { sampleNumber, weight, isTaring },
});

export const executeCheckWeightFailure = (e: any) => ({
  type: WeightsActionType.checkWeightExecuteFailure,
  payload: { e },
});

export const fetchCheckWeightSamples = (productionRunId: string, executionId: string) => ({
  type: WeightsActionType.checkWeightSamplesFetch,
  payload: { productionRunId, executionId },
});

export const fetchCheckWeightSamplesSuccess = (checkExecution: ProductionRunCheckExecution) => ({
  type: WeightsActionType.checkWeightSamplesFetchSuccess,
  payload: { checkExecution },
});

export const fetchCheckWeightSamplesFailure = () => ({
  type: WeightsActionType.checkWeightSamplesFetchFailure,
});

export const deleteCheckWeightSample = (
  productionRunId: string,
  executionId: string,
  sampleNumber: number
) => ({
  type: WeightsActionType.checkWeightSampleDelete,
  payload: { productionRunId, executionId, sampleNumber },
});

export const deleteCheckWeightSampleSuccess = (sampleNumber: number) => ({
  type: WeightsActionType.checkWeightSampleDeleteSuccess,
  payload: { sampleNumber },
});

export const deleteCheckWeightSampleFailure = () => ({
  type: WeightsActionType.checkWeightSampleDeleteFailure,
});

export const resetCheckWeightState = () => ({
  type: WeightsActionType.checkWeightStateReset,
});
