import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFertigPackVStatistics } from '../../../helper';
import { ProductionRun } from '../../../model';
import { getPixels, irisSpacing } from '../../../theme';
import { BarChartComponent, minWidth } from '../../bar-chart/bar-chart.component';
import { ContainerInside } from '../../structure';

const PREFIX = 'FertigPackVCategoriesComponent';

const classes = {
  typography: `${PREFIX}-typography`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: `${minWidth + 2 * getPixels(irisSpacing.container.p)}px`,

  [`& .${classes.typography}`]: {
    marginTop: theme.spacing(2),
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
}

export const FertigPackVCategoriesComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const { productionRun } = props;
  const statistics = getFertigPackVStatistics(productionRun);

  return (
    <StyledPaper data-testid={'fertigPackVCategoriesComponent'}>
      <ContainerInside>
        <Typography variant="h4">{t('data:check.countsPerCategory')}</Typography>
        {statistics?.checkSamplesDone && statistics.checkSamplesDone > 0 && (
          <Typography className={classes.typography} data-testid="samplesAmountCategories">
            {`${t('data:check.samplesAmount')} `}
            {statistics.checkSamplesDone}
          </Typography>
        )}
        <Box sx={{ marginTop: 3 }}>
          <BarChartComponent
            productionRun={productionRun}
            noCheckSamplesText={t('data:productionRun.noFertigPackVCheckSamples')}
          />
        </Box>
      </ContainerInside>
    </StyledPaper>
  );
};
