import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { User } from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  UsersActionType,
  fetchUser,
  fetchUserSuccess,
  fetchUserFailure,
  requestResetPassword,
  requestResetPasswordFailure,
  requestResetPasswordSuccess,
} from './users.actions';

const usersUrl = 'users';
const requestResetUrl = 'request-reset';

function* getUserById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchUser>).payload;
  try {
    const user = yield* call<[boolean, string], SagaRequest<User>>(
      SagaRequestHelper.get,
      true,
      `${usersUrl}/${id}`
    );
    yield put(fetchUserSuccess(user));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchUserFailure);
  }
}

function* requestResetUserPasswordByUsername(action: Action) {
  const { username } = (action as ReturnType<typeof requestResetPassword>).payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<string>>(
      SagaRequestHelper.post,
      false,
      `${usersUrl}/${requestResetUrl}`,
      {
        body: JSON.stringify({ username }),
      }
    );
    yield put(requestResetPasswordSuccess());
  } catch (e: any) {
    yield put(requestResetPasswordFailure());
  }
}

export function* fetchUserSaga() {
  yield takeLatest(UsersActionType.userFetch, getUserById);
}

export function* requestResetUserPasswordSaga() {
  yield takeLatest(UsersActionType.userRequestResetPassword, requestResetUserPasswordByUsername);
}

export function* usersSaga() {
  yield fork(fetchUserSaga);
  yield fork(requestResetUserPasswordSaga);
}
