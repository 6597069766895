import { ProductionRunCheckExecutionSample } from '.';

export interface ProductionRunCheckExecution {
  id: string;
  triggeredOn: string;
  checkedOn?: string;
  status: ResultStatus;
  samples?: ProductionRunCheckExecutionSample[];
}

export enum ResultStatus {
  Pending = 1,
  Done = 2,
  Cancelled = 3,
  Skipped = 4,
  Paused = 5,
}
