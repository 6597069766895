import { ProductionRunCheckExecution } from '../../model';

export enum CheckExecutionsWebsocketActionType {
  checkExecutionChanged = 'checkExecution/changed/',
}

export const changedCheckExecution = (checkExecution: ProductionRunCheckExecution) => ({
  type: CheckExecutionsWebsocketActionType.checkExecutionChanged,
  payload: { checkExecution },
});
