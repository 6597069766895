import ArrowBack from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import { DisruptionButton } from '../../components/buttons/buttons.component';
import { DisruptionDialogComponent } from '../../components/disruption/disruption-dialog.component';
import { hasActiveTareDetermination, hasActiveTestRun } from '../../helper';
import { AppRoutePath } from '../../routes/routes';
import {
  clearProductionRun,
  closeDisruptionDialog,
  currentProductionRunSelector,
  disruptionDialogOpenSelector,
  fetchProductionRun,
  openDisruptionDialog,
  productionRunsListSelector,
  productionRunsWebsocketConfig,
  resolveDisruption,
  startDisruption,
  startWsConnection,
  stopProductionRun,
} from '../../store';
import { CheckPage } from '../check/check.page';

import { ProductionRunPage } from './production-run.page';

const PREFIX = 'ProductionRunContentComponent';

const classes = {
  noProductionRun: `${PREFIX}-noProductionRun`,
  backLink: `${PREFIX}-backLink`,
  flexEndContainer: `${PREFIX}-flexEndContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.noProductionRun}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '160px',
  },
  [`& .${classes.backLink}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.flexEndContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '-50px',
  },
}));

export const ProductionRunContentComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const { id } = useParams<{ id: string }>();
  const productionRuns = useSelector(productionRunsListSelector);
  const productionRunPreloaded = id ? productionRuns[id] : undefined;
  const productionRun = useSelector(currentProductionRunSelector) || productionRunPreloaded;
  const isDisruptionDialogOpen = useSelector(disruptionDialogOpenSelector);
  const disrupted = productionRun && productionRun.disrupted;
  const nextOpenCheckExecution =
    productionRun &&
    productionRun.openCheckExecutions &&
    productionRun.openCheckExecutions.length > 0
      ? productionRun.openCheckExecutions[0]
      : undefined;

  const testRunActive = productionRun && hasActiveTestRun(productionRun);
  const tareDeterminationActive = productionRun && hasActiveTareDetermination(productionRun);

  useEffect(() => {
    dispatch(startWsConnection(productionRunsWebsocketConfig));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductionRun(id));
    return () => {
      dispatch(clearProductionRun());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (disrupted) dispatch(openDisruptionDialog());
    else dispatch(closeDisruptionDialog());
  }, [dispatch, disrupted]);

  const startDisruptionForProductionRun = () => {
    productionRun && dispatch(startDisruption(productionRun.id));
  };

  const resolveDisruptionForProductionRun = (reason: string, deviceId?: string) => {
    productionRun && dispatch(resolveDisruption(productionRun.id, reason, deviceId));
  };

  const stopProductionOrderRun = (reason?: string, deviceId?: string) => {
    productionRun && dispatch(stopProductionRun(productionRun.id, reason, disrupted, deviceId));
  };

  let content;

  if (productionRun && nextOpenCheckExecution) {
    content = (
      <CheckPage
        productionRun={productionRun}
        openCheckExecution={nextOpenCheckExecution}
        isDisruptionDialogOpen={isDisruptionDialogOpen}
      />
    );
  } else if (productionRun) {
    content = <ProductionRunPage productionRun={productionRun} />;
  } else {
    content = (
      <>
        <Box className={classes.noProductionRun}>
          <Typography variant="h1">{t('data:productionRun.noProductionRun')}</Typography>
        </Box>
      </>
    );
  }

  return (
    <Root>
      {productionRun && (
        <DisruptionDialogComponent
          handleResolve={resolveDisruptionForProductionRun}
          handleStop={stopProductionOrderRun}
          productionRun={productionRun}
        />
      )}
      {!isDisruptionDialogOpen && (
        <Box>
          <Link
            className={classes.backLink}
            component={NavLink}
            to={AppRoutePath.root}
            underline="hover"
          >
            <ArrowBack />
            {t('data:productionRun.backLink')}
          </Link>
          {productionRun && !testRunActive && !tareDeterminationActive && (
            <Box className={classes.flexEndContainer}>
              <DisruptionButton
                variant="outlined"
                onClick={startDisruptionForProductionRun}
                startIcon={<WarningIcon />}
              >
                {t('data:disruption.startDisruption')}
              </DisruptionButton>
            </Box>
          )}
        </Box>
      )}
      {content}
    </Root>
  );
};
