import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filterIssues, getFertigPackVStatistics, getSuffix } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';
import {
  calcFertigPackTolerances,
  clearFertigPackTolerances,
  fertigPackTolerancesSelector,
} from '../../../store';
import { FormattedNumberOutputComponent } from '../../formatted-number-output/formatted-number-output.component';
import { IssuesComponent } from '../../issue/issues.component';
import { ContainerInside } from '../../structure';
import { ToleranceBarComponent } from '../../tolerance-bar/tolerance-bar.component';

const PREFIX = 'AverageWeightComponent';

const classes = {
  outerBox: `${PREFIX}-outerBox`,
  infoBox: `${PREFIX}-infoBox`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.outerBox}`]: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'column',
  },

  [`& .${classes.infoBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
}

export const AverageWeightComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();
  const { productionRun } = props;
  const nominalValue = productionRun.article.nominalValue;
  const densityValue = productionRun.article.densityValue;
  const currentTolerances = useSelector(fertigPackTolerancesSelector);
  const statistics = getFertigPackVStatistics(productionRun);
  const filteredIssues = filterIssues(productionRun.issues, [IssueCode.AverageWeightBelowNominal]);

  const hasAverage =
    !!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume;
  const hasStandardDeviation =
    !!statistics?.checkSamplesStandardDeviation ||
    !!statistics?.checkSamplesStandardVolumeDeviation;
  const standardDeviation = statistics?.checkSamplesStandardVolumeDeviation
    ? statistics?.checkSamplesStandardVolumeDeviation
    : statistics?.checkSamplesStandardDeviation;
  const averageValue = statistics?.checkSamplesAverageVolume
    ? statistics?.checkSamplesAverageVolume
    : statistics?.checkSamplesAverageWeight;
  const suffix = getSuffix(!!productionRun.article.volumeValue);
  useEffect(() => {
    if (densityValue && nominalValue) {
      dispatch(calcFertigPackTolerances(nominalValue, densityValue));
    } else if (nominalValue) {
      dispatch(calcFertigPackTolerances(nominalValue));
    }
    return () => {
      dispatch(clearFertigPackTolerances());
    };
  }, [dispatch, nominalValue, densityValue]);

  return (
    <StyledPaper data-testid={'averageWeightComponent'}>
      <ContainerInside>
        <Box className={classes.outerBox}>
          <Box mb={2}>
            <Typography align="left" variant="h3">
              {hasAverage ? t('data:check.fertigPackVCurrentAverage') : t('data:check.fertigPackV')}
            </Typography>
            {statistics?.checkSamplesDone && statistics.checkSamplesDone > 0 && (
              <Box className={classes.infoBox} data-testid="averageWeightSamplesAmountInfo">
                <Typography data-testid="amountSamplesDisplay">
                  {`${t('data:check.samplesAmount')} `}
                  {statistics.checkSamplesDone}
                </Typography>

                {hasAverage && (
                  <>
                    <Typography marginX={1}>{'|'}</Typography>
                    <Typography data-testid="averageWeightDisplay">
                      {`${t('data:check.averageWeight')} `}
                      <FormattedNumberOutputComponent value={averageValue} suffix={suffix} />
                    </Typography>
                  </>
                )}

                {hasStandardDeviation && (
                  <>
                    <Typography marginX={1}>{'|'}</Typography>
                    <Typography data-testid="standardDeviationDisplay">
                      {`${t('data:check.standardDeviation')} `}
                      <FormattedNumberOutputComponent
                        value={standardDeviation}
                        suffix={suffix}
                        decimalScale={2}
                      />
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ToleranceBarComponent
            nominalValue={
              productionRun.article.volumeValue
                ? productionRun.article.volumeValue
                : productionRun.article.nominalValue
            }
            tolerances={currentTolerances}
            lowerTolerance={productionRun.article.lowerTolerance}
            upperTolerance={productionRun.article.upperTolerance}
            averageValue={averageValue}
            averagePassed={statistics?.checkSamplesAverageWeightPassed}
            averageSufficient={statistics?.checkSamplesAverageWeightSufficient}
            averageFailed={statistics?.checkSamplesAverageWeightFailed}
          />
        </Box>
        {filteredIssues.length > 0 && (
          <Box sx={{ marginTop: 3 }}>
            <IssuesComponent issues={filteredIssues} />
          </Box>
        )}
      </ContainerInside>
    </StyledPaper>
  );
};
