import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { getFertigPackVStatistics } from '../../helper';
import { ProductionRun } from '../../model';
import { irisSpacing } from '../../theme';

import { TU1ViolationInfoComponent } from './tu1-violation-info.component';

const PREFIX = 'TU1ViolationDialogComponent';

const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const TU1ViolationDialogComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const isTu1PopOverOpen = !!productionRun.fertigPackVData.hasCriticalTU1Violation;

  return (
    <StyledDialog
      open={isTu1PopOverOpen}
      maxWidth="md"
      className={classes.dialog}
      data-testid="tu1Dialog"
    >
      <DialogContent>
        <TU1ViolationInfoComponent
          tu1percentage={getFertigPackVStatistics(productionRun)?.percentageSamplesTU1}
        />
      </DialogContent>
    </StyledDialog>
  );
};
