import { styled } from '@mui/material/styles';

import { irisCheckResultColors, irisCustomColors } from '../../theme';

export interface StylePropsWrapper {
  styleProps: {
    averageValueMarginLeft?: string;
    lowerToleranceRatio: number;
    upperToleranceRatio: number;
  };
}

const PREFIX = 'ToleranceBarComponent';
export const toleranceBarClasses = {
  outerContainer: `${PREFIX}-outerContainer`,
  labelContainer: `${PREFIX}-labelContainer`,
  labelItemContainer: `${PREFIX}-labelItemContainer`,
  labelPaddingItem: `${PREFIX}-labelPaddingItem`,
  barContainer: `${PREFIX}-barContainer`,
  toleranceBarContainer: `${PREFIX}-toleranceBarContainer`,
  bar: `${PREFIX}-bar`,
  lowerToleranceYellowBar: `${PREFIX}-lowerToleranceYellowBar`,
  lowerToleranceGreenBar: `${PREFIX}-lowerToleranceGreenBar`,
  upperToleranceGreenBar: `${PREFIX}-upperToleranceGreenBar`,
  upperToleranceYellowBar: `${PREFIX}-upperToleranceYellowBar`,
  redBar: `${PREFIX}-redBar`,
  yellowBar: `${PREFIX}-yellowBar`,
  greenBar: `${PREFIX}-greenBar`,
  verticalLine: `${PREFIX}-verticalLine`,
  averageContainer: `${PREFIX}-averageContainer`,
  moveContainer: `${PREFIX}-moveContainer`,
  averageBoxContainer: `${PREFIX}-averageBoxContainer`,
  averageBox: `${PREFIX}-averageBox`,
  verticalLineStrong: `${PREFIX}-verticalLineStrong`,
  averageBoxBottomSpace: `${PREFIX}-averageBoxBottomSpace`,
  customToleranceBottomSpace: `${PREFIX}-customToleranceBottomSpace`,
  lineAndLabelBox: `${PREFIX}-lineAndLabelBox`,
  absoluteLabelItemContainer: `${PREFIX}-absoluteLabelItemContainer`,
  rightAlignedLabel: `${PREFIX}-rightAlignedLabel`,
  leftAlignedLabel: `${PREFIX}-leftAlignedLabel`,
  xAcross: `${PREFIX}-xAcross`,
};

export const ToleranceBarRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  width: '100%',
  [`& .${toleranceBarClasses.outerContainer}`]: {
    position: 'relative',
    minWidth: '550px',
    justifyContent: 'center',
  },

  [`& .${toleranceBarClasses.labelContainer}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  [`& .${toleranceBarClasses.labelItemContainer}`]: {
    width: `${100 / 6}%`,
    textAlign: 'center',
    flexDirection: 'column',
  },

  [`& .${toleranceBarClasses.labelPaddingItem}`]: {
    width: `${100 / 12}%`,
  },

  [`& .${toleranceBarClasses.barContainer}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
    marginTop: theme.spacing(1),
  },

  [`& .${toleranceBarClasses.toleranceBarContainer}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
  },

  [`& .${toleranceBarClasses.bar}`]: {
    width: `${100 / 6}%`,
    height: theme.spacing(2),
  },

  [`& .${toleranceBarClasses.lowerToleranceYellowBar}`]: {
    width: `${100 - styleProps.lowerToleranceRatio}%`,
    height: theme.spacing(2),
  },

  [`& .${toleranceBarClasses.lowerToleranceGreenBar}`]: {
    width: `${styleProps.lowerToleranceRatio}%`,
    height: theme.spacing(2),
  },

  [`& .${toleranceBarClasses.upperToleranceGreenBar}`]: {
    width: `${styleProps.upperToleranceRatio}%`,
    height: theme.spacing(2),
  },

  [`& .${toleranceBarClasses.upperToleranceYellowBar}`]: {
    width: `${100 - styleProps.upperToleranceRatio}%`,
    height: theme.spacing(2),
  },

  [`& .${toleranceBarClasses.redBar}`]: {
    backgroundColor: irisCheckResultColors.failed,
  },

  [`& .${toleranceBarClasses.yellowBar}`]: {
    backgroundColor: irisCheckResultColors.sufficient,
  },

  [`& .${toleranceBarClasses.greenBar}`]: {
    backgroundColor: irisCheckResultColors.passed,
  },

  [`& .${toleranceBarClasses.verticalLine}`]: {
    width: '1px',
    height: theme.spacing(4),
    borderRight: '1px',
    borderRightStyle: 'solid',
    borderRightColor: irisCustomColors.irisGrayDimmed,
  },

  [`& .${toleranceBarClasses.averageContainer}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    marginTop: '36px',
  },

  [`& .${toleranceBarClasses.moveContainer}`]: {
    marginLeft: styleProps.averageValueMarginLeft,
  },

  [`& .${toleranceBarClasses.averageBoxContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${toleranceBarClasses.averageBox}`]: {
    padding: theme.spacing(1),
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 'bolder',
    border: 'solid 2px',
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${toleranceBarClasses.verticalLineStrong}`]: {
    width: '1px',
    height: theme.spacing(2),
    borderRight: '2px',
    borderRightStyle: 'solid',
    borderRightColor: irisCustomColors.irisBlack,
  },

  [`& .${toleranceBarClasses.averageBoxBottomSpace}`]: {
    paddingBottom: theme.spacing(3),
  },

  [`& .${toleranceBarClasses.customToleranceBottomSpace}`]: {
    paddingBottom: theme.spacing(7),
  },

  [`& .${toleranceBarClasses.lineAndLabelBox}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${toleranceBarClasses.absoluteLabelItemContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    position: 'absolute',
    marginTop: theme.spacing(4.5),
  },

  [`& .${toleranceBarClasses.rightAlignedLabel}`]: {
    alignItems: 'flex-end',
  },

  [`& .${toleranceBarClasses.leftAlignedLabel}`]: {
    alignItems: 'flex-start',
  },

  [`& .${toleranceBarClasses.xAcross}`]: {
    marginRight: theme.spacing(1),
  },
}));
