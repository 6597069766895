import { TextField } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface OwnProps {
  label: string;
  testId?: string;
  name?: string;
  autocomplete?: string;
}

export const PasswordInputComponent = (props: OwnProps) => {
  const { errors, control } = useFormContext();
  const { t } = useTranslation(['form']);
  const { testId, label, name, autocomplete } = props;
  const inputName = name || 'password';
  const inputTestId = testId || 'password-input';

  return (
    <Controller
      defaultValue=""
      as={
        <TextField
          fullWidth={true}
          variant="outlined"
          type="password"
          required
          autoComplete={autocomplete}
        />
      }
      control={control}
      name={inputName}
      label={label}
      inputProps={{
        'data-testid': inputTestId,
      }}
      rules={{
        required: { value: true, message: t('form:fieldIsRequired') },
        minLength: { value: 6, message: t('form:minLength', { min: 6 }) },
        pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,256}$/,
          message: t('form:passwordValidationError'),
        },
      }}
      error={errors[inputName] !== undefined}
      helperText={errors[inputName] && errors[inputName].message}
    />
  );
};
