import {
  ProductionRun,
  OpenCheckExecution,
  ProductionRunDisruption,
  CheckAnswer,
  CheckAttributeType,
  QueryParams,
  ToleranceResult,
} from '../../model';

export enum ProductionRunsActionType {
  productionRunsFetchAll = 'productionRuns/fetchAll/',
  productionRunsFetchAllSuccess = 'productionRuns/fetchAll/success',
  productionRunsFetchAllFailure = 'productionRuns/fetchAll/failure',
  productionRunsReloadSilent = 'productionRuns/reloadSilent/',
  productionRunsReloadSilentSuccess = 'productionRuns/reloadSilent/success',
  productionRunsReloadSilentFailure = 'productionRuns/reloadSilent/failure',
  productionRunFetch = 'productionRun/fetch/',
  productionRunFetchSuccess = 'productionRun/fetch/success',
  productionRunFetchFailure = 'productionRun/fetch/failure',
  productionRunStop = 'productionRun/stop/',
  productionRunStopSuccess = 'productionRun/stop/success',
  productionRunStopFailure = 'productionRun/stop/failure',
  productionRunCheckConfirm = 'productionRun/confirmCheck',
  productionRunCheckConfirmSuccess = 'productionRun/confirmCheck/success',
  productionRunCheckConfirmFailure = 'productionRun/confirmCheck/failure',
  productionRunDisruptionStart = 'productionRun/startDisruption',
  productionRunDisruptionStartSuccess = 'productionRun/startDisruption/success',
  productionRunDisruptionStartFailure = 'productionRun/startDisruption/failure',
  productionRunDisruptionResolve = 'productionRun/resolveDisruption',
  productionRunDisruptionResolveSuccess = 'productionRun/resolveDisruption/success',
  productionRunDisruptionResolveFailure = 'productionRun/resolveDisruption/failure',
  productionRunOpenSkipCheckDialog = 'productionRun/skipDialog/open',
  productionRunCloseSkipCheckDialog = 'productionRun/skipDialog/close',
  productionRunCheckSkip = 'productionRun/skipCheck',
  productionRunCheckSkipSuccess = 'productionRun/skipCheck/success',
  productionRunCheckSkipFailure = 'productionRun/skipCheck/failure',
  productionRunAppendChecks = 'productionRun/appendChecks/',
  productionRunAppendChecksSuccess = 'productionRun/appendChecks/success',
  productionRunAppendChecksFailure = 'productionRun/appendChecks/failure',
  productionRunClear = 'productionRun/clear/',
  productionRunCalcFertigPackTolerances = 'productionRun/calcFertigPackTolerances',
  productionRunCalcFertigPackTolerancesSuccess = 'productionRun/calcFertigPackTolerances/success',
  productionRunCalcFertigPackTolerancesFailure = 'productionRun/calcFertigPackTolerances/failure',
  productionRunFertigPackTolerancesClear = 'productionRun/clearFertigPackTolerances',
  productionRunOpenDisruptionDialog = 'productionRun/disruptionDialog/open',
  productionRunCloseDisruptionDialog = 'productionRun/disruptionDialog/close',
  productionRunOpenHelpDialog = 'productionRun/helpDialog/open',
  productionRunCloseHelpDialog = 'productionRun/helpDialog/close',
  productionRunsCurrentPageSet = 'productionRuns/setPage/',
  productionRunPause = 'productionRun/pause',
  productionRunPauseSuccess = 'productionRun/pause/success',
  productionRunPauseFailure = 'productionRun/pause/failure',
  productionRunContinue = 'productionRun/continue',
  productionRunContinueSuccess = 'productionRun/continue/success',
  productionRunContinueFailure = 'productionRun/continue/failure',
}

export const fetchProductionRuns = (query: QueryParams) => ({
  type: ProductionRunsActionType.productionRunsFetchAll,
  payload: { query },
});

export const fetchProductionRunsSuccess = (productionRuns: ProductionRun[], total: number) => ({
  type: ProductionRunsActionType.productionRunsFetchAllSuccess,
  payload: { productionRuns, total },
});

export const fetchProductionRunsFailure = () => ({
  type: ProductionRunsActionType.productionRunsFetchAllFailure,
});

export const reloadProductionRunsSilent = (query: QueryParams) => ({
  type: ProductionRunsActionType.productionRunsReloadSilent,
  payload: { query },
});

export const reloadProductionRunsSilentSuccess = (
  productionRuns: ProductionRun[],
  total: number
) => ({
  type: ProductionRunsActionType.productionRunsReloadSilentSuccess,
  payload: { productionRuns, total },
});

export const reloadProductionRunsSilentFailure = () => ({
  type: ProductionRunsActionType.productionRunsReloadSilentFailure,
});

export const fetchProductionRun = (id: string) => ({
  type: ProductionRunsActionType.productionRunFetch,
  payload: { id },
});

export const fetchProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunFetchSuccess,
  payload: { productionRun },
});

export const fetchProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunFetchFailure,
});

export const stopProductionRun = (
  id: string,
  reason?: string,
  forced?: boolean,
  deviceId?: string
) => ({
  type: ProductionRunsActionType.productionRunStop,
  payload: { id, reason, forced, deviceId },
});

export const stopProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunStopSuccess,
  payload: { productionRun },
});

export const stopProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunStopFailure,
});

export const confirmCheckForProductionRun = (
  productionRunId: string,
  openCheckExecution: OpenCheckExecution,
  checkAttributeType: CheckAttributeType,
  answer?: CheckAnswer
) => ({
  type: ProductionRunsActionType.productionRunCheckConfirm,
  payload: {
    productionRunId,
    openCheckExecution,
    checkAttributeType,
    answer,
  },
});

export const confirmCheckForProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunCheckConfirmSuccess,
  payload: { productionRun },
});

export const confirmCheckForProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunCheckConfirmFailure,
});

export const startDisruption = (id: string) => ({
  type: ProductionRunsActionType.productionRunDisruptionStart,
  payload: { id },
});

export const startDisruptionSuccess = (id: string, disruption: ProductionRunDisruption) => ({
  type: ProductionRunsActionType.productionRunDisruptionStartSuccess,
  payload: { id, disruption },
});

export const startDisruptionFailure = () => ({
  type: ProductionRunsActionType.productionRunDisruptionStartFailure,
});

export const resolveDisruption = (id: string, reason: string, deviceId?: string) => ({
  type: ProductionRunsActionType.productionRunDisruptionResolve,
  payload: { id, reason, deviceId },
});

export const resolveDisruptionSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunDisruptionResolveSuccess,
  payload: { productionRun },
});

export const resolveDisruptionFailure = () => ({
  type: ProductionRunsActionType.productionRunDisruptionResolveFailure,
});

export const openSkipCheckDialog = () => ({
  type: ProductionRunsActionType.productionRunOpenSkipCheckDialog,
});

export const closeSkipCheckDialog = () => ({
  type: ProductionRunsActionType.productionRunCloseSkipCheckDialog,
});

export const skipCheckForProductionRun = (
  productionRunId: string,
  openCheckExecution: OpenCheckExecution,
  skipReason: string
) => ({
  type: ProductionRunsActionType.productionRunCheckSkip,
  payload: {
    productionRunId,
    openCheckExecution,
    skipReason,
  },
});

export const skipCheckForProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunCheckSkipSuccess,
  payload: { productionRun },
});

export const skipCheckForProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunCheckSkipFailure,
});

export const appendProductionRunChecks = (id: string) => ({
  type: ProductionRunsActionType.productionRunAppendChecks,
  payload: { id },
});

export const appendProductionRunChecksSuccess = (id: string) => ({
  type: ProductionRunsActionType.productionRunAppendChecksSuccess,
  payload: { id },
});

export const appendProductionRunChecksFailure = () => ({
  type: ProductionRunsActionType.productionRunAppendChecksFailure,
});

export const clearProductionRun = () => ({
  type: ProductionRunsActionType.productionRunClear,
});

export const calcFertigPackTolerances = (nominalValue: number, densityValue?: number) => ({
  type: ProductionRunsActionType.productionRunCalcFertigPackTolerances,
  payload: { nominalValue, densityValue },
});

export const calcFertigPackTolerancesSuccess = (tolerances: ToleranceResult) => ({
  type: ProductionRunsActionType.productionRunCalcFertigPackTolerancesSuccess,
  payload: { tolerances },
});

export const calcFertigPackTolerancesFailure = () => ({
  type: ProductionRunsActionType.productionRunCalcFertigPackTolerancesFailure,
});

export const clearFertigPackTolerances = () => ({
  type: ProductionRunsActionType.productionRunFertigPackTolerancesClear,
});

export const openDisruptionDialog = () => ({
  type: ProductionRunsActionType.productionRunOpenDisruptionDialog,
});

export const closeDisruptionDialog = () => ({
  type: ProductionRunsActionType.productionRunCloseDisruptionDialog,
});

export const openHelpDialog = () => ({
  type: ProductionRunsActionType.productionRunOpenHelpDialog,
});

export const closeHelpDialog = () => ({
  type: ProductionRunsActionType.productionRunCloseHelpDialog,
});

export const setProductionRunsCurrentPageIndex = (pageIndex: number) => ({
  type: ProductionRunsActionType.productionRunsCurrentPageSet,
  payload: { pageIndex },
});

export const pauseProductionRun = (id: string) => ({
  type: ProductionRunsActionType.productionRunPause,
  payload: { id },
});

export const pauseProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunPauseSuccess,
  payload: { productionRun },
});

export const pauseProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunPauseFailure,
});

export const continueProductionRun = (id: string) => ({
  type: ProductionRunsActionType.productionRunContinue,
  payload: { id },
});

export const continueProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunContinueSuccess,
  payload: { productionRun },
});

export const continueProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunContinueFailure,
});
