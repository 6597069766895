import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { queryDataToUrl } from '../../helper';
import { SagaRequest, SagaRequestHelper } from '../../http';
import {
  ProductionRun,
  ProductionRunDisruption,
  ProductionRunStatus,
  ToleranceResult,
} from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  ProductionRunsActionType,
  fetchProductionRunsSuccess,
  fetchProductionRunsFailure,
  stopProductionRun as stopProductionRunAction,
  stopProductionRunSuccess,
  stopProductionRunFailure,
  confirmCheckForProductionRun as confirmCheckForProductionRunAction,
  confirmCheckForProductionRunSuccess,
  confirmCheckForProductionRunFailure,
  skipCheckForProductionRun as skipCheckForProductionRunAction,
  skipCheckForProductionRunSuccess,
  skipCheckForProductionRunFailure,
  startDisruption as startDisruptionAction,
  startDisruptionSuccess,
  startDisruptionFailure,
  resolveDisruption as resolveDisruptionAction,
  resolveDisruptionSuccess,
  resolveDisruptionFailure,
  calcFertigPackTolerances as calcFertigPackTolerancesAction,
  calcFertigPackTolerancesSuccess,
  calcFertigPackTolerancesFailure,
  appendProductionRunChecksSuccess,
  appendProductionRunChecksFailure,
  fetchProductionRun,
  fetchProductionRunSuccess,
  fetchProductionRunFailure,
  fetchProductionRuns,
  reloadProductionRunsSilent,
  reloadProductionRunsSilentSuccess,
  reloadProductionRunsSilentFailure,
  pauseProductionRun as pauseProductionRunAction,
  pauseProductionRunSuccess,
  pauseProductionRunFailure,
  continueProductionRun as continueProductionRunAction,
  continueProductionRunSuccess,
  continueProductionRunFailure,
} from './production-runs.actions';

const productionRunUrl = 'production/runs';
const articlesUrl = 'articles';
const fertigpackvUrl = 'fertigpackv';
const checks = 'checks';
const stop = 'stop';
const executions = 'executions';
const disruptions = 'disruptions';
const start = 'start';
const resolve = 'resolve';
const append = 'append';
const runningFilter = '?running=1';
const pause = 'pause';
const advance = 'continue';

function* fetchRuns(action: Action) {
  const { query } = (
    action as ReturnType<typeof fetchProductionRuns | typeof reloadProductionRunsSilent>
  ).payload;
  if (!query.lineId) return;

  const orderQueryUrl = queryDataToUrl(query);
  return yield* call<
    [boolean, string],
    SagaRequest<{ data: ProductionRun[]; metadata: { total: number } }>
  >(SagaRequestHelper.get, true, `${productionRunUrl}${runningFilter}&${orderQueryUrl}`);
}

function* getProductionRuns(action: Action) {
  try {
    const response = yield* fetchRuns(action);
    if (response) yield put(fetchProductionRunsSuccess(response.data, response.metadata.total));
    else return;
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunsFailure);
  }
}

function* reloadProductionRuns(action: Action) {
  try {
    const response = yield* fetchRuns(action);
    if (response)
      yield put(reloadProductionRunsSilentSuccess(response.data, response.metadata.total));
    else return;
  } catch (e: any) {
    yield sagaErrorHandler(e, reloadProductionRunsSilentFailure);
  }
}

function* getProductionRunById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchProductionRun>).payload;
  try {
    const productionRun = yield* call<[boolean, string], SagaRequest<ProductionRun>>(
      SagaRequestHelper.get,
      true,
      `${productionRunUrl}/${id}`
    );
    yield put(fetchProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunFailure);
  }
}

function* stopProductionRun(action: Action) {
  const { id, reason, forced, deviceId } = (action as ReturnType<typeof stopProductionRunAction>)
    .payload;
  try {
    const productionRun = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRun>
    >(SagaRequestHelper.put, true, `${productionRunUrl}/${id}/${stop}`, {
      body: JSON.stringify({ reason, forced, deviceId }),
    });
    yield put(stopProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, stopProductionRunFailure);
  }
}

function* confirmCheckForProductionRun(action: Action) {
  const { productionRunId, openCheckExecution, checkAttributeType, answer } = (
    action as ReturnType<typeof confirmCheckForProductionRunAction>
  ).payload;

  const requestData = () => {
    if (!answer) return { body: JSON.stringify({ checkAttributeType }) };
    else {
      return {
        body: JSON.stringify({
          checkAttributeType,
          ...answer,
        }),
      };
    }
  };

  try {
    const productionRun = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRun>
    >(
      SagaRequestHelper.post,
      true,
      `${productionRunUrl}/${productionRunId}/${checks}/${executions}/${openCheckExecution.productionRunCheckExecutionId}`,
      requestData()
    );
    yield put(confirmCheckForProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, confirmCheckForProductionRunFailure);
  }
}

function* skipCheckForProductionRun(action: Action) {
  const { productionRunId, openCheckExecution, skipReason } = (
    action as ReturnType<typeof skipCheckForProductionRunAction>
  ).payload;
  try {
    const productionRun = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRun>
    >(
      SagaRequestHelper.post,
      true,
      `${productionRunUrl}/${productionRunId}/${checks}/${executions}/${openCheckExecution.productionRunCheckExecutionId}`,
      {
        body: JSON.stringify({
          skipReason,
        }),
      }
    );
    yield put(skipCheckForProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, skipCheckForProductionRunFailure);
  }
}

function* appendChecksForProductionRun(action: Action) {
  const { id } = (action as ReturnType<typeof stopProductionRunAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<ProductionRun>>(
      SagaRequestHelper.post,
      true,
      `${productionRunUrl}/${id}/${checks}/${append}`,
      {}
    );
    yield put(appendProductionRunChecksSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, appendProductionRunChecksFailure);
  }
}

function* startDisruption(action: Action) {
  const { id } = (action as ReturnType<typeof startDisruptionAction>).payload;
  try {
    const disruption = yield* call<[boolean, string, {}], SagaRequest<ProductionRunDisruption>>(
      SagaRequestHelper.post,
      true,
      `${productionRunUrl}/${id}/${disruptions}/${start}`,
      {}
    );
    yield put(startDisruptionSuccess(id, disruption));
  } catch (e: any) {
    yield sagaErrorHandler(e, startDisruptionFailure);
  }
}

function* resolveDisruption(action: Action) {
  const { id, reason, deviceId } = (action as ReturnType<typeof resolveDisruptionAction>).payload;
  try {
    const productionRun = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRun>
    >(SagaRequestHelper.put, true, `${productionRunUrl}/${id}/${disruptions}/${resolve}`, {
      body: JSON.stringify({
        productionRunId: id,
        reason,
        deviceId,
      }),
    });
    yield put(resolveDisruptionSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, resolveDisruptionFailure);
  }
}

function* redirectToRootPath(action: Action) {
  const productionRunAction = action as ReturnType<
    typeof stopProductionRunSuccess | typeof confirmCheckForProductionRunSuccess
  >;
  const { productionRun } = productionRunAction.payload;
  if (productionRun.status === ProductionRunStatus.Done) {
    yield put(push(`${AppRoutePath.root}`));
  }
}

function* getFertigPackTolerances(action: Action) {
  const { nominalValue, densityValue } = (
    action as ReturnType<typeof calcFertigPackTolerancesAction>
  ).payload;
  let queryUrl = `?nominalValue=${encodeURIComponent(nominalValue)}`;
  if (densityValue) {
    queryUrl += `&densityValue=${densityValue}`;
  }
  try {
    const tolerances = yield* call<[boolean, string], SagaRequest<ToleranceResult>>(
      SagaRequestHelper.get,
      true,
      `${articlesUrl}/${fertigpackvUrl}${queryUrl}`
    );

    yield put(calcFertigPackTolerancesSuccess(tolerances));
  } catch (e: any) {
    yield sagaErrorHandler(e, calcFertigPackTolerancesFailure);
  }
}

function* pauseProductionRun(action: Action) {
  const { id } = (action as ReturnType<typeof pauseProductionRunAction>).payload;
  try {
    const productionRun = yield* call<[boolean, string], SagaRequest<ProductionRun>>(
      SagaRequestHelper.put,
      true,
      `${productionRunUrl}/${id}/${pause}`
    );
    yield put(pauseProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, pauseProductionRunFailure);
  }
}

function* continueProductionRun(action: Action) {
  const { id } = (action as ReturnType<typeof continueProductionRunAction>).payload;
  try {
    const productionRun = yield* call<[boolean, string], SagaRequest<ProductionRun>>(
      SagaRequestHelper.put,
      true,
      `${productionRunUrl}/${id}/${advance}`
    );
    yield put(continueProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, continueProductionRunFailure);
  }
}

export function* redirectToRootPathSaga() {
  yield takeLatest(
    [
      ProductionRunsActionType.productionRunCheckConfirmSuccess,
      ProductionRunsActionType.productionRunStopSuccess,
      ProductionRunsActionType.productionRunCheckSkipSuccess,
      ProductionRunsActionType.productionRunDisruptionResolveSuccess,
    ],
    redirectToRootPath
  );
}

export function* fetchProductionRunsSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunsFetchAll, getProductionRuns);
}

export function* reloadProductionRunsSilentSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunsReloadSilent, reloadProductionRuns);
}

export function* fetchProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunFetch, getProductionRunById);
}

export function* stopProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunStop, stopProductionRun);
}

export function* confirmCheckProductionRunSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunCheckConfirm,
    confirmCheckForProductionRun
  );
}

export function* skipCheckProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunCheckSkip, skipCheckForProductionRun);
}

export function* appendChecksSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunAppendChecks,
    appendChecksForProductionRun
  );
}

export function* startDisruptionSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunDisruptionStart, startDisruption);
}

export function* resolveDisruptionSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunDisruptionResolve, resolveDisruption);
}

export function* calcFertigPackTolerancesSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunCalcFertigPackTolerances,
    getFertigPackTolerances
  );
}
export function* pauseProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunPause, pauseProductionRun);
}

export function* continueProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunContinue, continueProductionRun);
}

export function* productionRunsSaga() {
  yield fork(fetchProductionRunsSaga);
  yield fork(reloadProductionRunsSilentSaga);
  yield fork(fetchProductionRunSaga);
  yield fork(stopProductionRunSaga);
  yield fork(confirmCheckProductionRunSaga);
  yield fork(skipCheckProductionRunSaga);
  yield fork(appendChecksSaga);
  yield fork(startDisruptionSaga);
  yield fork(resolveDisruptionSaga);
  yield fork(calcFertigPackTolerancesSaga);
  yield fork(redirectToRootPathSaga);
  yield fork(pauseProductionRunSaga);
  yield fork(continueProductionRunSaga);
}
