import ArrowBack from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ProductionRun } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { irisSpacing } from '../../theme';
import { dateAndTimeOptions } from '../../utils/date-utils';
import { InfoboxComponent } from '../infobox/infobox.component';

const PREFIX = 'DisruptionFormComponent';

const classes = {
  root: `${PREFIX}-root`,
  buttonGridItem: `${PREFIX}-buttonGridItem`,
  disruptionInfo: `${PREFIX}-disruptionInfo`,
  backLink: `${PREFIX}-backLink`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.buttonGridItem}`]: {
    textAlign: 'center',
  },

  [`& .${classes.disruptionInfo}`]: {
    display: 'flex',
    verticalAlign: 'middle',
    color: theme.palette.error.main,
    justifyContent: 'center',
  },

  [`& .${classes.backLink}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface OwnProps {
  resolve: (reason: string, deviceId?: string) => void;
  stop: (reason: string, deviceId?: string) => void;
  productionRun: ProductionRun;
}

type FormData = {
  disruptionReason: string;
};

const getFormValues = (): FormData => ({
  disruptionReason: '',
});

enum SubmitAction {
  stop = 1,
  resolve = 2,
}

export const DisruptionFormComponent = (props: OwnProps) => {
  const { productionRun, resolve, stop } = props;
  const { t, i18n } = useTranslation(['data', 'form']);
  const { handleSubmit, errors, control } = useForm<FormData>({
    defaultValues: getFormValues(),
  });
  const [currentAction, setCurrentAction] = useState<SubmitAction | undefined>(undefined);
  const [currentDisruptionDevice, setCurrentDisruptionDevice] = useState<string | undefined>(
    undefined
  );

  const disruptionDate =
    productionRun.disruptions && productionRun.disruptions.length > 0
      ? new Date(productionRun.disruptions[0].started)
      : undefined;

  const onSubmit = handleSubmit((formData: FormData) => {
    if (currentAction === SubmitAction.stop)
      stop(formData.disruptionReason, currentDisruptionDevice);
    else if (currentAction === SubmitAction.resolve)
      resolve(formData.disruptionReason, currentDisruptionDevice);
  });

  return (
    <Root>
      <form onSubmit={onSubmit} className={classes.root}>
        <Box mb={3}>
          <Link
            className={classes.backLink}
            component={NavLink}
            to={AppRoutePath.root}
            underline="hover"
          >
            <ArrowBack />
            {t('data:productionRun.backLink')}
          </Link>
        </Box>
        <Grid container direction="row" spacing={irisSpacing.input.space}>
          <Grid item xs={12}>
            <InfoboxComponent
              headline={`${t('data:disruption.info', { orderNumber: productionRun.orderNumber })} ${
                disruptionDate
                  ? disruptionDate.toLocaleString(i18n.language, dateAndTimeOptions)
                  : '-'
              } ${t('common:time')}`}
              subline={t('data:disruption.note')}
              type={'error'}
            />
          </Grid>
          {productionRun.devices && productionRun.devices.length > 0 && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth={true} id="disruptionDeviceSelect">
                <InputLabel id="disruptionDevice-label" variant="outlined">
                  {t('data:disruption.chooseDevice')}
                </InputLabel>
                <Select
                  labelId="disruptionDevice-label"
                  label={t('data:disruption.chooseDevice')}
                  variant="outlined"
                  value={currentDisruptionDevice ? currentDisruptionDevice : ''}
                  onChange={(e) => setCurrentDisruptionDevice(e.target.value)}
                  data-testid="disruptionDeviceSelect"
                >
                  {productionRun.devices.map((device) => (
                    <MenuItem value={device.id} key={device.id}>
                      {device.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              as={<TextField required />}
              control={control}
              name="disruptionReason"
              label={t('data:disruption.describeDisruptionReason')}
              placeholder={t('form:multilineTextPlaceholder')}
              multiline
              rows={6}
              fullWidth
              variant="outlined"
              inputProps={{
                'data-testid': 'disruptionReason-input',
              }}
              rules={{
                required: { value: true, message: t('form:fieldIsRequired') },
                validate: (value: string) =>
                  value.trim() ? true : (t('form:fieldIsRequired') as string),
                minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                maxLength: { value: 1000, message: t('form:maxLength', { max: '1000' }) },
              }}
              error={errors.disruptionReason !== undefined}
              helperText={errors.disruptionReason && errors.disruptionReason.message}
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonGridItem}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={irisSpacing.button.space}
            >
              <Grid item>
                <Button
                  data-testid="stop-btn"
                  color="secondary"
                  variant="contained"
                  type="submit"
                  startIcon={<HighlightOffIcon />}
                  onClick={() => {
                    setCurrentAction(SubmitAction.stop);
                  }}
                >
                  {t('data:productionRun.stopProduction')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  data-testid="resolve-btn"
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    setCurrentAction(SubmitAction.resolve);
                  }}
                  startIcon={<PlayCircleOutlineIcon />}
                >
                  {t('data:disruption.resolveDisruption')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Root>
  );
};
