import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { confirmCheckForProductionRun, hasAllRequiredCheckWeightsSelector } from '../../../store';
import { ContainerOutsideWithHeader } from '../../structure';
import { CheckProps } from '../check.component';

import { WeightCheckMeasurementComponent } from './weight-check-measurement.component';

export interface OwnProps {
  checkProps: CheckProps;
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void;
}

export const WeightCheckComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { checkProps, executeMeasurement } = props;
  const { productionRun, openCheckExecution, checkAttribute } = checkProps;
  const hasAllRequiredSamples = useSelector(hasAllRequiredCheckWeightsSelector);

  const confirmCheck = () => {
    if (hasAllRequiredSamples) {
      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          checkAttribute.checkAttributeType
        )
      );
    }
  };

  return (
    <div data-testid="WeightCheckComponent">
      <ContainerOutsideWithHeader>
        <WeightCheckMeasurementComponent
          checkProps={checkProps}
          confirmCheck={confirmCheck}
          executeMeasurement={executeMeasurement}
        />
      </ContainerOutsideWithHeader>
    </div>
  );
};
