export enum CheckAttributeType {
  YesNo = 1,
  TrafficLight,
  FertigPackV,
  Weight,
  Temperature,
  Volume,
  FreeText,
  Barcode,
  TestRun = 99,
  Tare = 100,
}

export enum CheckAttributeSpecification {
  Visual = 1,
  Haptic,
  Acoustic,
  Smell,
  Taste,
  Numeric,
  Volume,
  Weight,
  CCP,
  CP,
}
