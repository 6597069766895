import { SagaGenerator } from 'typed-redux-saga';

export enum HttpRequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}
export type HttpRequestMethod = <T>(
  withAuthentication: boolean,
  url: string,
  requestData?: RequestInit
) => Promise<T>;

export type SagaRequestMethod = <T>(
  withAuthentication: boolean,
  url: string,
  requestData?: RequestInit
) => SagaGenerator<T>;

export type HttpService<T> = {
  [HttpRequestType.GET]: T;
  [HttpRequestType.POST]: T;
  [HttpRequestType.PUT]: T;
  [HttpRequestType.DELETE]: T;
};

export type SagaRequest<T> = (
  withAuthentification: boolean,
  url: string,
  requestData?: RequestInit
) => SagaGenerator<T>;

export interface Options extends RequestInit {
  token?: string;
}
