import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CheckAttributeType } from '../../../model';
import {
  checkExecutionSamplesSelector,
  confirmCheckForProductionRun,
  sendBarcodeSample,
  fetchCheckExecution,
  checkExecutionSamplesCompleteSelector,
  initCheckExecutionSamples,
  resetCheckExecutionSamples,
  startWsConnection,
  checkExecutionsWebsocketConfig,
} from '../../../store';
import { getPixels, irisSpacing } from '../../../theme';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { CheckResultIconComponent } from '../../check-result-icon/check-result-icon.component';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { SampleBannerComponent } from '../../sample-banner/sample-banner.component';
import { CheckInfoElement, ContainerInside, ContainerOutsideWithHeader } from '../../structure';
import { CheckProps } from '../check.component';

const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: '700px',
}));

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const TableRoot = styled('div')(({ theme }) => ({
  width: `calc(100% + ${getPixels(irisSpacing.container.p * 2)}px)`,
}));

const CheckResultContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const InfoBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(irisSpacing.button.space),
}));

const initTempSamples = (sampleSize: number) => {
  const initialTempSamples: { [index: number]: string } = {};
  for (let i = 0; i < sampleSize; i++) initialTempSamples[i] = '';
  return initialTempSamples;
};

export const BarcodeCheckComponent = (props: CheckProps) => {
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();
  const { productionRun, openCheckExecution, checkAttribute } = props;
  const { id: productionRunId } = productionRun;
  const { productionRunCheckExecutionId: openCheckExecutionId } = openCheckExecution;
  const { sampleSize, barcodeDescription } = checkAttribute;

  const storedSamples = useSelector(checkExecutionSamplesSelector);
  const hasAllSamples = useSelector(checkExecutionSamplesCompleteSelector);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [tempSamples, setTempSamples] = useState<{ [index: number]: string }>(
    initTempSamples(sampleSize)
  );

  useEffect(() => {
    dispatch(initCheckExecutionSamples(sampleSize));
    dispatch(fetchCheckExecution(productionRunId, openCheckExecutionId));
    dispatch(startWsConnection(checkExecutionsWebsocketConfig));
    setTempSamples(initTempSamples(sampleSize));
    setSubmitted(false);
    return () => {
      dispatch(resetCheckExecutionSamples());
    };
  }, [dispatch, productionRunId, openCheckExecutionId, sampleSize]);

  useEffect(() => {
    setSubmitted(false);
  }, [storedSamples]);

  const confirmCheck = () => {
    if (hasAllSamples) {
      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          CheckAttributeType.Barcode
        )
      );
    }
  };

  const confirmationHandler = () => {
    setSubmitted(true);
    confirmCheck();
  };

  const checkInfoHeader = () => {
    return (
      <Box>
        <Box mb={3}>
          <Typography variant="h3">{checkAttribute.name}</Typography>
        </Box>
        {barcodeDescription && (
          <CheckInfoElement>
            <Box>
              <Typography>{barcodeDescription}</Typography>
            </Box>
          </CheckInfoElement>
        )}
        {checkAttribute.barcode && (
          <CheckInfoElement>
            <Typography fontWeight={'bold'}>
              <span>{`${t('data:check.targetBarcode')} ${checkAttribute.barcode}`}</span>
            </Typography>
          </CheckInfoElement>
        )}
      </Box>
    );
  };

  const updateTempSample = (sampleIndex: number, newValue: string) => {
    const draftState = { ...tempSamples };
    draftState[sampleIndex] = newValue;
    setTempSamples(draftState);
  };

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    sampleIndex: number
  ) => {
    updateTempSample(sampleIndex, event.target.value);
  };

  const handleFieldFocus = (sampleIndex: number) => {
    if (tempSamples[sampleIndex]) updateTempSample(sampleIndex, '');
  };

  const handleFieldLeave = (sampleIndex: number) => {
    const sampleNumber = sampleIndex + 1;
    if (tempSamples[sampleIndex])
      dispatch(
        sendBarcodeSample(
          tempSamples[sampleIndex]!,
          productionRunId,
          openCheckExecutionId,
          sampleNumber
        )
      );
  };

  const barcodeField = (sampleIndex: number) => {
    const id = `barcodeInput${sampleIndex}`;
    return (
      <>
        {storedSamples[sampleIndex] && storedSamples[sampleIndex].barcode ? (
          <Typography>{storedSamples[sampleIndex].barcode}</Typography>
        ) : (
          <TextField
            key={id}
            id={id}
            name={id}
            label={t('data:check.barcode')}
            value={tempSamples[sampleIndex]}
            onChange={(e) => handleFieldChange(e, sampleIndex)}
            onFocus={() => handleFieldFocus(sampleIndex)}
            onBlur={() => handleFieldLeave(sampleIndex)}
            inputProps={{
              'data-testid': id,
            }}
          />
        )}
      </>
    );
  };

  const checkResultRating = (sampleIndex: number) => {
    return (
      <>
        {storedSamples && (
          <CheckResultContainer>
            {!!storedSamples[sampleIndex]?.checkResult && (
              <CheckResultIconComponent
                key={sampleIndex}
                checkResult={storedSamples[sampleIndex]!.checkResult!}
              />
            )}
          </CheckResultContainer>
        )}
      </>
    );
  };

  const colWidth = '33%';

  return (
    <ContainerOutsideWithHeader>
      <StyledPaper>
        <SampleBannerComponent sampleSize={checkAttribute.sampleSize} />
        <ContainerInside>
          {checkInfoHeader()}
          <FlexContainer>
            <TableRoot>
              <TableContainer component={Paper}>
                <Table data-testid="barcodeCheckTable">
                  <TableHead>
                    <TableRow key={0}>
                      <TableCell width={colWidth} align="center">
                        {t('data:check.sample')}
                      </TableCell>
                      <TableCell width={colWidth}>{t('data:check.barcodeCheck')}</TableCell>
                      <TableCell width={colWidth}>{t('data:check.rating')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(sampleSize || 1).keys()].map((sampleIndex) => {
                      return (
                        <TableRow key={sampleIndex + 1} data-testid="checkTableRow">
                          <TableCell width={colWidth} align="center">
                            {sampleIndex + 1}
                          </TableCell>
                          <TableCell width={colWidth}>{barcodeField(sampleIndex)}</TableCell>
                          <TableCell width={colWidth}>{checkResultRating(sampleIndex)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableRoot>
          </FlexContainer>
          {submitted && !hasAllSamples && (
            <InfoBoxContainer>
              <InfoboxComponent headline={t('data:check.executeAllSamples')} type="error" />
            </InfoBoxContainer>
          )}
          <Box mt={irisSpacing.button.mt}>
            <Grid
              container
              direction="row"
              justifyContent="right"
              alignItems="center"
              spacing={irisSpacing.button.space}
            >
              <Grid item>
                <ConfirmButtonComponent handleClick={confirmationHandler} />
              </Grid>
            </Grid>
          </Box>
        </ContainerInside>
      </StyledPaper>
    </ContainerOutsideWithHeader>
  );
};
