import { HttpError } from '../../http';
import { ProfileInfo, User } from '../../model';

export enum AuthenticationActionType {
  login = 'login',
  loginSuccess = 'login/success',
  loginFailure = 'login/failure',
  loginRoleInsufficient = 'login/roleInsufficient',
  loginFailureClear = 'login/failure/clear',
  logout = 'logout',
  logoutSuccess = 'logout/success',
  logoutFailure = 'logout/failure',
  loginInit = 'login/init',
  changePasswordAfterReset = 'password/changeAfterReset',
  changePasswordAfterResetSuccess = 'password/changeAfterReset/success',
  changePasswordAfterResetFailure = 'password/changeAfterReset/failure',
  changePasswordAfterResetFailureClear = 'password/changeAfterReset/failure/clear',
  changePassword = 'password/change',
  changePasswordSuccess = 'password/change/success',
  changePasswordFailure = 'password/change/failure',
  changePasswordSuccessfulClear = 'password/change/successful/clear',
  profileInfoFetch = 'profileInfo/fetch',
  profileInfoFetchSuccess = 'profileInfo/fetch/Success',
  profileInfoFetchFailure = 'profileInfo/fetch/Failure',
}

export const login = (username: string, password: string) => ({
  type: AuthenticationActionType.login,
  payload: { username, password },
});

export const loginSuccess = (user: User, changePassword: boolean) => ({
  type: AuthenticationActionType.loginSuccess,
  payload: { user, changePassword },
});

export const loginFailure = (e: HttpError) => ({
  type: AuthenticationActionType.loginFailure,
  payload: { e },
});

export const loginRoleInsufficient = () => ({
  type: AuthenticationActionType.loginRoleInsufficient,
});

export const clearLoginFailure = () => ({
  type: AuthenticationActionType.loginFailureClear,
});

export const logout = () => ({
  type: AuthenticationActionType.logout,
});

export const logoutSuccess = () => ({
  type: AuthenticationActionType.logoutSuccess,
});

export const logoutFailure = () => ({
  type: AuthenticationActionType.logoutFailure,
});

export const initLogin = (user: User, changePassword: boolean) => ({
  type: AuthenticationActionType.loginInit,
  payload: { user, changePassword },
});

export const changePasswordAfterReset = (password: string, passwordConfirmation: string) => ({
  type: AuthenticationActionType.changePasswordAfterReset,
  payload: { password, passwordConfirmation },
});

export const changePasswordAfterResetSuccess = () => ({
  type: AuthenticationActionType.changePasswordAfterResetSuccess,
});

export const changePasswordAfterResetFailure = (e: HttpError) => ({
  type: AuthenticationActionType.changePasswordAfterResetFailure,
  payload: { e },
});

export const clearChangePasswordAfterResetFailure = () => ({
  type: AuthenticationActionType.changePasswordAfterResetFailureClear,
});

export const changePassword = (
  currentPassword: string,
  password: string,
  passwordConfirmation: string
) => ({
  type: AuthenticationActionType.changePassword,
  payload: { currentPassword, password, passwordConfirmation },
});

export const changePasswordSuccess = () => ({
  type: AuthenticationActionType.changePasswordSuccess,
});

export const changePasswordFailure = (e: HttpError) => ({
  type: AuthenticationActionType.changePasswordFailure,
  payload: { e },
});

export const clearChangePasswordSuccessful = () => ({
  type: AuthenticationActionType.changePasswordSuccessfulClear,
});

export const fetchProfileInfo = () => ({
  type: AuthenticationActionType.profileInfoFetch,
});

export const fetchProfileInfoSuccess = (profileInfo: ProfileInfo) => ({
  type: AuthenticationActionType.profileInfoFetchSuccess,
  payload: { profileInfo },
});

export const fetchProfileInfoFailure = () => ({
  type: AuthenticationActionType.profileInfoFetchFailure,
});
