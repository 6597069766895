import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoginFormComponent } from '../../components/form/login-form/login-form.component';
import { StartScreenLayoutComponent } from '../../components/layout/start-screen-layout/start-screen-layout.component';
import { NoLicenseInfoComponent } from '../../components/license-info/no-license-info.component';
import { License } from '../../model';
import { fetchLicense, licenseSelector } from '../../store';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const license: License | boolean | undefined = useSelector(licenseSelector);

  useEffect(() => {
    license === undefined && dispatch(fetchLicense());
  }, [dispatch, license]);

  return (
    <StartScreenLayoutComponent>
      {license && !license.expired ? <LoginFormComponent /> : <NoLicenseInfoComponent />}
    </StartScreenLayoutComponent>
  );
};
