import React from 'react';
import { useSelector } from 'react-redux';

import productionRoutes from '../../routes';
import { changePasswordAfterResetSelector, loggedInUserSelector } from '../../store';

export const ProductionContentComponent = () => {
  const loggedInUser = useSelector(loggedInUserSelector);
  const changePassword = useSelector(changePasswordAfterResetSelector);

  return <>{productionRoutes(changePassword, loggedInUser)}</>;
};
