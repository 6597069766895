import { createSelector } from 'reselect';

import { AppState } from '..';

export const websocketConnectionsStateSelector = (state: AppState) => state.websocketConnections;
export const websocketConnectionSelector = (url: string) =>
  createSelector(websocketConnectionsStateSelector, (state) =>
    state.connections[url] ? state.connections[url] : undefined
  );
export const websocketConnectionsSelector = createSelector(
  websocketConnectionsStateSelector,
  (state) => Object.entries(state.connections)
);
