import { createSelector } from 'reselect';

import { AppState } from '..';

export const checkExecutionsStateSelector = (state: AppState) => state.checkExecutions;
export const checkExecutionSamplesSelector = createSelector(
  checkExecutionsStateSelector,
  (state) => state.samples
);

export const checkExecutionSampleSizeSelector = createSelector(
  checkExecutionsStateSelector,
  (state) => state.sampleSize
);

export const checkExecutionSamplesCompleteSelector = createSelector(
  checkExecutionSamplesSelector,
  checkExecutionSampleSizeSelector,
  (samples, sampleSize) => {
    let samplesComplete = true;
    let sampleIndex = 0;

    while (samplesComplete && sampleIndex < sampleSize) {
      if (samples[sampleIndex]?.checkResult == null) {
        samplesComplete = false;
      }
      sampleIndex++;
    }

    return samplesComplete;
  }
);
