import { createSelector } from 'reselect';

import { AppState } from '..';
import { DEFAULT_LINE_ID } from '../../constants';

import { defaultLine } from './lines.reducer';

export const linesStateSelector = (state: AppState) => state.lines;
export const lineListSelector = createSelector(linesStateSelector, (state) => state.lines);
export const currentLineIdSelector = createSelector(
  linesStateSelector,
  (state) => state.currentLineId || DEFAULT_LINE_ID
);

export const currentLineSelector = createSelector(
  linesStateSelector,
  (state) => state.lines.find((line) => line.id === state.currentLineId) || defaultLine
);

export const linesFetchedSelector = createSelector(
  linesStateSelector,
  (state) => !!state.linesFetched
);
