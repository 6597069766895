import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import {
  ProductionRunCheckExecution,
  ProductionRunCheckExecutionWeighingSampleResult,
} from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  executeCheckWeight,
  executeCheckWeightFailure,
  executeCheckWeightSuccess,
  deleteCheckWeightSample,
  deleteCheckWeightSampleFailure,
  deleteCheckWeightSampleSuccess,
  fetchCheckWeight,
  fetchCheckWeightFailure,
  fetchCheckWeightSuccess,
  WeightsActionType,
  fetchCheckWeightSamples,
  fetchCheckWeightSamplesSuccess,
  fetchCheckWeightSamplesFailure,
} from './weights.actions';

const productionRunsUrl = 'production/runs';
const checkExecutionsUrl = 'checks/executions';
const weighingUrl = 'weighing';
const samplesUrl = 'samples';
const checksUrl = 'checks';
const executionsUrl = 'executions';

function* getCheckWeight(action: Action) {
  const { sampleIndex, productionRunId, executionId, scaleId } = (
    action as ReturnType<typeof fetchCheckWeight>
  ).payload;
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<ProductionRunCheckExecutionWeighingSampleResult>
    >(
      SagaRequestHelper.get,
      true,
      `${productionRunsUrl}/${productionRunId}/${checkExecutionsUrl}/${executionId}/${weighingUrl}/${scaleId}`
    );
    yield put(fetchCheckWeightSuccess(sampleIndex, response));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckWeightFailure);
  }
}

function* createCheckWeight(action: Action) {
  const { sampleNumber, productionRunId, executionId, scaleId, isTareWeighing } = (
    action as ReturnType<typeof executeCheckWeight>
  ).payload;
  try {
    const response = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRunCheckExecutionWeighingSampleResult>
    >(
      SagaRequestHelper.post,
      true,
      `${productionRunsUrl}/${productionRunId}/${checkExecutionsUrl}/${executionId}/${weighingUrl}/${scaleId}`,
      {
        body: JSON.stringify({
          sampleNumber,
          isTareWeighing,
        }),
      }
    );
    yield put(executeCheckWeightSuccess(sampleNumber, response, isTareWeighing));
  } catch (e: any) {
    yield sagaErrorHandler(e, executeCheckWeightFailure);
  }
}

function* getCheckWeightSamples(action: Action) {
  const { productionRunId, executionId } = (action as ReturnType<typeof fetchCheckWeightSamples>)
    .payload;
  try {
    const checkExecution = yield* call<[boolean, string], SagaRequest<ProductionRunCheckExecution>>(
      SagaRequestHelper.get,
      true,
      `${productionRunsUrl}/${productionRunId}/${checksUrl}/${executionsUrl}/${executionId}`
    );
    yield put(fetchCheckWeightSamplesSuccess(checkExecution));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckWeightSamplesFailure);
  }
}

function* removeCheckWeightSample(action: Action) {
  const { productionRunId, executionId, sampleNumber } = (
    action as ReturnType<typeof deleteCheckWeightSample>
  ).payload;
  try {
    yield* call<
      [boolean, string, {}],
      SagaRequest<ProductionRunCheckExecutionWeighingSampleResult>
    >(
      SagaRequestHelper.delete,
      true,
      `${productionRunsUrl}/${productionRunId}/${checkExecutionsUrl}/${executionId}/${samplesUrl}/${sampleNumber}`,
      {}
    );
    yield put(deleteCheckWeightSampleSuccess(sampleNumber));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteCheckWeightSampleFailure);
  }
}

export function* fetchCheckWeightSaga() {
  yield takeLatest(WeightsActionType.checkWeightFetch, getCheckWeight);
}

export function* executeCheckWeightSaga() {
  yield takeLatest(WeightsActionType.checkWeightExecute, createCheckWeight);
}

export function* fetchCheckWeightSamplesSaga() {
  yield takeLatest(WeightsActionType.checkWeightSamplesFetch, getCheckWeightSamples);
}

export function* deleteCheckWeightSampleSaga() {
  yield takeLatest(WeightsActionType.checkWeightSampleDelete, removeCheckWeightSample);
}

export function* weightsSaga() {
  yield fork(fetchCheckWeightSaga);
  yield fork(executeCheckWeightSaga);
  yield fork(fetchCheckWeightSamplesSaga);
  yield fork(deleteCheckWeightSampleSaga);
}
