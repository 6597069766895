import { User } from '../../model';

export enum UsersActionType {
  userFetch = 'user/fetch',
  userFetchSuccess = 'user/fetch/success',
  userFetchFailure = 'user/fetch/failure',
  userRequestResetPassword = 'user/requestResetPassword',
  userRequestResetPasswordSuccess = 'user/requestResetPassword/success',
  userRequestResetPasswordFailure = 'user/requestResetPassword/failure',
  userRequestResetPasswordFailedClear = 'user/requestResetPasswordFailed/clear',
}

export const fetchUser = (id: string) => ({
  type: UsersActionType.userFetch,
  payload: { id },
});

export const fetchUserSuccess = (user: User) => ({
  type: UsersActionType.userFetchSuccess,
  payload: { user },
});

export const fetchUserFailure = () => ({
  type: UsersActionType.userFetchFailure,
});

export const requestResetPassword = (username: string) => ({
  type: UsersActionType.userRequestResetPassword,
  payload: { username },
});

export const requestResetPasswordSuccess = () => ({
  type: UsersActionType.userRequestResetPasswordSuccess,
});

export const requestResetPasswordFailure = () => ({
  type: UsersActionType.userRequestResetPasswordFailure,
});

export const clearRequestResetPasswordFailed = () => ({
  type: UsersActionType.userRequestResetPasswordFailedClear,
});
