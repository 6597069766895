import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import localForage from 'localforage';
import log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router';

import { DEFAULT_LINE_ID } from '../../constants';
import { UserRole } from '../../model';
import { ProfilePage } from '../../pages/profile/profile.page';
import { PrivateRoute } from '../../routes/private-route';
import { AppRoutePath } from '../../routes/routes';
import { loggedInUserSelector, initLine, changePasswordAfterResetSelector } from '../../store';
import { ProductionContentComponent } from '../production/production-content.component';
import { PageBodyStructure } from '../structure';

import { HeaderComponent } from './header.component';

const PREFIX = 'LayoutComponent';

const classes = {
  content: `${PREFIX}-content`,
  loadingBox: `${PREFIX}-loadingBox`,
};

const Root = styled('main')(({ theme }) => ({
  [`&.${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.loadingBox}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100vh',
  },
}));

export const LayoutComponent = () => {
  const loggedInUser = useSelector(loggedInUserSelector);
  const changePassword = useSelector(changePasswordAfterResetSelector);
  const dispatch = useDispatch();

  const [lineInitialized, setLineInitialized] = useState<boolean | null>(null);

  useEffect(() => {
    async function getLineFromStorage() {
      try {
        const chosenLineId = (await localForage.getItem('chosenLineId')) || DEFAULT_LINE_ID;
        chosenLineId && dispatch(initLine(chosenLineId as string));
        setLineInitialized(!!chosenLineId);
      } catch (e: any) {
        log.debug(e);
      }
    }
    getLineFromStorage();
  }, [dispatch]);

  return (
    <Root className={classes.content}>
      {/* Render routes only when line was initialized or else the original route will get lost on reload due to redirect*/}
      {lineInitialized === null ? (
        <div className={classes.loadingBox}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <HeaderComponent />
          <PageBodyStructure>
            <Switch>
              <PrivateRoute
                exact={true}
                path={`/${AppRoutePath.me}`}
                component={ProfilePage}
                loggedInUser={loggedInUser}
                minRole={UserRole.Production}
                changePassword={changePassword}
              />
              <PrivateRoute
                exact={false}
                path={`/`}
                component={ProductionContentComponent}
                loggedInUser={loggedInUser}
                minRole={UserRole.Production}
                changePassword={changePassword}
              />
            </Switch>
          </PageBodyStructure>
        </>
      )}
    </Root>
  );
};
