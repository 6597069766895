import { styled } from '@mui/material/styles';

import { irisSpacing, responsiveness } from '../../theme';

export const PageBodyStructure = styled('div')(({ theme }) => ({
  margin: 'auto',
  padding: `${theme.spacing(irisSpacing.structure.p)} ${responsiveness.page.padding.x}px`,
  minWidth: responsiveness.page.width.min,
  maxWidth: responsiveness.page.width.max,
}));
