import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NoLicenseInfoComponent = () => {
  const { t } = useTranslation(['license']);

  return (
    <Box>
      <Typography>{t('license:noActiveLicenseFound')}</Typography>
    </Box>
  );
};
