import { VOLUME_ENTITY, WEIGHT_ENTITY } from '../../constants';
import { IssueCode, ProductionRun, ProductionRunStatus } from '../../model';
import { hasIssue } from '../issue/issue.helper';

export const hasActiveTestRunAfterStart = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.InTestRunAfterStart;
};

export const hasActiveTestRunAfterDisruption = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.InTestRunAfterDisruption;
};

export const hasActiveTestRun = (productionRun: ProductionRun) => {
  return (
    hasActiveTestRunAfterStart(productionRun) || hasActiveTestRunAfterDisruption(productionRun)
  );
};

export const hasActiveTareDetermination = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.Taring;
};

export const getFertigPackVStatistics = (productionRun: ProductionRun) => {
  return hasActiveTestRun(productionRun)
    ? productionRun.fertigPackVData.testRunStatistics
    : productionRun.fertigPackVData.statistics;
};

export const hasMessageFlagIssues = (productionRun: ProductionRun) => {
  const hasTU1Violation = hasIssue(productionRun, IssueCode.FertigPackVTU1Violation);
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasAnyActiveTestRun = hasActiveTestRun(productionRun);
  const paused = isRunPausedManually(productionRun);

  if (paused || hasTU1Violation || hasTU2Violation || hasAnyActiveTestRun) {
    return true;
  } else {
    return false;
  }
};

export const canBePaused = (productionRun: ProductionRun) => {
  const hasAnyActiveTestRun = hasActiveTestRun(productionRun);
  const hasTareDeterminationActive = hasActiveTareDetermination(productionRun);
  const hasCriticalTU1Violation = productionRun.fertigPackVData.hasCriticalTU1Violation;
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const isFinishing = productionRun.status === ProductionRunStatus.Finishing;
  const isDisrupted = productionRun.disrupted;

  return !(
    hasAnyActiveTestRun ||
    hasTareDeterminationActive ||
    hasCriticalTU1Violation ||
    hasTU2Violation ||
    isFinishing ||
    isDisrupted
  );
};

export const isRunPausedManually = (productionRun: ProductionRun) => {
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);

  return (
    productionRun.status === ProductionRunStatus.Paused &&
    !hasTU2Violation &&
    !productionRun.fertigPackVData.hasCriticalTU1Violation
  );
};

export const getSuffix = (hasVolumeValue?: boolean) => {
  return hasVolumeValue ? VOLUME_ENTITY : WEIGHT_ENTITY;
};
