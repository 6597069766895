import { Action, Dispatch, MiddlewareAPI, AnyAction } from 'redux';

import { DEFAULT_LINE_ID } from '../../constants';
import { ProductionOrder, ProductionRun } from '../../model';
import {
  changedProductionOrder as changedProductionOrderAction,
  removeProductionOrder as removeProductionOrderAction,
  ProductionOrdersWebsocketActionType,
} from '../production-orders';
import {
  changedProductionRun as changedProductionRunAction,
  changedProductionTestRun as changedProductionTestRunAction,
  finishedProductionRun as finishedProductionRunAction,
  currentProductionRunSelector,
  ProductionRunsWebsocketActionType,
} from '../production-runs';

import { currentLineIdSelector } from './lines.selectors';

export const linesMiddleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: Action<ProductionRunsWebsocketActionType | ProductionOrdersWebsocketActionType>) => {
    const { type } = action;
    const currentLineId = currentLineIdSelector(api.getState());
    const currentProductionRun = currentProductionRunSelector(api.getState());

    const shouldForwardProductionRun = (incomingProductionRun: ProductionRun) => {
      return (
        currentLineId === DEFAULT_LINE_ID ||
        currentLineId === incomingProductionRun.productionLineId ||
        (currentProductionRun && currentProductionRun.id === incomingProductionRun.id)
      );
    };

    const shouldForwardProductionOrder = (incomingProductionOrder: ProductionOrder) => {
      return (
        currentLineId === DEFAULT_LINE_ID ||
        currentLineId === incomingProductionOrder.productionLine?.id
      );
    };

    switch (type) {
      case ProductionRunsWebsocketActionType.productionRunChanged:
        const changedProductionRun = (action as ReturnType<typeof changedProductionRunAction>)
          .payload.productionRun;
        if (shouldForwardProductionRun(changedProductionRun)) {
          return next(action);
        }
        break;

      case ProductionRunsWebsocketActionType.productionTestRunChanged:
        const changedProductionTestRun = (
          action as ReturnType<typeof changedProductionTestRunAction>
        ).payload.productionRun;
        if (shouldForwardProductionRun(changedProductionTestRun)) {
          return next(action);
        }
        break;

      case ProductionRunsWebsocketActionType.productionRunFinished:
        const finishedProductionRun = (action as ReturnType<typeof finishedProductionRunAction>)
          .payload.productionRun;
        if (shouldForwardProductionRun(finishedProductionRun)) {
          return next(action);
        }
        break;

      case ProductionOrdersWebsocketActionType.productionOrderChanged:
        const changedProductionOrder = (action as ReturnType<typeof changedProductionOrderAction>)
          .payload.productionOrder;
        if (shouldForwardProductionOrder(changedProductionOrder)) {
          return next(action);
        } else {
          return next(removeProductionOrderAction(changedProductionOrder.id));
        }

      case ProductionOrdersWebsocketActionType.productionOrderDeleted:
        return next(action);

      default:
        return next(action);
    }
  };
