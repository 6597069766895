import { push } from 'connected-react-router';
import { Action } from 'redux';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { login, logout, LoggedInUserData, setResetPassword } from '../../auth/auth.service';
import { SagaRequest, SagaRequestHelper } from '../../http';
import { ProfileInfo, UserRole } from '../../model';
import { clearNotification } from '../notifications';
import { sagaErrorHandler } from '../saga-error-handler';
import { stopWsConnections } from '../websockets';

import { getRefreshToken } from './../../auth/auth.service';
import {
  AuthenticationActionType,
  login as loginAction,
  loginFailure,
  loginRoleInsufficient,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  changePasswordAfterReset as changePasswordAfterResetAction,
  changePasswordAfterResetSuccess,
  changePasswordAfterResetFailure,
  changePassword as changePasswordAction,
  changePasswordSuccess,
  changePasswordFailure,
  fetchProfileInfoSuccess,
  fetchProfileInfoFailure,
} from './authentication.actions';

const authUrl = 'auth';
const loginUrl = 'login';
const logoutUrl = 'logout';
const meUrl = 'me';
const resetPasswordUrl = 'reset-password';
const changePasswordUrl = 'change-password';

function* loginUser(action: Action) {
  const { username, password } = (action as ReturnType<typeof loginAction>).payload;
  try {
    const response = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<LoggedInUserData>
    >(SagaRequestHelper.post, false, `${authUrl}/${loginUrl}`, {
      body: JSON.stringify({ username, password }),
    });

    if ((response.user.role as Number) > (UserRole.Production as Number))
      yield put(loginRoleInsufficient());
    else {
      yield call(login, response);
      yield put(loginSuccess(response.user, response.resetPassword));
      yield put(push(`/`));
    }
  } catch (e: any) {
    yield put(loginFailure(e));
  }
}

function* logoutUser() {
  const refreshToken: string | undefined = yield call(getRefreshToken);
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<undefined>>(
      SagaRequestHelper.post,
      true,
      `${authUrl}/${logoutUrl}`,
      {
        body: JSON.stringify({ refreshToken }),
      }
    );
    yield put(logoutSuccess());
  } catch (e: any) {
    yield put(logoutFailure());
  }
  yield call(logout);
  yield put(clearNotification());
  yield put(stopWsConnections());
  yield put(push(`/`));
}

function* changePasswordAfterReset(action: Action) {
  const { password, passwordConfirmation } = (
    action as ReturnType<typeof changePasswordAfterResetAction>
  ).payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<undefined>>(
      SagaRequestHelper.put,
      true,
      `${meUrl}/${resetPasswordUrl}`,
      {
        body: JSON.stringify({ password, passwordConfirmation }),
      }
    );
    yield call(setResetPassword, false);
    yield put(changePasswordAfterResetSuccess());
    yield put(push(`/`));
  } catch (e: any) {
    yield sagaErrorHandler(e, changePasswordAfterResetFailure);
  }
}

function* changePassword(action: Action) {
  const { currentPassword, password, passwordConfirmation } = (
    action as ReturnType<typeof changePasswordAction>
  ).payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<undefined>>(
      SagaRequestHelper.put,
      true,
      `${meUrl}/${changePasswordUrl}`,
      {
        body: JSON.stringify({ currentPassword, password, passwordConfirmation }),
      }
    );
    yield put(changePasswordSuccess());
  } catch (e: any) {
    yield sagaErrorHandler(e, changePasswordFailure);
  }
}

function* getProfileInfo(action: Action) {
  try {
    const profileInfo = yield* call<[boolean, string], SagaRequest<ProfileInfo>>(
      SagaRequestHelper.get,
      true,
      `${meUrl}`
    );
    yield put(fetchProfileInfoSuccess(profileInfo));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProfileInfoFailure);
  }
}

export function* loginSaga() {
  yield takeLatest(AuthenticationActionType.login, loginUser);
}

export function* logoutSaga() {
  yield takeLatest(AuthenticationActionType.logout, logoutUser);
}

export function* changePasswordAfterResetSaga() {
  yield takeLatest(AuthenticationActionType.changePasswordAfterReset, changePasswordAfterReset);
}

export function* changePasswordSaga() {
  yield takeLatest(AuthenticationActionType.changePassword, changePassword);
}

export function* fetchProfileInfoSaga() {
  yield takeLatest(AuthenticationActionType.profileInfoFetch, getProfileInfo);
}

export function* authenticationSaga() {
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(changePasswordAfterResetSaga);
  yield fork(changePasswordSaga);
  yield fork(fetchProfileInfoSaga);
}
