import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OpenCheckExecution } from '../../model';
import { skipCheckForProductionRun, skipCheckDialogOpenSelector } from '../../store';
import { irisSpacing } from '../../theme';

import { CheckSkipComponent } from './check-skip.component';

const PREFIX = 'CheckSkipDialogComponent';
const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '1000px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

export interface OwnProps {
  handleCancel: () => void;
  productionRunId: string;
  openCheckExecution: OpenCheckExecution;
}

export const CheckSkipDialogComponent = (props: OwnProps) => {
  const { productionRunId, openCheckExecution, handleCancel } = props;

  const dispatch = useDispatch();
  const isSkipCheckDialogOpen = useSelector(skipCheckDialogOpenSelector);

  const submit = (skipReason: string) => {
    dispatch(skipCheckForProductionRun(productionRunId, openCheckExecution, skipReason));
  };

  const cancel = () => {
    handleCancel();
  };

  return (
    <StyledDialog open={isSkipCheckDialogOpen} maxWidth="md" className={classes.dialog}>
      <DialogContent>
        <CheckSkipComponent submit={submit} cancel={cancel}></CheckSkipComponent>
      </DialogContent>
    </StyledDialog>
  );
};
