import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { irisCustomColors } from '../../theme';

const PREFIX = 'ChecksProgressComponent';

const classes = {
  box: `${PREFIX}-box`,
  progress: `${PREFIX}-progress`,
};

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  [`& .${classes.box}`]: {
    height: '10px',
    border: '1px solid',
    display: 'flex',
    borderColor: irisCustomColors.irisGold,
    backgroundColor: theme.palette.common.white,
  },

  [`& .${classes.progress}`]: {
    backgroundColor: irisCustomColors.irisGold,
    width: `${(styleProps.executed * 100) / styleProps.total}%`,
  },
}));

interface StylePropsWrapper {
  styleProps: {
    total: number;
    executed: number;
  };
}

export interface OwnProps {
  total: number;
  executed: number;
}

export const ChecksProgressComponent = (props: OwnProps) => {
  const { total, executed } = props;
  const { t } = useTranslation(['data']);

  return (
    <Root styleProps={{ total, executed }} data-testid={'checksProgress'}>
      <Box mb={1}>{`${t('data:check.check')} ${executed + 1} / ${total}`}</Box>
      <div className={classes.box}>
        <span className={classes.progress}></span>
      </div>
    </Root>
  );
};
