export interface ProductionRunCheckExecutionSample
  extends Omit<ProductionRunCheckExecutionSampleResult, 'resultValue'>,
    Omit<
      ProductionRunCheckExecutionWeighingSampleResult,
      'weighingOn' | 'weightDisplay' | 'weightGross' | 'weightNet'
    > {
  sampleNumber: number;
  checkResult: CheckResult;
  weighingOn?: string;
  weightDisplay?: number;
  weightGross?: number;
  weightNet?: number;
  barcode?: string;
}

export interface ProductionRunCheckExecutionSampleResult {
  checkResult: CheckResult;
  resultValue: string;
}

export interface ProductionRunCheckExecutionWeighingSampleResult extends ScaleWeighing {
  checkResult?: CheckResult;
  fertigPackVCategory?: FertigPackVCategory;
  volumeNet?: number;
  volumeGross?: number;
  volumeDisplay?: number;
}

export interface ScaleWeighing {
  weighingOn: string;
  tareValue?: number;
  weightDisplay: number;
  weightGross: number;
  weightNet: number;
  alibiNumber?: number;
}

export enum CheckResult {
  Unknown = 1,
  Passed = 2,
  Sufficient = 3,
  Failed = 4,
  Unrated = 5,
}

export enum FertigPackVCategory {
  TO2 = 1,
  TO1 = 2,
  OK = 3,
  TU1 = 4,
  TU2 = 5,
}
