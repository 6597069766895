import { Grid } from '@mui/material';
import React from 'react';

import { filterIssues } from '../../helper';
import { IssueCode, ProductionRunIssue } from '../../model';
import { InfoboxComponent, mapToInfoboxType } from '../infobox/infobox.component';
import { ContainerOutside } from '../structure';

import { IssueComponent } from './issue.component';

interface OwnProps {
  issues: ProductionRunIssue[];
  allowedIssueCodes?: IssueCode[];
  iconSize?: 'small' | 'inherit' | 'medium' | 'large';
  elementStyle?: 'plain' | 'infobox';
}

export const IssuesComponent = (props: OwnProps) => {
  const { issues, allowedIssueCodes, iconSize, elementStyle } = props;
  const filteredIssues = filterIssues(issues, allowedIssueCodes);

  const getInfoboxIssue = (issue: ProductionRunIssue) => {
    return (
      <ContainerOutside key={`${issue.issueType}_${issue.issueCode}`}>
        <InfoboxComponent
          type={mapToInfoboxType(issue.issueType)}
          customElement={<IssueComponent issue={issue} iconSize={iconSize} />}
        />
      </ContainerOutside>
    );
  };

  const getPlainIssue = (issue: ProductionRunIssue) => {
    return (
      <Grid item key={`${issue.issueType}_${issue.issueCode}`}>
        <IssueComponent issue={issue} iconSize={iconSize} />
      </Grid>
    );
  };

  if (filteredIssues.length === 0) return <></>;
  else {
    switch (elementStyle) {
      case 'infobox':
        return <>{filteredIssues.map((issue) => getInfoboxIssue(issue))}</>;
      default:
        return (
          <Grid container spacing={1} flexDirection={'column'}>
            {filteredIssues.map((issue) => getPlainIssue(issue))}
          </Grid>
        );
    }
  }
};
