import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GridContainer } from '../../components/grid/grid.container';
import { GridItem } from '../../components/grid/grid.item';
import { LineSelectComponent } from '../../components/line-select/line-select.component';
import { SearchFieldComponent } from '../../components/search-field/search-field.component';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_LINE_ID } from '../../constants';
import {
  currentLineIdSelector,
  fetchLines,
  fetchProductionOrders,
  fetchProductionRuns,
  lineListSelector,
  linesFetchedSelector,
  productionOrdersCurrentPageIndexSelector,
  productionOrdersFetchedSelector,
  productionOrdersSilentReloadSelector,
  productionOrdersTotalCountSelector,
  productionOrdersWebsocketConfig,
  productionRunsCurrentPageIndexSelector,
  productionRunsFetchedSelector,
  productionRunsSilentReloadSelector,
  productionRunsTotalCountSelector,
  productionRunsWebsocketConfig,
  productionSearchTermSelector,
  reloadProductionOrdersSilent,
  reloadProductionRunsSilent,
  setProductionOrdersCurrentPageIndex,
  setProductionRunsCurrentPageIndex,
  setSearchTermProduction,
  startWsConnection,
} from '../../store';
import { irisCustomColors, irisSpacing } from '../../theme';

const ProductionOrdersListComponent = React.lazy(
  () => import('./production-orders/production-orders-list.component')
);
const ProductionRunsListComponent = React.lazy(
  () => import('./production-runs/production-runs-list.component')
);

enum TabItem {
  ProductionRuns = 0,
  ProductionOrders = 1,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabName: string;
  value: number;
}

function tabProps(index: number, disabled: boolean) {
  return {
    id: `tab${index}`,
    tabIndex: index,
    'aria-controls': `tabButton${index}`,
    'data-testid': `tabButton${index}`,
    disabled,
  };
}

interface StyledTabProps {
  label: string;
}

const FilterArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(irisSpacing.input.space),
}));

const StyledTabs = styled(Tabs)({
  borderBottom: `1px solid ${irisCustomColors.irisBlack}`,
  '& .MuiTabs-indicator': {
    backgroundColor: irisCustomColors.irisBlueGray,
  },
});

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: irisCustomColors.irisWhite,
    opacity: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textTransform: 'none',
    fontSize: '24px',
    '&.Mui-selected': {
      color: irisCustomColors.irisWhite,
      backgroundColor: irisCustomColors.irisBlueGray,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        backgroundColor: irisCustomColors.irisBlueGray,
      },
    },
    '&:hover': {
      backgroundColor: irisCustomColors.irisGrayLight,
    },
  })
);

const TabContent = (props: TabPanelProps) => {
  const { children, value, index, tabName, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={`tabPanel${index}`}
      id={`tabPanel${index}`}
      aria-labelledby={`tab${index}`}
      data-testid={`tabContent${tabName}`}
      {...other}
    >
      {value === index && <Box mt={irisSpacing.container.space}>{children}</Box>}
    </div>
  );
};

const LineInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '150px',
  whiteSpace: 'pre-line',
  textAlign: 'center',
}));

export const ProductionOverviewComponent = () => {
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();

  const lines = useSelector(lineListSelector);
  const currentLineId = useSelector(currentLineIdSelector);
  const runsFetched = useSelector(productionRunsFetchedSelector);
  const ordersFetched = useSelector(productionOrdersFetchedSelector);
  const linesFetched = useSelector(linesFetchedSelector);
  const runsCount = useSelector(productionRunsTotalCountSelector);
  const ordersCount = useSelector(productionOrdersTotalCountSelector);
  const existingSearchTerm = useSelector(productionSearchTermSelector);
  const runsPageIndex = useSelector(productionRunsCurrentPageIndexSelector);
  const ordersPageIndex = useSelector(productionOrdersCurrentPageIndexSelector);
  const silentReloadRuns = useSelector(productionRunsSilentReloadSelector);
  const silentReloadOrders = useSelector(productionOrdersSilentReloadSelector);

  const totalCount = runsCount + ordersCount;
  const hasLines = lines.length > 1;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(TabItem.ProductionRuns);
  const [validatedCurrentLineId, setValidatedCurrentLineId] = useState<string | undefined>(
    undefined
  );

  const handleRunsPageChange = (newPageIndex: number) => {
    dispatch(setProductionRunsCurrentPageIndex(newPageIndex));
  };

  const handleOrdersPageChange = (newPageIndex: number) => {
    dispatch(setProductionOrdersCurrentPageIndex(newPageIndex));
  };

  useEffect(() => {
    if (linesFetched) {
      if (lines.some((l) => l.id === currentLineId)) setValidatedCurrentLineId(currentLineId);
      else setValidatedCurrentLineId(DEFAULT_LINE_ID);
    }
  }, [linesFetched, lines, currentLineId]);

  useEffect(() => {
    if (runsFetched && ordersFetched) {
      if (totalCount > 0) {
        if (runsCount === 0 && selectedTab === TabItem.ProductionRuns)
          setSelectedTab(TabItem.ProductionOrders);
        else if (ordersCount === 0 && selectedTab === TabItem.ProductionOrders)
          setSelectedTab(TabItem.ProductionRuns);
      }
    }
  }, [runsFetched, ordersFetched, totalCount, runsCount, ordersCount, selectedTab]);

  useEffect(() => {
    dispatch(fetchLines());
    dispatch(startWsConnection(productionRunsWebsocketConfig));
    dispatch(startWsConnection(productionOrdersWebsocketConfig));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setProductionRunsCurrentPageIndex(0));
    dispatch(setProductionOrdersCurrentPageIndex(0));
  }, [dispatch, validatedCurrentLineId, searchTerm]);

  useEffect(() => {
    if (validatedCurrentLineId && silentReloadRuns) {
      dispatch(
        reloadProductionRunsSilent({
          lineId: validatedCurrentLineId,
          search: searchTerm,
          pagination: { pageIndex: runsPageIndex, perPage: DEFAULT_ITEMS_PER_PAGE },
        })
      );
    }
  }, [dispatch, silentReloadRuns, validatedCurrentLineId, searchTerm, runsPageIndex]);

  useEffect(() => {
    if (validatedCurrentLineId && silentReloadOrders) {
      dispatch(
        reloadProductionOrdersSilent({
          lineId: validatedCurrentLineId,
          search: searchTerm,
          pagination: { pageIndex: ordersPageIndex, perPage: DEFAULT_ITEMS_PER_PAGE },
        })
      );
    }
  }, [dispatch, silentReloadOrders, validatedCurrentLineId, searchTerm, ordersPageIndex]);

  useEffect(() => {
    if (validatedCurrentLineId && hasLines) {
      dispatch(
        fetchProductionRuns({
          lineId: validatedCurrentLineId,
          search: searchTerm,
          pagination: { pageIndex: runsPageIndex, perPage: DEFAULT_ITEMS_PER_PAGE },
        })
      );
    }
  }, [dispatch, hasLines, validatedCurrentLineId, searchTerm, runsPageIndex]);

  useEffect(() => {
    if (validatedCurrentLineId && hasLines) {
      dispatch(
        fetchProductionOrders({
          lineId: validatedCurrentLineId,
          search: searchTerm,
          pagination: { pageIndex: ordersPageIndex, perPage: DEFAULT_ITEMS_PER_PAGE },
        })
      );
    }
  }, [dispatch, hasLines, validatedCurrentLineId, searchTerm, ordersPageIndex]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTermProduction(searchTerm));
    };
  }, [dispatch, searchTerm]);

  let errorText;
  if (!hasLines) errorText = t('data:line.noLines');

  const headerText = () => {
    if (!linesFetched || !runsFetched || !ordersFetched)
      return t('data:productionOrder.loadingProductionOrders');
    else return t('data:productionOrder.productionOrdersFound', { count: totalCount });
  };

  const header = () => {
    return <Typography variant="h1">{headerText()}</Typography>;
  };

  const tabPanel = () => {
    return (
      <Paper>
        {/* KNOWN ISSUE in MUIs Tab component is causing "The `value` provided to the Tabs component is invalid" error            
            Suggested workarounds: https://github.com/mui/material-ui/issues/32749
            Applied workaround: Import tab contents via lazy loading and wrap them with React.Suspense */}
        <StyledTabs value={selectedTab} onChange={handleTabChange}>
          <StyledTab
            label={t('data:productionRun.productionRunsCounted', {
              count: runsCount,
            })}
            {...tabProps(TabItem.ProductionRuns, runsCount === 0)}
          />
          <StyledTab
            label={t('data:productionOrder.productionOrdersCounted', {
              count: ordersCount,
            })}
            {...tabProps(TabItem.ProductionOrders, ordersCount === 0)}
          />
        </StyledTabs>
      </Paper>
    );
  };

  const tabProductionRuns = () => {
    return (
      <TabContent value={selectedTab} index={TabItem.ProductionRuns} tabName={'ProductionRuns'}>
        {
          <Suspense fallback={null}>
            {
              <ProductionRunsListComponent
                handlePageChange={handleRunsPageChange}
                pageIndex={runsPageIndex}
              />
            }
          </Suspense>
        }
      </TabContent>
    );
  };

  const tabProductionOrders = () => {
    return (
      <TabContent value={selectedTab} index={TabItem.ProductionOrders} tabName={'ProductionOrders'}>
        {
          <Suspense fallback={null}>
            {
              <ProductionOrdersListComponent
                handlePageChange={handleOrdersPageChange}
                pageIndex={ordersPageIndex}
              />
            }
          </Suspense>
        }
      </TabContent>
    );
  };

  const searchValueChanged = (value: string) => {
    if (searchTerm !== value) {
      setSearchTerm(value);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      {hasLines && (
        <>
          <FilterArea>
            <GridContainer>
              <GridItem s={4}>
                <LineSelectComponent />
              </GridItem>
              <GridItem s={4}>
                <SearchFieldComponent
                  defaultValue={existingSearchTerm}
                  onSearch={searchValueChanged}
                  placeholder={t('data:productionOrder.orderSearch')}
                />
              </GridItem>
            </GridContainer>
          </FilterArea>
          {header()}
          {linesFetched && runsFetched && ordersFetched && totalCount > 0 && (
            <>
              {tabPanel()}
              {tabProductionRuns()}
              {tabProductionOrders()}
            </>
          )}
        </>
      )}
      {errorText && (
        <LineInfo>
          <Typography variant="h1">{errorText}</Typography>
        </LineInfo>
      )}
    </Box>
  );
};
