import { InputAdornment, TextField } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

export interface OwnProps {
  name: string;
  onInputChange: (sampleIndex: number, value: string) => void;
  sampleIndex: number;
  values: { [id: number]: string | undefined };
  label: string;
  ariaLabel: string;
  suffixUnit?: string;
}

interface NumericFormatCustomProps {
  inputRef: (instance: string | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props: NumericFormatCustomProps,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      allowedDecimalSeparators={['.', ',']}
      decimalScale={3}
      valueIsNumericString
    />
  );
});

export const FormattedSampleInputComponent = (props: OwnProps) => {
  const { name, onInputChange, sampleIndex, values, label, ariaLabel, suffixUnit } = props;
  const inputName = name + '-input';
  const [value, setValue] = useState<string | undefined>('');

  useEffect(() => {
    setValue(values[sampleIndex]);
  }, [values, setValue, sampleIndex]);

  return (
    <TextField
      key={inputName}
      id={inputName}
      name={name}
      label={label}
      variant="outlined"
      value={value}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        endAdornment: <InputAdornment position="end">{suffixUnit}</InputAdornment>,
        'aria-label': ariaLabel,
        onChange: (event) => onInputChange(sampleIndex, event.target.value),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        'data-testid': inputName,
      }}
    />
  );
};
