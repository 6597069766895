import * as signalR from '@microsoft/signalr';

import { WebsocketConfig } from './websockets-config';

export enum WebsocketActionType {
  connectionStart = 'connection/start',
  connectionsStop = 'connections/stop',
  connectionAdd = 'connection/add',
  connectionRemove = 'connection/remove',
}
export const startWsConnection = (config: WebsocketConfig) => ({
  type: WebsocketActionType.connectionStart,
  payload: { config },
});

export const stopWsConnections = () => ({
  type: WebsocketActionType.connectionsStop,
});

export const addConnection = (url: string, connection: signalR.HubConnection) => ({
  type: WebsocketActionType.connectionAdd,
  payload: { url, connection },
});

export const removeConnection = (url: string) => ({
  type: WebsocketActionType.connectionRemove,
  payload: { url },
});
