import { Action } from 'redux';
import { put, select } from 'redux-saga/effects';

import { HttpError } from './../http/http-error';
import { AppRoutePath } from './../routes/routes';
import { logout } from './authentication';

export function* sagaErrorHandler(
  error: HttpError,
  failureActionCreator?: (error: HttpError) => Action
) {
  if (error.status === 401) {
    // @ts-ignore
    const state = yield select();
    if (
      state.router &&
      state.router.location &&
      state.router.location.pathname !== `/${AppRoutePath.login}`
    ) {
      yield put(logout());
    }
  } else {
    if (failureActionCreator) {
      yield put(failureActionCreator(error));
    }
  }
}
