import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import { Avatar, Box, Grid, IconButton, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TareIcon from '../../../assets/img/tara-icon.svg';
import { WEIGHT_ENTITY } from '../../../constants';
import { hasActiveTestRunAfterStart } from '../../../helper';
import { TareMode } from '../../../model';
import {
  checkWeightsSelector,
  deleteCheckWeightSample,
  hasAllRequiredCheckWeightsSelector,
  hasAllRequiredTareWeightsSelector,
  openHelpDialog,
} from '../../../store';
import { irisCustomColors, irisSpacing } from '../../../theme';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { SampleBannerComponent } from '../../sample-banner/sample-banner.component';
import { CheckInfoElement, ContainerInside } from '../../structure';
import {
  CheckMeasurementTableComponent,
  MeasurementColumn,
} from '../check-measurement-table.component';
import { CheckProps } from '../check.component';
import {
  checkInfo,
  checkResultRatingWeighing,
  formattedResult,
  weightMeasurementContent,
  scaleInfo,
  weightMeasurementLink,
  weightResult,
} from '../common/weight-check.components';

const InfoHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
  flexDirection: 'column',
}));

const TareInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const HelpButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
  color: irisCustomColors.irisGreen,
  whiteSpace: 'nowrap',
  padding: 0,
}));

export interface OwnProps {
  checkProps: CheckProps;
  confirmCheck: () => void;
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void;
  testRunActive?: boolean;
  tareDeterminationActive?: boolean;
  deletable?: boolean;
}

export const FertigPackVCheckMeasurementComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const {
    checkProps,
    confirmCheck,
    executeMeasurement,
    testRunActive,
    deletable,
    tareDeterminationActive,
  } = props;
  const { productionRun, openCheckExecution, checkAttribute } = checkProps;
  const { tareMode } = productionRun.article;
  const description =
    testRunActive || tareDeterminationActive ? undefined : checkAttribute.fertigPackVDescription;

  const currentWeights = useSelector(checkWeightsSelector);
  const { productionRunCheckExecutionId: checkExecutionId } = openCheckExecution;
  const hasAllRequiredWeights = useSelector(hasAllRequiredCheckWeightsSelector);
  const hasAllRequiredTareWeights = useSelector(hasAllRequiredTareWeightsSelector);

  const handleOpenHelpDialog = () => {
    dispatch(openHelpDialog());
  };

  const getSampleSize = () => {
    if (testRunActive) {
      return currentWeights ? Object.keys(currentWeights).length + 1 : 1;
    } else {
      return checkAttribute.sampleSize;
    }
  };

  const customSampleBannerText = () => {
    return testRunActive
      ? t('data:check.testRunCaps')
      : tareDeterminationActive
      ? t('data:check.tareCaps')
      : '';
  };

  const tareDeterminationInfoHeader = () => {
    return (
      <Box display={'flex'} flexDirection={'row'} gap={irisSpacing.info.space}>
        <Avatar sx={{ backgroundColor: irisCustomColors.irisGold, height: 70, width: 70 }}>
          <img src={TareIcon} alt={''} style={{ color: irisCustomColors.irisWhite }} />
        </Avatar>
        <Box display={'flex'} flexDirection={'column'}>
          <Box mb={1}>
            <Typography variant="h3">{t('data:check.tareDeterminationAfterStart')}</Typography>
          </Box>
          <CheckInfoElement>
            <Typography>{t('data:check.tareDeterminationDescription')}</Typography>
          </CheckInfoElement>
          {scaleInfo(productionRun)}
        </Box>
      </Box>
    );
  };

  const individualTareInfo = () => {
    return (
      <CheckInfoElement>
        <TareInfoContainer>
          <Typography>{t('data:check.tareDescription')}</Typography>
          <HelpButton onClick={() => handleOpenHelpDialog()} data-testid="openHelpBtn">
            <HelpOutlineIcon fontSize="large" />
            <Typography ml={1}>
              <b>{t('data:check.helpPopover.helpCaps')}</b>
            </Typography>
          </HelpButton>
        </TareInfoContainer>
      </CheckInfoElement>
    );
  };

  const checkInfoHeader = () => {
    return (
      <InfoHeaderContainer>
        {checkInfo(checkAttribute, description)}
        {scaleInfo(productionRun)}
        {tareMode === TareMode.IndividualTare && individualTareInfo()}
      </InfoHeaderContainer>
    );
  };

  const testRunInfoHeader = () => {
    return (
      <Box display={'flex'} flexDirection={'row'} gap={irisSpacing.info.space}>
        <Avatar sx={{ backgroundColor: irisCustomColors.irisGold, height: 70, width: 70 }}>
          <RuleOutlinedIcon sx={{ color: irisCustomColors.irisWhite, fontSize: 48 }} />
        </Avatar>
        <Box display={'flex'} flexDirection={'column'}>
          <Box mb={1}>
            <Typography variant="h3">
              {hasActiveTestRunAfterStart(productionRun)
                ? t('data:check.testRunAfterStart')
                : t('data:check.testRunAfterDisruption')}
            </Typography>
          </Box>
          <CheckInfoElement>
            <Typography>{t('data:check.executeAdjustmentChecks')}</Typography>
          </CheckInfoElement>
          {scaleInfo(productionRun)}
        </Box>
      </Box>
    );
  };

  const testRunBottomInfo = () => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography fontWeight={'bold'}>
          {hasActiveTestRunAfterStart(productionRun)
            ? t('data:check.quitTestRunAnytimeAfterStart')
            : t('data:check.quitTestRunAnytimeAfterDisruption')}
        </Typography>
        <Typography fontWeight={'bold'}>{t('data:check.testRunResultsWillNotBeSaved')}</Typography>
      </Box>
    );
  };

  const deleteMeasurementWithIndex = (sampleIndex: number) => {
    const sampleNumber = sampleIndex + 1;
    dispatch(deleteCheckWeightSample(productionRun.id, checkExecutionId, sampleNumber));
  };

  const deleteMeasurementButton = (sampleIndex: number) => {
    return (
      <IconButton
        color="secondary"
        onClick={() => deleteMeasurementWithIndex(sampleIndex)}
        data-testid={`deleteMeasurementBtn${sampleIndex}`}
      >
        <DeleteForeverOutlinedIcon />
      </IconButton>
    );
  };

  const tareValueResult = (sampleIndex: number) => {
    return (
      <>{formattedResult(currentWeights[`weight${sampleIndex}`]?.tareValue, `${WEIGHT_ENTITY}`)}</>
    );
  };

  const tareAverage = () => {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <Typography fontWeight={'bold'}>
          {`${t('data:check.averageWeight')}`}
          &nbsp;
          {formattedResult(productionRun.article.tareValue, `${WEIGHT_ENTITY}`)}
        </Typography>
      </Box>
    );
  };

  const executeTareMeasurementButton = (sampleIndex: number) => {
    return (
      <Link
        component="button"
        variant="body2"
        underline="always"
        color="secondary"
        onClick={() => executeMeasurement(sampleIndex, true)}
        data-testid={`executeTareMeasurementBtn${sampleIndex}`}
      >
        {t('data:check.executeTareMeasurement')}
      </Link>
    );
  };

  const formattedTareValue = (sampleIndex: number) => {
    return (
      <>
        <Box data-testid={`tareWeighing_${sampleIndex}`}>
          {tareMode === TareMode.IndividualTare ? (
            <>
              {currentWeights && currentWeights[`weight${sampleIndex}`]?.tareValue
                ? tareValueResult(sampleIndex)
                : executeTareMeasurementButton(sampleIndex)}
            </>
          ) : (
            <>{formattedResult(productionRun.article.tareValue, `${WEIGHT_ENTITY}`)}</>
          )}
        </Box>
      </>
    );
  };

  const netResult = (sampleIndex: number) => {
    return (
      <Box data-testid={`weighing_${sampleIndex}`}>
        {weightResult(currentWeights[`weight${sampleIndex}`])}
        {deletable && <>{deleteMeasurementButton(sampleIndex)}</>}
      </Box>
    );
  };

  const measurementLink = (sampleIndex: number) => {
    const disabled = !hasAllRequiredTareWeights && tareMode === TareMode.IndividualTare;
    return weightMeasurementLink(sampleIndex, executeMeasurement, disabled);
  };

  const getMeasurementLink = (sampleIndex: number) => {
    return weightMeasurementContent(
      currentWeights[`weight${sampleIndex}`]?.weightDisplay,
      netResult(sampleIndex),
      measurementLink(sampleIndex)
    );
  };

  const checkResultRating = (sampleIndex: number) => {
    return checkResultRatingWeighing(sampleIndex, currentWeights[`weight${sampleIndex}`]);
  };

  const tareColumn: MeasurementColumn = {
    label: t('data:check.tareValueHeader'),
    content: formattedTareValue,
  };

  const resultColumn: MeasurementColumn = {
    label: t('data:check.netResult'),
    content: getMeasurementLink,
  };

  const ratingColumn: MeasurementColumn = {
    label: t('data:check.rating'),
    content: checkResultRating,
  };

  return (
    <Paper>
      {testRunActive || tareDeterminationActive ? (
        <SampleBannerComponent
          sampleSize={0}
          customText={customSampleBannerText()}
          customBackgroundColor={irisCustomColors.irisRed}
          customTextColor={irisCustomColors.irisWhite}
        />
      ) : (
        getSampleSize() > 1 && <SampleBannerComponent sampleSize={getSampleSize()} />
      )}
      <ContainerInside>
        {testRunActive
          ? testRunInfoHeader()
          : tareDeterminationActive
          ? tareDeterminationInfoHeader()
          : checkInfoHeader()}
        {tareDeterminationActive ? (
          <CheckMeasurementTableComponent
            resultColumn={{ ...resultColumn, label: t('data:check.result') }}
            sampleSize={getSampleSize()}
          />
        ) : (
          <CheckMeasurementTableComponent
            sampleSize={getSampleSize()}
            tareColumn={tareColumn}
            resultColumn={resultColumn}
            ratingColumn={ratingColumn}
          />
        )}
        <Box mt={irisSpacing.button.mt}>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="right"
            spacing={irisSpacing.button.space}
            flexWrap={'nowrap'}
          >
            <Grid item>
              <ConfirmButtonComponent
                handleClick={confirmCheck}
                disabled={!testRunActive && !hasAllRequiredWeights}
                customText={
                  !testRunActive
                    ? undefined
                    : hasActiveTestRunAfterStart(productionRun)
                    ? t('data:check.startProductionRun')
                    : t('data:check.continueProductionRun')
                }
              />
            </Grid>
            {testRunActive && <Grid item>{testRunBottomInfo()}</Grid>}
            {tareDeterminationActive && <Grid item>{tareAverage()}</Grid>}
          </Grid>
        </Box>
      </ContainerInside>
    </Paper>
  );
};
