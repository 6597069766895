export class HttpError extends Error {
  public status?: number;
  public internalErrorCode?: number;
  public data: any;

  constructor(message: string, status?: number, internalErrorCode?: number, data: any = null) {
    super(message);
    this.message = message;
    this.status = status;
    this.internalErrorCode = internalErrorCode;
    this.data = data;
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}
