import { styled } from '@mui/material/styles';

import { irisSpecialColors } from '../../theme';

const PREFIX = 'StyledDataTable';
export const dataTableClasses = {
  dataTable: `${PREFIX}-dataTable`,
};

export const DataTableRoot = styled('div')(({ theme }) => ({
  [`& .${dataTableClasses.dataTable}`]: {
    '& tr': {
      verticalAlign: 'text-top',
    },
    '& td': {
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& td:nth-of-type(1)': {
      color: irisSpecialColors.attributeCaption,
    },
    '& td:nth-of-type(2)': {
      wordBreak: 'break-word',
    },
  },
}));
