import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSuffix } from '../../helper';
import { ToleranceResult } from '../../model';
import { irisCheckResultColors, irisCustomColors } from '../../theme';
import { FormattedNumberOutputComponent } from '../formatted-number-output/formatted-number-output.component';

import {
  StylePropsWrapper,
  toleranceBarClasses as classes,
  ToleranceBarRoot,
} from './tolerance-bar.styles';

export interface OwnProps {
  nominalValue?: number;
  tolerances?: ToleranceResult;
  lowerTolerance?: number;
  upperTolerance?: number;
  averageValue?: number;
  averagePassed?: boolean;
  averageSufficient?: boolean;
  averageFailed?: boolean;
}

const getStyleProps = (props: OwnProps) => {
  const returnProps: StylePropsWrapper = {
    styleProps: {
      averageValueMarginLeft: calcAverageValueMarginLeft(props),
      lowerToleranceRatio: calcLowerToleranceRatio(props),
      upperToleranceRatio: calcUpperToleranceRatio(props),
    },
  };
  return returnProps;
};

const getTolerance = (props: OwnProps, forTo1?: boolean) => {
  if (forTo1) {
    return props.tolerances?.volume ? props.tolerances.volume.tO1 : props.tolerances?.weight?.tO1;
  } else {
    return props.tolerances?.volume ? props.tolerances.volume.tU1 : props.tolerances?.weight?.tU1;
  }
};

const calcAverageValueMarginLeft = (props: OwnProps) => {
  if (props && props.tolerances && props.nominalValue && props.averageValue) {
    const percentCenter: number = 0;
    const percentOutmost: number = 100;

    const tu1Tolerance = getTolerance(props);
    const centerToOutmostDelta = Math.abs((props.nominalValue - tu1Tolerance!) * 3);
    const centerToAverageDelta: number = Math.abs(props.nominalValue - props.averageValue);
    const sign: number = props.nominalValue <= props.averageValue ? 1 : -1;

    let percentResult: number = percentCenter;
    if (centerToAverageDelta > centerToOutmostDelta) percentResult = percentOutmost;
    else percentResult = (centerToAverageDelta / centerToOutmostDelta) * percentOutmost;
    percentResult *= sign;

    return percentResult + '%';
  } else return undefined;
};

const calcLowerToleranceRatio = (props: OwnProps) => {
  const tu1Tolerance = getTolerance(props);

  return calcToleranceRatio(props.nominalValue, tu1Tolerance!, props.lowerTolerance);
};

const calcUpperToleranceRatio = (props: OwnProps) => {
  const to1Tolerance = getTolerance(props, true);

  return calcToleranceRatio(props.nominalValue, to1Tolerance!, props.upperTolerance);
};

const calcToleranceRatio = (
  nominalValue?: number,
  tolerance?: number,
  customTolerance?: number
) => {
  if (nominalValue && tolerance && customTolerance) {
    const basedTolerance = nominalValue - tolerance;
    const basedCustomTolerance = nominalValue - customTolerance;
    const customToleranceRatio = (100 / basedTolerance) * basedCustomTolerance;

    return customToleranceRatio;
  } else return 50;
};

export const ToleranceBarComponent = (props: OwnProps) => {
  const { t } = useTranslation(['table']);
  const {
    nominalValue,
    tolerances,
    lowerTolerance,
    upperTolerance,
    averageValue,
    averagePassed,
    averageSufficient,
    averageFailed,
  } = props;

  const currentSuffix = getSuffix(!!tolerances?.volume);
  const [currentTolerances, setCurrentTolerances] = React.useState(
    tolerances?.volume ? tolerances.volume : tolerances?.weight
  );

  React.useEffect(() => {
    setCurrentTolerances(tolerances?.volume ? tolerances.volume : tolerances?.weight);
  }, [tolerances]);

  const showLowerToleranceElements = () => {
    return (
      currentTolerances &&
      nominalValue &&
      lowerTolerance &&
      lowerTolerance >= currentTolerances?.tU1 &&
      lowerTolerance < nominalValue
    );
  };

  const showUpperToleranceElements = () => {
    return (
      currentTolerances &&
      nominalValue &&
      upperTolerance &&
      upperTolerance <= currentTolerances?.tO1 &&
      upperTolerance > nominalValue
    );
  };

  const getLowerToleranceItemClassName = (baseClassName: string) => {
    const ratio = calcLowerToleranceRatio(props);
    return ratio < 25 ? `${baseClassName} ${classes.rightAlignedLabel}` : baseClassName;
  };

  const getUpperToleranceItemClassName = (baseClassName: string) => {
    const ratio = calcUpperToleranceRatio(props);
    return ratio < 25 ? `${baseClassName} ${classes.leftAlignedLabel}` : baseClassName;
  };

  const innerLowerToleranceBar = () => {
    if (showLowerToleranceElements()) {
      return (
        <div className={`${classes.bar}`}>
          <Grid container className={classes.toleranceBarContainer}>
            <div className={`${classes.lowerToleranceYellowBar} ${classes.yellowBar}`} />
            <Box
              className={getLowerToleranceItemClassName(classes.lineAndLabelBox)}
              data-testid={'lowerToleranceLabelItem'}
            >
              <div className={classes.verticalLine}></div>
              <div className={getLowerToleranceItemClassName(classes.absoluteLabelItemContainer)}>
                <Typography> {t('data:check.lowerToleranceCustomShort')}</Typography>
                <FormattedNumberOutputComponent value={lowerTolerance} suffix={currentSuffix} />
              </div>
            </Box>
            <div className={`${classes.lowerToleranceGreenBar} ${classes.greenBar}`} />
          </Grid>
        </div>
      );
    } else {
      return <div className={`${classes.bar} ${classes.greenBar}`}> </div>;
    }
  };

  const innerUpperToleranceBar = () => {
    if (showUpperToleranceElements()) {
      return (
        <div className={`${classes.bar}`}>
          <Grid container className={classes.toleranceBarContainer}>
            <div className={`${classes.upperToleranceGreenBar} ${classes.greenBar}`} />
            <Box className={getUpperToleranceItemClassName(classes.lineAndLabelBox)}>
              <div className={classes.verticalLine}></div>
              <div
                className={getUpperToleranceItemClassName(classes.absoluteLabelItemContainer)}
                data-testid={'upperToleranceLabelItem'}
              >
                <Typography> {t('data:check.upperToleranceCustomShort')}</Typography>
                <FormattedNumberOutputComponent value={upperTolerance} suffix={currentSuffix} />
              </div>
            </Box>
            <div className={`${classes.upperToleranceYellowBar} ${classes.yellowBar}`} />
          </Grid>
        </div>
      );
    } else {
      return <div className={`${classes.bar} ${classes.greenBar}`} />;
    }
  };

  return (
    <ToleranceBarRoot styleProps={getStyleProps(props).styleProps}>
      <Grid container className={classes.outerContainer} data-testid={'toleranceBarOuterContainer'}>
        <Grid container className={classes.labelContainer}>
          <div className={classes.labelPaddingItem}> </div>
          <div className={classes.labelItemContainer}>
            <Typography> {t('data:check.lowerTolerance2Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tU2 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={classes.labelItemContainer}>
            <Typography> {t('data:check.lowerTolerance1Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tU1 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={classes.labelItemContainer}>
            <Typography> {t('data:check.nominalValue')}</Typography>
            <FormattedNumberOutputComponent value={nominalValue || '-'} suffix={currentSuffix} />
          </div>
          <div className={classes.labelItemContainer}>
            <Typography> {t('data:check.upperTolerance1Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tO1 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={classes.labelItemContainer}>
            <Typography> {t('data:check.upperTolerance2Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tO2 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={classes.labelPaddingItem}> </div>
        </Grid>
        <Grid container className={classes.barContainer}>
          <div className={`${classes.bar} ${classes.redBar}`}> </div>
          <div className={classes.verticalLine}></div>
          <div className={`${classes.bar} ${classes.yellowBar}`}> </div>
          <div className={classes.verticalLine}></div>
          {innerLowerToleranceBar()}
          <div className={classes.verticalLine}></div>
          {innerUpperToleranceBar()}
          <div className={classes.verticalLine}></div>
          <div className={`${classes.bar} ${classes.yellowBar}`}> </div>
          <div className={classes.verticalLine}></div>
          <div className={`${classes.bar} ${classes.redBar}`}> </div>
        </Grid>
        {averageValue && (
          <>
            <Grid
              container
              className={`${classes.averageContainer}`}
              data-testid={'averageContainer'}
            >
              <div className={`${classes.moveContainer}`} data-testid={'moveContainer'}>
                <div className={classes.averageBoxContainer}>
                  <div className={classes.verticalLineStrong}></div>
                  <Box
                    className={classes.averageBox}
                    data-testid={'averageBox'}
                    bgcolor={
                      averagePassed
                        ? irisCheckResultColors.passed
                        : averageSufficient
                        ? irisCheckResultColors.sufficient
                        : averageFailed
                        ? irisCheckResultColors.failed
                        : irisCustomColors.irisGrayBright
                    }
                  >
                    <Box className={classes.xAcross}>{'x̄'}</Box>
                    <FormattedNumberOutputComponent value={averageValue} suffix={currentSuffix} />
                  </Box>
                  <div className={classes.verticalLineStrong}></div>
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      {showLowerToleranceElements() || showUpperToleranceElements() ? (
        <div
          className={classes.customToleranceBottomSpace}
          data-testid={'customToleranceBottomSpace'}
        ></div>
      ) : (
        averageValue && (
          <div
            className={classes.averageBoxBottomSpace}
            data-testid={'averageBoxBottomSpace'}
          ></div>
        )
      )}
    </ToleranceBarRoot>
  );
};
