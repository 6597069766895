import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import { createStore, combineReducers, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { authenticationReducer } from './authentication/authentication.reducer';
import { authenticationSaga } from './authentication/authentication.saga';
import { checkExecutionsSaga } from './check-executions';
import { checkExecutionsReducer } from './check-executions/check-executions.reducer';
import { devicesSaga } from './devices/devices.saga';
import { licenseReducer } from './license/license.reducer';
import { licenseSaga } from './license/license.saga';
import { linesMiddleware } from './lines/lines.middleware';
import { linesReducer } from './lines/lines.reducer';
import { linesSaga } from './lines/lines.saga';
import { notificationsReducer } from './notifications/notifications.reducer';
import { productionOrdersReducer } from './production-orders/production-orders.reducer';
import { productionOrdersSaga } from './production-orders/production-orders.saga';
import { productionRunsReducer } from './production-runs/production-runs.reducer';
import { productionRunsSaga } from './production-runs/production-runs.saga';
import { usersReducer } from './users/users.reducer';
import { usersSaga } from './users/users.saga';
import { websocketsMiddleware } from './websockets/websockets.middleware';
import { websocketConnectionsReducer } from './websockets/websockets.reducer';
import { weightsReducer } from './weights/weights.reducer';
import { weightsSaga } from './weights/weights.saga';
export const history = createHashHistory();

const reducers = {
  authentication: authenticationReducer,
  productionOrders: productionOrdersReducer,
  productionRuns: productionRunsReducer,
  lines: linesReducer,
  notifications: notificationsReducer,
  weights: weightsReducer,
  users: usersReducer,
  license: licenseReducer,
  checkExecutions: checkExecutionsReducer,
  websocketConnections: websocketConnectionsReducer,
  router: connectRouter(history),
};

function* rootSaga() {
  yield all([
    authenticationSaga(),
    productionOrdersSaga(),
    productionRunsSaga(),
    linesSaga(),
    weightsSaga(),
    usersSaga(),
    licenseSaga(),
    devicesSaga(),
    checkExecutionsSaga(),
  ]);
}

const allReducers = combineReducers({ ...reducers });
export type AppState = ReturnType<typeof allReducers>;

const rootReducer = (state: any, action: Action) => {
  return allReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      websocketsMiddleware,
      linesMiddleware
    )
  )
);

sagaMiddleware.run(rootSaga);
