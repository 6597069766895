import { Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CheckAttributeType } from '../../../model';
import { confirmCheckForProductionRun } from '../../../store';
import { GridContainer, GridLevel } from '../../grid/grid.container';
import { GridItem } from '../../grid/grid.item';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { MultilineSampleInputComponent } from '../../multiline-sample-input/multiline-sample-input.component';
import { SampleBannerComponent } from '../../sample-banner/sample-banner.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../structure';
import { CheckProps } from '../check.component';
import {
  CheckNameComponent,
  CheckInputContainer,
  CheckImageComponent,
  CheckConfirmationButtonComponent,
  CheckDescriptionComponent,
} from '../common/check.components';

export const FreeTextCheckComponent = (props: CheckProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const { productionRun, openCheckExecution, isDisruptionDialogOpen, checkAttribute } = props;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [currentTexts, setCurrentTexts] = useState<{ [id: number]: string | undefined }>({});
  const [hasAllRequiredTexts, setHasAllRequiredTexts] = useState<boolean>(false);
  const [hasValidTexts, setHasValidTexts] = useState<boolean>(false);

  const { sampleSize } = checkAttribute;

  useEffect(() => {
    setSubmitted(false);
    setCurrentTexts({});
    setHasAllRequiredTexts(false);
    setHasValidTexts(false);
  }, [openCheckExecution, isDisruptionDialogOpen]);

  const confirmCheck = () => {
    if (hasAllRequiredTexts && hasValidTexts) {
      const texts = [...Array(sampleSize).keys()].map((sampleIndex) => {
        return currentTexts[sampleIndex]!;
      });

      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          CheckAttributeType.FreeText,
          {
            texts,
          }
        )
      );
    }
  };

  const textValueInputChanged = (sampleIndex: number, value: string) => {
    if (currentTexts[sampleIndex] !== value) {
      let texts = currentTexts;
      texts[sampleIndex] = value;
      setCurrentTexts(texts);
      validateTextCount();
      validateTextValues();
    }
  };

  const validateTextCount = () => {
    for (let i = 0; i < sampleSize; i++) {
      const val = currentTexts[i];
      if (val === undefined || val === '') {
        setHasAllRequiredTexts(false);
        return;
      }
    }
    setHasAllRequiredTexts(true);
  };

  const validateTextValues = () => {
    for (let i = 0; i < sampleSize; i++) {
      const val = currentTexts[i];
      if (
        val !== undefined &&
        !(val === '' || (val !== '' && val.length > 0 && val.length <= 256))
      ) {
        setHasValidTexts(false);
        return;
      }
    }
    setHasValidTexts(true);
  };

  const confirmationHandler = () => {
    setSubmitted(true);
    confirmCheck();
  };

  const getCheckName = () => {
    return <CheckNameComponent name={checkAttribute.name} sampleBanner={sampleSize > 1} />;
  };

  const getCheckDescription = () => {
    return <CheckDescriptionComponent text={checkAttribute.freeTextDescription!} />;
  };

  const getCheckImage = () => {
    return <CheckImageComponent imageUrl={checkAttribute?.freeTextImageUrl} />;
  };

  const getInputField = (sampleIndex: number) => {
    return (
      <CheckInputContainer>
        {sampleSize > 1 && (
          <Typography fontWeight={'bold'}>
            {`${t('data:check.sampleNumber', { sampleNumber: sampleIndex + 1 })}`}
          </Typography>
        )}
        <div>
          <MultilineSampleInputComponent
            name={`textValue_${openCheckExecution.productionRunCheckExecutionId}_${sampleIndex}_${isDisruptionDialogOpen}`}
            onInputChange={textValueInputChanged}
            sampleIndex={sampleIndex}
            values={currentTexts}
            label={t('form:input')}
            ariaLabel={t('form:input')}
          />
        </div>
      </CheckInputContainer>
    );
  };

  const getInfoBox = () => {
    return (
      <InfoboxComponent
        headline={
          sampleSize > 1
            ? t('data:check.textLimitsError', { max: 256 })
            : t('data:check.textLimitError', { max: 256 })
        }
        type="error"
      />
    );
  };

  const getConfirmationButton = () => {
    return (
      <CheckConfirmationButtonComponent
        handleClick={confirmationHandler}
        disabled={!hasAllRequiredTexts}
      />
    );
  };

  const gridColsM = sampleSize === 1 ? 12 : 6;

  return (
    <ContainerOutsideWithHeader>
      <Paper>
        {sampleSize > 1 && <SampleBannerComponent sampleSize={sampleSize} />}
        <ContainerInside>
          {getCheckName()}
          <GridContainer level={GridLevel.CheckPaper}>
            {checkAttribute.freeTextDescription && <GridItem>{getCheckDescription()}</GridItem>}
            {checkAttribute.freeTextImageUrl && <GridItem>{getCheckImage()}</GridItem>}
            {[...Array(sampleSize || 1).keys()].map((sampleIndex) => {
              return (
                <GridItem m={gridColsM} key={sampleIndex}>
                  {getInputField(sampleIndex)}
                </GridItem>
              );
            })}
            {submitted && !hasValidTexts && <GridItem>{getInfoBox()}</GridItem>}
            <GridItem>{getConfirmationButton()}</GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
    </ContainerOutsideWithHeader>
  );
};
