import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import { CheckResult } from '../../model';
import { irisCheckResultColors, irisCustomColors } from '../../theme';

const PREFIX = 'CheckResultIconComponent';

const classes = {
  checkSize: `${PREFIX}-checkSize`,
  weightResultIcon: `${PREFIX}-weightResultIcon`,
  weightPassed: `${PREFIX}-weightPassed`,
  weightSufficient: `${PREFIX}-weightSufficient`,
  weightFailed: `${PREFIX}-weightFailed`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.checkSize}`]: {
    width: '30px',
    height: '30px',
    marginRight: theme.spacing(2),
  },

  [`& .${classes.weightResultIcon}`]: {
    color: irisCustomColors.irisWhite,
  },

  [`& .${classes.weightPassed}`]: {
    backgroundColor: irisCheckResultColors.passed,
  },

  [`& .${classes.weightSufficient}`]: {
    backgroundColor: irisCheckResultColors.sufficient,
  },

  [`& .${classes.weightFailed}`]: {
    backgroundColor: irisCheckResultColors.failed,
  },
}));

export interface OwnProps {
  checkResult: CheckResult;
}

export const CheckResultIconComponent = (props: OwnProps) => {
  const { checkResult } = props;

  const getClassName = (checkResult: CheckResult) => {
    const resultClass =
      checkResult === CheckResult.Passed
        ? classes.weightPassed
        : checkResult === CheckResult.Sufficient
        ? classes.weightSufficient
        : classes.weightFailed;

    return `${resultClass} ${classes.checkSize}`;
  };

  return (
    <Root>
      {checkResult !== CheckResult.Unknown && (
        <Avatar className={getClassName(checkResult)}>
          {checkResult === CheckResult.Failed ? (
            <CloseIcon className={classes.weightResultIcon} />
          ) : (
            <CheckIcon className={classes.weightResultIcon} />
          )}
        </Avatar>
      )}
    </Root>
  );
};
