import './i18next';
import 'typeface-roboto';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import App from './App';
import { store, history } from './store';
import { irisTheme } from './theme';

const AppWrapper = () => {
  return (
    <ThemeProvider theme={irisTheme}>
      <Provider store={store}>
        <Suspense fallback={<h1>Loading app...</h1>}>
          <ConnectedRouter history={history}>
            <HashRouter basename="/">
              <CssBaseline>
                <App />
              </CssBaseline>
            </HashRouter>
          </ConnectedRouter>
        </Suspense>
      </Provider>
    </ThemeProvider>
  );
};

export default AppWrapper;
