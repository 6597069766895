import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getTare, getWeighingCategory, WeighingCategory } from '../../../helper';
import { CheckAttributeType } from '../../../model';
import {
  checkExecutionsWebsocketConfig,
  executeCheckWeight,
  fetchCheckWeightSamples,
  initCheckWeights,
  resetCheckWeightState,
  setScaleTareValue,
  startWsConnection,
} from '../../../store';
import { CheckProps } from '../check.component';
import { FertigPackVCheckComponent } from '../fertigpackv-check/fertigpackv-check.component';
import { WeightCheckComponent } from '../weight-check/weight-check.component';

export const WeighingComponent = (checkProps: CheckProps) => {
  const dispatch = useDispatch();
  const { productionRun, openCheckExecution, checkAttribute, isDisruptionDialogOpen } = checkProps;
  const { sampleSize, checkAttributeType } = checkAttribute;
  const weighingCategory = getWeighingCategory(checkAttributeType);
  const { id: productionRunId, article } = productionRun;
  const { productionRunCheckExecutionId: checkExecutionId } = openCheckExecution;
  const tareDeterminationActive = checkAttributeType === CheckAttributeType.Tare;
  const tareValue = getTare(checkAttribute, article);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const scaleId = productionRun.scaleDevice?.id;

  useEffect(() => {
    dispatch(startWsConnection(checkExecutionsWebsocketConfig));
  }, [dispatch]);

  useEffect(() => {
    dispatch(initCheckWeights(sampleSize));
  }, [dispatch, sampleSize, checkExecutionId, isDisruptionDialogOpen]);

  useEffect(() => {
    dispatch(fetchCheckWeightSamples(productionRunId, checkExecutionId));
    setIsFirstRender(true);
    return () => {
      dispatch(resetCheckWeightState());
      if (scaleId) dispatch(setScaleTareValue(0, scaleId, true));
    };
  }, [dispatch, productionRunId, checkExecutionId, scaleId]);

  useEffect(() => {
    if (scaleId && isFirstRender) {
      dispatch(setScaleTareValue(!tareDeterminationActive ? tareValue ?? 0 : 0, scaleId));
      setIsFirstRender(false);
    }
  }, [isFirstRender, scaleId, tareValue, dispatch, tareDeterminationActive]);

  const executeMeasurement = (sampleIndex: number, forTare: boolean) => {
    const sampleNumber = sampleIndex + 1;
    productionRun.scaleDevice &&
      dispatch(
        executeCheckWeight(
          sampleNumber,
          productionRun.id,
          checkExecutionId,
          productionRun.scaleDevice.id,
          forTare
        )
      );
  };

  switch (weighingCategory) {
    case WeighingCategory.FertigPackV:
      return (
        <FertigPackVCheckComponent
          checkProps={checkProps}
          executeMeasurement={executeMeasurement}
        />
      );
    case WeighingCategory.Weight:
      return (
        <WeightCheckComponent checkProps={checkProps} executeMeasurement={executeMeasurement} />
      );
    default:
      return <></>;
  }
};
