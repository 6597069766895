import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { hasIssue } from '../../helper';
import { IssueCode, ProductionRun } from '../../model';
import { irisSpacing } from '../../theme';

import { ViolationInfoComponent } from './violation-info.component';

const PREFIX = 'ViolationDialogComponent';
const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const ViolationDialogComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const isDialogOpen = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);

  return (
    <StyledDialog open={isDialogOpen} maxWidth="md" className={classes.dialog}>
      <DialogContent>
        <ViolationInfoComponent />
      </DialogContent>
    </StyledDialog>
  );
};
