import { styled } from '@mui/material/styles';

import { irisSpacing } from '../../theme';

// For standalone containers like tables or papers (without outside headers)
export const ContainerOutside = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(irisSpacing.container.space),
}));

// For containers not needing a top margin (usually having outside headers)
export const ContainerOutsideWithHeader = styled('div')(({ theme }) => ({
  marginTop: 0,
}));

export const ContainerInside = styled('div')(({ theme }) => ({
  padding: theme.spacing(irisSpacing.container.p),
}));
