import { License } from '../../model';

export enum LicenseActionType {
  licenseFetch = 'license/fetch',
  licenseFetchSuccess = 'license/fetch/success',
  licenseFetchFailure = 'license/fetch/failure',
}

export const fetchLicense = () => ({
  type: LicenseActionType.licenseFetch,
});

export const fetchLicenseSuccess = (license: License) => ({
  type: LicenseActionType.licenseFetchSuccess,
  payload: { license },
});

export const fetchLicenseFailure = () => ({
  type: LicenseActionType.licenseFetchFailure,
});
