import { Line } from '../../model';

export enum LinesActionType {
  linesFetchAll = 'lines/fetchAll',
  linesFetchAllSuccess = 'lines/fetchAll/success',
  linesFetchAllFailure = 'lines/fetchAll/failure',
  lineChange = 'line/change',
  lineInit = 'line/init',
}

export const fetchLines = () => ({
  type: LinesActionType.linesFetchAll,
});

export const fetchLinesSuccess = (lines: Line[]) => ({
  type: LinesActionType.linesFetchAllSuccess,
  payload: { lines },
});

export const fetchLinesFailure = () => ({
  type: LinesActionType.linesFetchAllFailure,
});

export const changeLine = (lineId: string) => ({
  type: LinesActionType.lineChange,
  payload: { lineId },
});

export const initLine = (lineId: string) => ({
  type: LinesActionType.lineInit,
  payload: { lineId },
});
