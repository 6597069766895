import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { queryDataToUrl } from '../../helper';
import { SagaRequest, SagaRequestHelper } from '../../http';
import { ProductionOrder, ProductionRun } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  ProductionOrdersActionType,
  fetchProductionOrdersFailure,
  startProductionOrder as startProductionOrderAction,
  startProductionOrderSuccess,
  startProductionOrderFailure,
  fetchProductionOrdersSuccess,
  fetchProductionOrders,
  reloadProductionOrdersSilent,
  reloadProductionOrdersSilentFailure,
  reloadProductionOrdersSilentSuccess,
} from './production-orders.actions';

const productionOrdersUrl = 'production/orders';
const startableFilter = '?startable=1';
const start = 'start';

function* fetchOrders(action: Action) {
  const { query } = (
    action as ReturnType<typeof fetchProductionOrders | typeof reloadProductionOrdersSilent>
  ).payload;
  if (!query.lineId) return;

  const orderQueryUrl = queryDataToUrl(query);
  return yield* call<
    [boolean, string],
    SagaRequest<{ data: ProductionOrder[]; metadata: { total: number } }>
  >(SagaRequestHelper.get, true, `${productionOrdersUrl}${startableFilter}&${orderQueryUrl}`);
}

function* getProductionOrders(action: Action) {
  try {
    const response = yield* fetchOrders(action);
    if (response) yield put(fetchProductionOrdersSuccess(response.data, response.metadata.total));
    else return;
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionOrdersFailure);
  }
}

function* reloadProductionOrders(action: Action) {
  try {
    const response = yield* fetchOrders(action);
    if (response)
      yield put(reloadProductionOrdersSilentSuccess(response.data, response.metadata.total));
    else return;
  } catch (e: any) {
    yield sagaErrorHandler(e, reloadProductionOrdersSilentFailure);
  }
}

function* startProductionOrder(action: Action) {
  const { id } = (action as ReturnType<typeof startProductionOrderAction>).payload;
  try {
    const productionRun = yield* call<[boolean, string], SagaRequest<ProductionRun>>(
      SagaRequestHelper.post,
      true,
      `${productionOrdersUrl}/${id}/${start}`
    );
    yield put(startProductionOrderSuccess(productionRun, id));
    yield put(push(`/${AppRoutePath.productionRuns}/${productionRun.id}`));
  } catch (e: any) {
    yield sagaErrorHandler(e, startProductionOrderFailure);
  }
}

export function* fetchProductionOrdersSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrdersFetchAll, getProductionOrders);
}

export function* reloadProductionOrdersSilentSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrdersReloadSilent, reloadProductionOrders);
}

export function* startProductionOrderSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrderStart, startProductionOrder);
}

export function* productionOrdersSaga() {
  yield fork(fetchProductionOrdersSaga);
  yield fork(reloadProductionOrdersSilentSaga);
  yield fork(startProductionOrderSaga);
}
