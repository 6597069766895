import { DEFAULT_LINE_ID } from '../../constants';
import { QueryParams } from '../../model';

const parameterSign = (url: string) => {
  return url === '' ? '' : '&';
};

export const queryDataToUrl = (params: QueryParams) => {
  let url = '';
  if (params.lineId !== DEFAULT_LINE_ID)
    url += `productionLineId=${encodeURIComponent(params.lineId)}`;
  if (params.search) {
    url += `${parameterSign(url)}search=${encodeURIComponent(params.search)}`;
  }
  if (params.pagination) {
    url += `${parameterSign(url)}page=${params.pagination.pageIndex + 1}`;
    url += `${parameterSign(url)}per_page=${params.pagination.perPage}`;
  }
  return url;
};
