import { ProductionOrder } from '../../model';

export enum ProductionOrdersWebsocketActionType {
  productionOrderChanged = 'productionOrder/changed',
  productionOrderDeleted = 'productionOrder/deleted/',
}

export const changedProductionOrder = (productionOrder: ProductionOrder) => ({
  type: ProductionOrdersWebsocketActionType.productionOrderChanged,
  payload: { productionOrder },
});

export const deletedProductionOrder = (response: { productionOrderId: string }) => ({
  type: ProductionOrdersWebsocketActionType.productionOrderDeleted,
  payload: { id: response.productionOrderId },
});
