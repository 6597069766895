import * as signalR from '@microsoft/signalr';
import produce from 'immer';
import { Action } from 'redux';

import { WebsocketActionType, addConnection, removeConnection } from './websockets.actions';

export interface WebsocketConnectionsState {
  connections: { [id: string]: signalR.HubConnection };
}

export const getInitialState = (): WebsocketConnectionsState => {
  return {
    connections: {},
  };
};

export const websocketConnectionsReducer = (
  previousState: WebsocketConnectionsState = getInitialState(),
  action: Action<WebsocketActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case WebsocketActionType.connectionAdd:
      const addAction = action as ReturnType<typeof addConnection>;
      nextState = produce(previousState, (draftState) => {
        const { url, connection } = addAction.payload;
        draftState.connections[url] = connection;
      });
      break;

    case WebsocketActionType.connectionRemove:
      const removeAction = action as ReturnType<typeof removeConnection>;
      nextState = produce(previousState, (draftState) => {
        const { url } = removeAction.payload;
        delete draftState.connections[url];
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
