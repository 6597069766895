import produce from 'immer';
import { Action } from 'redux';

import { totalPages } from '../../helper';
import { ProductionOrder } from '../../model';
import { AuthenticationActionType } from '../authentication';
import { LinesActionType } from '../lines';
import { deleteItemFromArray, updateItemInArray } from '../reducer-utils';

import {
  ProductionOrdersActionType,
  fetchProductionOrdersSuccess,
  startProductionOrderSuccess,
  setSearchTermProduction,
  setProductionOrdersCurrentPageIndex,
  reloadProductionOrdersSilentSuccess,
  removeProductionOrder,
} from './production-orders.actions';
import {
  ProductionOrdersWebsocketActionType,
  changedProductionOrder as changedProductionOrderAction,
  deletedProductionOrder as deletedProductionOrderAction,
} from './production-orders.websocket-actions';

export interface ProductionOrdersState {
  productionOrders?: ProductionOrder[];
  itemsTotalCount?: number;
  currentPageIndex?: number;
  productionOrdersFetched?: boolean;
  searchTerm?: string;
  silentReload?: boolean;
}

export const getInitialState = (): ProductionOrdersState => {
  return {};
};

const updateCurrentPageIndex = (draftState: ProductionOrdersState) => {
  const maxPages = totalPages(draftState.itemsTotalCount);
  const maxPageIndex = maxPages - 1;
  if (maxPageIndex < 0) {
    delete draftState.currentPageIndex;
  } else if (draftState.currentPageIndex && draftState.currentPageIndex > maxPageIndex)
    draftState.currentPageIndex = maxPageIndex;
};

const setProductionOrders = (
  draftState: ProductionOrdersState,
  action: ReturnType<
    typeof fetchProductionOrdersSuccess | typeof reloadProductionOrdersSilentSuccess
  >
) => {
  const { productionOrders, total } = action.payload;
  draftState.productionOrders = productionOrders;
  draftState.itemsTotalCount = total;
};

const setProductionOrder = (
  draftState: ProductionOrdersState,
  productionOrder: ProductionOrder
) => {
  draftState.productionOrders = updateItemInArray(draftState.productionOrders, productionOrder);
};

const deleteProductionOrder = (draftState: ProductionOrdersState, productionOrderId: string) => {
  draftState.productionOrders = deleteItemFromArray(draftState.productionOrders, productionOrderId);
};

export const productionOrdersReducer = (
  previousState: ProductionOrdersState = getInitialState(),
  action: Action<
    | ProductionOrdersActionType
    | ProductionOrdersWebsocketActionType
    | LinesActionType
    | AuthenticationActionType
  >
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ProductionOrdersActionType.productionOrdersFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.productionOrdersFetched = false;
      });
      break;

    case ProductionOrdersActionType.productionOrdersFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        setProductionOrders(draftState, action as ReturnType<typeof fetchProductionOrdersSuccess>);
        draftState.productionOrdersFetched = true;
      });
      break;

    case ProductionOrdersActionType.productionOrdersFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.productionOrdersFetched = true;
      });
      break;

    case ProductionOrdersActionType.productionOrdersReloadSilentSuccess:
      nextState = produce(previousState, (draftState) => {
        setProductionOrders(
          draftState,
          action as ReturnType<typeof reloadProductionOrdersSilentSuccess>
        );
        draftState.silentReload = false;
        updateCurrentPageIndex(draftState);
      });
      break;

    case ProductionOrdersActionType.productionOrdersReloadSilentFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.silentReload = false;
      });
      break;

    case ProductionOrdersActionType.productionOrderStartSuccess:
      const startSuccessAction = action as ReturnType<typeof startProductionOrderSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { orderId } = startSuccessAction.payload;
        deleteProductionOrder(draftState, orderId);
      });
      break;

    case ProductionOrdersActionType.productionOrderSetSearchTerm:
      nextState = produce(previousState, (draftState) => {
        const searchTermAction = action as ReturnType<typeof setSearchTermProduction>;
        draftState.searchTerm = searchTermAction.payload.searchTerm;
      });
      break;

    case ProductionOrdersActionType.productionOrdersCurrentPageSet:
      nextState = produce(previousState, (draftState) => {
        const currentPageAction = action as ReturnType<typeof setProductionOrdersCurrentPageIndex>;
        draftState.currentPageIndex = currentPageAction.payload.pageIndex;
      });
      break;

    case ProductionOrdersActionType.productionOrderRemove:
    case ProductionOrdersWebsocketActionType.productionOrderDeleted:
      nextState = produce(previousState, (draftState) => {
        const deletedAction = action as ReturnType<
          typeof deletedProductionOrderAction | typeof removeProductionOrder
        >;
        const deletedProductionOrderId = deletedAction.payload.id;
        deleteProductionOrder(draftState, deletedProductionOrderId);
        draftState.silentReload = true;
      });
      break;

    case ProductionOrdersWebsocketActionType.productionOrderChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<typeof changedProductionOrderAction>;
        const changedProductionOrder = changedAction.payload.productionOrder;

        if (!changedProductionOrder.startable) {
          deleteProductionOrder(draftState, changedProductionOrder.id);
        } else {
          setProductionOrder(draftState, changedProductionOrder);
        }
        draftState.silentReload = true;
      });
      break;

    case LinesActionType.lineChange:
      nextState = produce(previousState, (draftState) => {
        delete draftState.productionOrders;
        draftState.productionOrdersFetched = false;
      });
      break;

    case AuthenticationActionType.login:
      nextState = produce(previousState, (draftState) => {
        delete draftState.searchTerm;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
