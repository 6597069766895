import { TextField } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';

export interface OwnProps {
  name: string;
  onInputChange: (sampleIndex: number, value: string) => void;
  sampleIndex: number;
  values: { [id: number]: string | undefined };
  label: string;
  ariaLabel: string;
}

export const MultilineSampleInputComponent = (props: OwnProps) => {
  const { name, onInputChange, sampleIndex, values, label, ariaLabel } = props;
  const inputName = name + '-input';
  const [value, setValue] = useState<string | undefined>('');

  useEffect(() => {
    setValue(values[sampleIndex]);
  }, [values, setValue, sampleIndex]);

  return (
    <TextField
      key={inputName}
      id={inputName}
      name={name}
      label={label}
      variant="outlined"
      defaultValue={value}
      multiline={true}
      rows={2}
      style={{ width: '100%' }}
      InputProps={{
        'aria-label': ariaLabel,
        onChange: (event) => onInputChange(sampleIndex, event.target.value),
      }}
      inputProps={{
        'data-testid': inputName,
      }}
    />
  );
};
