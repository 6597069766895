import { IssueCode, ProductionRun, ProductionRunIssue } from '../../model';

export const filterIssues = (issues: ProductionRunIssue[], allowedCodes?: IssueCode[]) => {
  if (allowedCodes)
    return issues.filter((issue) => allowedCodes.some((allowed) => allowed === issue.issueCode));
  else return issues;
};

export const hasIssue = (productionRun: ProductionRun, issueCode: IssueCode) => {
  return productionRun.issues.some((i) => i.issueCode === issueCode);
};

export const haveIssue = (productionRuns: ProductionRun[], issueCode: IssueCode) => {
  return productionRuns.some((pr) => hasIssue(pr, issueCode));
};
