import React from 'react';

import { ChangePasswordAfterResetFormComponent } from '../../components/form/change-password-after-reset-form/change-password-after-reset-form.component';
import { StartScreenLayoutComponent } from '../../components/layout/start-screen-layout/start-screen-layout.component';

export const ChangePasswordPage = () => {
  return (
    <>
      <StartScreenLayoutComponent>
        <ChangePasswordAfterResetFormComponent />
      </StartScreenLayoutComponent>
    </>
  );
};
