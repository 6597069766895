import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { ProductionRun } from '../../model';
import { disruptionDialogOpenSelector } from '../../store';
import { irisSpacing } from '../../theme';

import { DisruptionFormComponent } from './disruption-form.component';

const PREFIX = 'CheckSkipDialogComponent';
const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '1000px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

export interface OwnProps {
  handleResolve: (reason: string, deviceId?: string) => void;
  handleStop: (reason: string, deviceId?: string) => void;
  productionRun: ProductionRun;
}

export const DisruptionDialogComponent = (props: OwnProps) => {
  const { productionRun, handleResolve, handleStop } = props;

  const isSkipCheckDialogOpen = useSelector(disruptionDialogOpenSelector);

  return (
    <StyledDialog open={isSkipCheckDialogOpen} maxWidth="md" className={classes.dialog}>
      <DialogContent>
        <DisruptionFormComponent
          resolve={handleResolve}
          stop={handleStop}
          productionRun={productionRun}
        ></DisruptionFormComponent>
      </DialogContent>
    </StyledDialog>
  );
};
