import React from 'react';

import { RequestResetPasswordFormComponent } from '../../components/form/request-reset-password-form/request-reset-password-form.component';
import { StartScreenLayoutComponent } from '../../components/layout/start-screen-layout/start-screen-layout.component';

export const RequestResetPasswordPage = () => {
  return (
    <>
      <StartScreenLayoutComponent>
        <RequestResetPasswordFormComponent />
      </StartScreenLayoutComponent>
    </>
  );
};
