import React from 'react';

import { OpenCheckExecution, ProductionRun, CheckAttributeType, CheckAttribute } from '../../model';

import { BarcodeCheckComponent } from './barcode-check/barcode-check.component';
import { WeighingComponent } from './common/weighing.component';
import { FreeTextCheckComponent } from './free-text-check/free-text-check.component';
import { TemperatureCheckComponent } from './temperature-check/temperature-check.component';
import { TrafficLightCheckComponent } from './traffic-light-check/traffic-light-check.component';
import { VolumeCheckComponent } from './volume-check/volume-check.component';
import { YesNoCheckComponent } from './yes-no-check/yes-no-check.component';

export interface CheckProps {
  productionRun: ProductionRun;
  openCheckExecution: OpenCheckExecution;
  isDisruptionDialogOpen: boolean;
  checkAttribute: CheckAttribute;
}

export const getCheckComponent = (checkProps: CheckProps) => {
  const { checkAttribute } = checkProps;
  switch (checkAttribute.checkAttributeType) {
    case CheckAttributeType.YesNo:
      return <YesNoCheckComponent {...checkProps} />;
    case CheckAttributeType.TrafficLight:
      return <TrafficLightCheckComponent {...checkProps} />;
    case CheckAttributeType.FertigPackV:
    case CheckAttributeType.TestRun:
    case CheckAttributeType.Tare:
    case CheckAttributeType.Weight:
      return <WeighingComponent {...checkProps} />;
    case CheckAttributeType.Temperature:
      return <TemperatureCheckComponent {...checkProps} />;
    case CheckAttributeType.Volume:
      return <VolumeCheckComponent {...checkProps} />;
    case CheckAttributeType.FreeText:
      return <FreeTextCheckComponent {...checkProps} />;
    case CheckAttributeType.Barcode:
      return <BarcodeCheckComponent {...checkProps} />;
  }
};
