import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactElement } from 'react';

import { IssueType } from '../../model';

export type InfoboxType = 'error' | 'warning' | 'success';

export const mapToInfoboxType = (issueType: IssueType) => {
  switch (issueType) {
    case IssueType.Error:
      return 'error';
    case IssueType.Warning:
      return 'warning';
    default:
      return 'success';
  }
};

const ColorContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  border: 'solid 1px',
  borderRadius: 0,
  padding: theme.spacing(2),
  borderColor: theme.palette[styleProps.type].main,
  backgroundColor: theme.palette[styleProps.type].light,
}));

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const SuccessIcon = styled(CheckCircleIcon, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  color: theme.palette[styleProps.type].main,
  marginRight: theme.spacing(1),
}));

const FailureIcon = styled(WarningIcon, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  color: theme.palette[styleProps.type].main,
  marginRight: theme.spacing(1),
}));

interface StylePropsWrapper {
  styleProps: {
    type: InfoboxType;
  };
}

export interface OwnProps {
  type: InfoboxType;
  customElement?: ReactElement;
  headline?: string;
  subline?: string | ReactElement;
  direction?: 'column' | 'row';
}

export const InfoboxComponent = (props: OwnProps) => {
  const { type, customElement, headline, subline, direction } = props;

  return (
    <Paper>
      <ColorContainer data-testid="infoboxComponent" styleProps={{ type }}>
        <FlexContainer className={`infobox ${type}`}>
          {customElement ? (
            customElement
          ) : (
            <>
              {type === 'success' ? (
                <SuccessIcon styleProps={{ type }} />
              ) : (
                <FailureIcon styleProps={{ type }} />
              )}
              <Grid item>
                <Grid container spacing={1} flexDirection={direction ? direction : 'column'}>
                  {headline && (
                    <Grid item>
                      <Box>
                        <Typography variant="h5">{headline}</Typography>
                      </Box>
                    </Grid>
                  )}
                  {subline && (
                    <Grid item>
                      <Box>
                        <Typography variant="h6">{subline}</Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </FlexContainer>
      </ColorContainer>
    </Paper>
  );
};
