import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { VOLUME_ENTITY, WEIGHT_ENTITY } from '../../constants';
import {
  checkWeightsSelector,
  clearCheckWeight,
  fetchCheckWeight,
  hasAllRequiredCheckWeightsSelector,
  initCheckWeights,
  setScaleTareValue,
} from '../../store';
import { irisSpacing } from '../../theme';
import { ConfirmButtonComponent } from '../buttons/confirm-button.component';
import { CheckResultIconComponent } from '../check-result-icon/check-result-icon.component';
import { SampleBannerComponent } from '../sample-banner/sample-banner.component';
import { CheckInfoElement, ContainerInside } from '../structure';

import {
  CheckMeasurementTableComponent,
  MeasurementColumn,
} from './check-measurement-table.component';
import {
  checkMeasurementClasses,
  CheckMeasurementRoot,
} from './check-measurement.component.styles';
import { CheckProps } from './check.component';
import { scaleInfo } from './common/weight-check.components';

export interface OwnProps {
  checkProps: CheckProps;
  confirmCheck: () => void;
  description?: string;
  tareValue?: number;
}

export const CheckMeasurementComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const { checkProps, confirmCheck, description, tareValue } = props;
  const { productionRun, openCheckExecution, isDisruptionDialogOpen, checkAttribute } = checkProps;
  const { sampleSize } = checkAttribute;
  const checkExecutionId = openCheckExecution.productionRunCheckExecutionId;

  const currentWeights = useSelector(checkWeightsSelector);
  const hasAllRequiredWeights = useSelector(hasAllRequiredCheckWeightsSelector);

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const scaleId = productionRun.scaleDevice?.id;

  useEffect(() => {
    dispatch(initCheckWeights(sampleSize));
    return () => {
      if (scaleId) dispatch(setScaleTareValue(0, scaleId, true));
    };
  }, [dispatch, sampleSize, checkExecutionId, isDisruptionDialogOpen, scaleId]);

  useEffect(() => {
    setIsFirstRender(true);
  }, [dispatch, checkExecutionId]);

  useEffect(() => {
    if (scaleId && isFirstRender) {
      dispatch(setScaleTareValue(tareValue ? tareValue : 0, scaleId));
      setIsFirstRender(false);
    }
  }, [tareValue, isFirstRender, dispatch, scaleId]);

  const deleteMeasurementWithIndex = (sampleIndex: number) => {
    dispatch(clearCheckWeight(sampleIndex));
  };

  const executeMeasurement = (sampleIndex: number) => {
    productionRun.scaleDevice &&
      dispatch(
        fetchCheckWeight(
          sampleIndex,
          productionRun.id,
          checkExecutionId,
          productionRun.scaleDevice.id
        )
      );
  };

  const formattedResult = (value?: number, suffix?: string) => {
    return value ? (
      <>
        <NumericFormat
          value={value}
          decimalScale={3}
          decimalSeparator={(t('form:decimalSeparator') as string) || ','}
          displayType="text"
        />
        {suffix && <span className={checkMeasurementClasses.suffix}>{`${suffix}`}</span>}
      </>
    ) : undefined;
  };

  const executeMeasurementButton = (sampleIndex: number) => {
    return (
      <Link
        component="button"
        variant="body2"
        underline="always"
        color="secondary"
        onClick={() => executeMeasurement(sampleIndex)}
        data-testid={`executeMeasurementBtn${sampleIndex}`}
      >
        {t('data:check.executeMeasurement')}
      </Link>
    );
  };

  const deleteMeasurementButton = (sampleIndex: number) => {
    return (
      <Link
        component="button"
        variant="body2"
        underline="always"
        color="secondary"
        onClick={() => deleteMeasurementWithIndex(sampleIndex)}
        data-testid={`deleteMeasurementBtn${sampleIndex}`}
      >
        {t('data:check.deleteMeasurement')}
      </Link>
    );
  };

  const confirmationHandler = () => {
    confirmCheck();
  };

  const checkInfoHeader = () => {
    return (
      <Box className={checkMeasurementClasses.check}>
        <Box className={checkMeasurementClasses.headline}>
          <Typography variant="h3">{checkAttribute.name}</Typography>
        </Box>
        {description && (
          <CheckInfoElement>
            <Box>
              <Typography>{description}</Typography>
            </Box>
          </CheckInfoElement>
        )}
        {scaleInfo(productionRun)}
        <CheckInfoElement>
          <Typography>
            <span className={checkMeasurementClasses.bold}>
              {t('data:check.tareValue', {
                tareValue: tareValue ? tareValue : '- ',
              })}
            </span>
          </Typography>
        </CheckInfoElement>
      </Box>
    );
  };

  const netResult = (sampleIndex: number) => {
    return (
      <Box data-testid={`weighing_${sampleIndex}`}>
        {currentWeights[`weight${sampleIndex}`]?.volumeDisplay && (
          <>
            {formattedResult(
              currentWeights[`weight${sampleIndex}`]?.volumeDisplay,
              `${VOLUME_ENTITY}`
            )}
            <span className={checkMeasurementClasses.separator}>{`/`}</span>
          </>
        )}
        {formattedResult(currentWeights[`weight${sampleIndex}`]?.weightDisplay, `${WEIGHT_ENTITY}`)}
      </Box>
    );
  };

  const checkResultRating = (sampleIndex: number) => {
    return (
      <div className={checkMeasurementClasses.sampleContainer}>
        {currentWeights[`weight${sampleIndex}`]?.checkResult && (
          <CheckResultIconComponent
            key={sampleIndex}
            checkResult={currentWeights[`weight${sampleIndex}`]!.checkResult!}
          />
        )}
        {currentWeights[`weight${sampleIndex}`]?.fertigPackVCategory &&
          t([
            `data:check.fertigPackVCategories.${
              currentWeights[`weight${sampleIndex}`]?.fertigPackVCategory
            }`,
            `data:check.fertigPackVCategories.notFound`,
          ])}
      </div>
    );
  };

  const actionButton = (sampleIndex: number) => {
    return (
      <>
        {currentWeights[`weight${sampleIndex}`]
          ? deleteMeasurementButton(sampleIndex)
          : executeMeasurementButton(sampleIndex)}
      </>
    );
  };

  const resultColumn: MeasurementColumn = {
    label: t('data:check.netResult'),
    content: netResult,
  };

  const ratingColumn: MeasurementColumn = {
    label: t('data:check.rating'),
    content: checkResultRating,
  };

  const actionColumn: MeasurementColumn = {
    label: t('data:check.action'),
    content: actionButton,
  };

  return (
    <CheckMeasurementRoot>
      <Paper className={checkMeasurementClasses.paper}>
        {sampleSize > 1 && <SampleBannerComponent sampleSize={sampleSize} />}
        <ContainerInside>
          {checkInfoHeader()}
          <CheckMeasurementTableComponent
            resultColumn={resultColumn}
            ratingColumn={ratingColumn}
            actionColumn={actionColumn}
            sampleSize={sampleSize}
          />

          <Box mt={irisSpacing.button.mt}>
            <Grid
              container
              direction="row-reverse"
              justifyContent="space-between"
              alignItems="right"
              spacing={irisSpacing.button.space}
              flexWrap={'nowrap'}
            >
              <Grid item>
                <ConfirmButtonComponent
                  handleClick={confirmationHandler}
                  disabled={!hasAllRequiredWeights}
                />
              </Grid>
            </Grid>
          </Box>
        </ContainerInside>
      </Paper>
    </CheckMeasurementRoot>
  );
};
