import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { isRunPausedManually } from '../../helper';
import { ProductionRun } from '../../model';
import { irisSpacing } from '../../theme';

import { PauseInfoComponent } from './pause-info.component';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthMd': {
    width: '800px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(irisSpacing.container.p),
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
}

export const PauseDialogComponent = (props: OwnProps) => {
  const { productionRun } = props;

  const paused = isRunPausedManually(productionRun);

  return (
    <StyledDialog open={paused} maxWidth="md" data-testid="pauseDialog">
      <DialogContent data-testid="pauseDialogContent">
        <PauseInfoComponent productionRun={productionRun} />
      </DialogContent>
    </StyledDialog>
  );
};
