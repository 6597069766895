export enum DevicesActionType {
  scaleSetTare = 'scale/setTare',
  scaleSetTareSuccess = 'scale/setTare/success',
  scaleSetTareFailure = 'scale/setTare/failure',
}

export const setScaleTareValue = (tareValue: number, scaleId: string, cleanup?: boolean) => ({
  type: DevicesActionType.scaleSetTare,
  payload: { tareValue, scaleId, cleanup },
});

export const setScaleTareValueSuccess = () => ({
  type: DevicesActionType.scaleSetTareSuccess,
});

export const setScaleTareValueFailure = (cleanup?: boolean) => ({
  type: DevicesActionType.scaleSetTareFailure,
  payload: { cleanup },
});
