import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CheckAttributeType } from '../../../model';
import { confirmCheckForProductionRun } from '../../../store';
import { GridContainer, GridLevel } from '../../grid/grid.container';
import { GridItem } from '../../grid/grid.item';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { SampleBannerComponent } from '../../sample-banner/sample-banner.component';
import { SampleSizeSelectComponent } from '../../sample-size-select/sample-size-select.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../structure';
import { CheckProps } from '../check.component';
import {
  CheckNameComponent,
  CheckInputContainer,
  CheckNarrowContainer,
  CheckImageComponent,
  CheckConfirmationButtonComponent,
  CheckDescriptionComponent,
} from '../common/check.components';

export const YesNoCheckComponent = (props: CheckProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const { productionRun, openCheckExecution, isDisruptionDialogOpen, checkAttribute } = props;
  const { sampleSize } = checkAttribute;

  const [answeredYes, setAnsweredYes] = useState<number | string>('');
  const [answeredNo, setAnsweredNo] = useState<number | string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hasAllRequiredSamples, setHasAllRequiredSamples] = useState<boolean>(true);

  useEffect(() => {
    let sum = answeredYes ? (answeredYes as number) : 0;
    sum += answeredNo ? (answeredNo as number) : 0;
    setHasAllRequiredSamples(sum === sampleSize);
  }, [answeredYes, answeredNo, sampleSize]);

  useEffect(() => {
    setAnsweredYes('');
    setAnsweredNo('');
    setSubmitted(false);
  }, [openCheckExecution, isDisruptionDialogOpen]);

  const confirmCheck = (answeredYes: number, answeredNo: number) => {
    dispatch(
      confirmCheckForProductionRun(productionRun.id, openCheckExecution, CheckAttributeType.YesNo, {
        answeredYes,
        answeredNo,
      })
    );
  };

  const confirmationHandler = () => {
    setSubmitted(true);
    hasAllRequiredSamples &&
      confirmCheck(
        answeredYes ? (answeredYes as number) : 0,
        answeredNo ? (answeredNo as number) : 0
      );
  };

  const getCheckName = () => {
    return <CheckNameComponent name={checkAttribute.name} sampleBanner={sampleSize > 1} />;
  };

  const getCheckDescription = () => {
    return <CheckDescriptionComponent text={checkAttribute.yesNoQuestion!} />;
  };

  const getCheckImage = () => {
    return <CheckImageComponent imageUrl={checkAttribute?.yesNoImageUrl} />;
  };

  const getDropdown = (
    text: string,
    value: string | number,
    action: React.Dispatch<React.SetStateAction<string | number>>
  ) => {
    return (
      <CheckInputContainer>
        <Typography fontWeight={'bold'}>{text}</Typography>
        <SampleSizeSelectComponent
          sampleSize={sampleSize}
          sampleValue={value}
          setSampleValue={action}
        />
      </CheckInputContainer>
    );
  };

  const getDropdownYes = () => {
    return getDropdown(t('data:check.answerIsYes'), answeredYes, setAnsweredYes);
  };

  const getDropdownNo = () => {
    return getDropdown(t('data:check.answerIsNo'), answeredNo, setAnsweredNo);
  };

  const getButton = (
    text: string,
    alignment: 'flex-start' | 'flex-end',
    clickHandler: React.MouseEventHandler<HTMLButtonElement>
  ) => {
    return (
      <Box display={'flex'} justifyContent={alignment}>
        <Button color="primary" variant="contained" onClick={clickHandler}>
          {text}
        </Button>
      </Box>
    );
  };

  const getButtonYes = () => {
    return getButton(t('data:check.yes'), 'flex-end', () => confirmCheck(1, 0));
  };

  const getButtonNo = () => {
    return getButton(t('data:check.no'), 'flex-start', () => confirmCheck(0, 1));
  };

  const getInfoBox = () => {
    return <InfoboxComponent headline={t('data:check.sampleSizeDoesNotMatchError')} type="error" />;
  };

  const getConfirmationButton = () => {
    return <CheckConfirmationButtonComponent handleClick={confirmationHandler} />;
  };

  return (
    <ContainerOutsideWithHeader>
      <Paper>
        {sampleSize > 1 && <SampleBannerComponent sampleSize={sampleSize} />}
        <ContainerInside>
          {getCheckName()}
          <GridContainer level={GridLevel.CheckPaper}>
            {checkAttribute.yesNoQuestion && <GridItem>{getCheckDescription()}</GridItem>}
            {checkAttribute.yesNoImageUrl && <GridItem>{getCheckImage()}</GridItem>}
            <GridItem>
              <CheckNarrowContainer>
                <GridContainer level={GridLevel.CheckPaper}>
                  {sampleSize > 1 ? (
                    <>
                      <GridItem s={6}>{getDropdownYes()}</GridItem>
                      <GridItem s={6}>{getDropdownNo()}</GridItem>
                    </>
                  ) : (
                    <>
                      <GridItem s={6}>{getButtonYes()}</GridItem>
                      <GridItem s={6}>{getButtonNo()}</GridItem>
                    </>
                  )}
                  {submitted && !hasAllRequiredSamples && <GridItem>{getInfoBox()}</GridItem>}
                </GridContainer>
              </CheckNarrowContainer>
            </GridItem>
            {sampleSize > 1 && <GridItem>{getConfirmationButton()}</GridItem>}
          </GridContainer>
        </ContainerInside>
      </Paper>
    </ContainerOutsideWithHeader>
  );
};
