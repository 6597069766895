import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PREFIX = 'SampleSizeSelectComponent';

const classes = {
  formControl: `${PREFIX}-formControl`,
  select: `${PREFIX}-select`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    width: '100%',
  },

  [`& .${classes.select}`]: {
    minWidth: '200px',
  },
}));

export interface OwnProps {
  sampleValue: number | string;
  setSampleValue: (value: string | number) => void;
  sampleSize: number;
}

export const SampleSizeSelectComponent = (props: OwnProps) => {
  const { sampleSize, sampleValue, setSampleValue } = props;
  const { t } = useTranslation(['data']);

  return (
    <StyledFormControl className={classes.formControl}>
      <InputLabel id="sampleSize-label" variant="outlined">
        {t('data:check.sampleSize')}
      </InputLabel>
      <Select
        className={classes.select}
        fullWidth={true}
        labelId="sampleSize-label"
        label={t('data:check.sampleSize')}
        value={sampleValue}
        variant="outlined"
        onChange={(event) => setSampleValue(event.target.value as number)}
        data-testid="select-sample-size"
      >
        {[...Array(sampleSize + 1).keys()].map((index: number) => (
          <MenuItem value={index} key={index} data-testid={'select-sample-size-option'}>
            {index}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};
