import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { checkWeightsSelector, hasAllRequiredCheckWeightsSelector } from '../../../store';
import { irisSpacing } from '../../../theme';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { SampleBannerComponent } from '../../sample-banner/sample-banner.component';
import { CheckInfoElement, ContainerInside } from '../../structure';
import {
  CheckMeasurementTableComponent,
  MeasurementColumn,
} from '../check-measurement-table.component';
import { CheckProps } from '../check.component';
import {
  checkInfo,
  checkResultRatingWeighing,
  weightMeasurementContent,
  scaleInfo,
  weightMeasurementLink,
  weightResult,
} from '../common/weight-check.components';

const InfoHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
  flexDirection: 'column',
}));

export interface OwnProps {
  checkProps: CheckProps;
  confirmCheck: () => void;
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void;
}

export const WeightCheckMeasurementComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data', 'form']);
  const { checkProps, confirmCheck, executeMeasurement } = props;
  const { productionRun, checkAttribute } = checkProps;
  const { weightDescription: description, weightTareValue: tareValue, sampleSize } = checkAttribute;

  const currentWeights = useSelector(checkWeightsSelector);
  const hasAllRequiredWeights = useSelector(hasAllRequiredCheckWeightsSelector);

  const checkInfoHeader = () => {
    return (
      <InfoHeaderContainer data-testid={'checkInfoHeader'}>
        {checkInfo(checkAttribute, description)}
        {scaleInfo(productionRun)}
        {tareInfo()}
      </InfoHeaderContainer>
    );
  };

  const tareInfo = () => {
    return (
      <CheckInfoElement>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>
            {t('data:check.tareValue', {
              tareValue: tareValue ? tareValue : '- ',
            })}
          </span>
        </Typography>
      </CheckInfoElement>
    );
  };

  const netResult = (sampleIndex: number) => {
    return (
      <Box data-testid={`weighing_${sampleIndex}`}>
        {weightResult(currentWeights[`weight${sampleIndex}`])}
      </Box>
    );
  };

  const measurementLink = (sampleIndex: number) => {
    return weightMeasurementLink(sampleIndex, executeMeasurement);
  };

  const getMeasurementLink = (sampleIndex: number) => {
    return weightMeasurementContent(
      currentWeights[`weight${sampleIndex}`]?.weightDisplay,
      netResult(sampleIndex),
      measurementLink(sampleIndex)
    );
  };

  const checkResultRating = (sampleIndex: number) => {
    return checkResultRatingWeighing(sampleIndex, currentWeights[`weight${sampleIndex}`]);
  };

  const resultColumn: MeasurementColumn = {
    label: t('data:check.netResult'),
    content: getMeasurementLink,
  };

  const ratingColumn: MeasurementColumn = {
    label: t('data:check.rating'),
    content: checkResultRating,
  };

  return (
    <Paper data-testid={'weightCheckMeasurementComponent'}>
      {sampleSize > 1 && <SampleBannerComponent sampleSize={sampleSize} />}
      <ContainerInside>
        {checkInfoHeader()}
        <CheckMeasurementTableComponent
          sampleSize={sampleSize}
          resultColumn={resultColumn}
          ratingColumn={ratingColumn}
        />
        <Box mt={irisSpacing.button.mt}>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="right"
            spacing={irisSpacing.button.space}
            flexWrap={'nowrap'}
          >
            <Grid item>
              <ConfirmButtonComponent
                handleClick={confirmCheck}
                disabled={!hasAllRequiredWeights}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerInside>
    </Paper>
  );
};
