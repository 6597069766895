import { Box, Paper } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Pie, DefaultRawDatum, PieSvgProps, ComputedDatum } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';

import { ContainerInside } from '../../components/structure';
import { ProductionRun } from '../../model';
import { irisCheckResultColors, irisCustomColors } from '../../theme';
import { getPercentage } from '../../utils/calc-utils';

const CenteringContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

const Cutout = styled('div')(({ theme }) => ({
  marginLeft: -120,
  overflow: 'hidden',
}));

const PieContainer = styled('div')(({ theme }) => ({
  height: '370px',
  backgroundColor: irisCustomColors.irisWhite,
  width: '650px',
  minWidth: '650px',
  left: -30,
}));

export interface OwnProps {
  productionRun: ProductionRun;
  noCheckResultsText: string;
}

export const ProductionRunPieChartComponent = (props: OwnProps) => {
  const theme = useTheme();
  const { productionRun, noCheckResultsText } = props;

  const { t } = useTranslation(['data']);

  const getPieProps = (): PieSvgProps<OwnProps> => {
    const spacingPx: string = theme.spacing(1);
    const spacing: number = +spacingPx.substring(0, spacingPx.length - 2);

    const props = {
      theme: {
        labels: {
          text: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
          },
        },
        legends: {
          text: {
            fontSize: theme.typography.body1.fontSize,
          },
        },
      },
      height: 370,
      width: 524,
      margin: {
        top: spacing * 4,
        right: spacing * 8,
        bottom: spacing * 4,
        left: spacing * 8,
      },
      colors: (d: DefaultRawDatum) => {
        switch (d.id) {
          case t('data:productionRun.checkResultsPassed'):
            return irisCheckResultColors.passed;
          case t('data:productionRun.checkResultsSufficient'):
            return irisCheckResultColors.sufficient;
          case t('data:productionRun.checkResultsFailed'):
            return irisCheckResultColors.failed;
          case t('data:productionRun.checkResultsUnrated'):
            return irisCheckResultColors.unrated;
          case t('data:productionRun.checkResultsSkipped'):
            return irisCheckResultColors.skipped;
          default:
            return irisCustomColors.irisGrayBright;
        }
      },
      animate: false,
      padAngle: 1,
      data: [],
      innerRadius: 0.7,
      isInteractive: true,
      arcLabel: (d: DefaultRawDatum) =>
        `${getPercentage(d.value, productionRun ? productionRun.checkSamplesDone : 0.0)}%`,
      tooltip: <R,>({ datum }: { datum: ComputedDatum<R> }) => {
        var percentage = `${getPercentage(
          datum.value,
          productionRun ? productionRun.checkSamplesDone : 0.0
        )}%`;
        return (
          <BasicTooltip
            id={datum.label}
            value={percentage}
            color={datum.color}
            enableChip
          ></BasicTooltip>
        );
      },
      arcLabelsTextColor: (d: DefaultRawDatum) => {
        switch (d.id) {
          case t('data:productionRun.checkResultsUnrated'):
            return irisCustomColors.irisWhite;
          default:
            return theme.palette.text.primary;
        }
      },
      enableArcLinkLabels: false,
      activeOuterRadiusOffset: 15,
    };

    // don't add 0 values so labels won't overlap
    if (productionRun) {
      const productionRunData = [];
      productionRun.checkSamplesPassed &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsPassed') as string,
          value: productionRun.checkSamplesPassed,
        });

      productionRun.checkSamplesSufficient &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsSufficient') as string,
          value: productionRun.checkSamplesSufficient,
        });

      productionRun.checkSamplesFailed &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsFailed') as string,
          value: productionRun.checkSamplesFailed,
        });

      productionRun.checkSamplesUnrated &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsUnrated') as string,
          value: productionRun.checkSamplesUnrated,
        });

      productionRun.checkSamplesSkipped &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsSkipped') as string,
          value: productionRun.checkSamplesSkipped,
        });

      props.data = productionRunData as any;
    }

    return props;
  };

  return (
    <>
      {productionRun?.checkSamplesDone > 0 ? (
        <Paper>
          <ContainerInside>
            <CenteringContainer>
              <Cutout>
                <PieContainer>
                  <AutoSizer>
                    {({ height, width }) => (
                      <Pie
                        {...getPieProps()}
                        height={height}
                        width={width}
                        legends={[
                          {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            itemsSpacing: 16,
                            symbolSize: 20,
                            symbolShape: 'circle',
                            itemWidth: 70,
                            itemHeight: 16,
                          },
                        ]}
                      />
                    )}
                  </AutoSizer>
                </PieContainer>
              </Cutout>
            </CenteringContainer>
          </ContainerInside>
        </Paper>
      ) : (
        <Box>{noCheckResultsText}</Box>
      )}
    </>
  );
};
