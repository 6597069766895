import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { User, UserRole } from '../model';

import { AppRoutePath } from './routes';

export interface OwnProps extends RouteProps {
  loggedInUser?: User;
  minRole: UserRole;
  changePassword: boolean;
}

export const PrivateRoute: FunctionComponent<OwnProps> = ({
  component: Component,
  loggedInUser,
  minRole,
  changePassword,
  ...rest
}) => {
  if (!!loggedInUser) {
    if (changePassword) {
      return <Redirect to={`/${AppRoutePath.changePassword}`} />;
    } else {
      return <Route {...rest} component={Component} />;
    }
  }
  return <Redirect to={`/${AppRoutePath.login}`} />;
};
