import produce from 'immer';
import { Action } from 'redux';

import {
  CheckExecutionsWebsocketActionType,
  changedCheckExecution as changedCheckExecutionAction,
} from '../check-executions/check-executions.websocket-actions';

import { ProductionRunCheckExecutionWeighingSampleResult } from './../../model';
import {
  executeCheckWeightSuccess,
  clearCheckWeight,
  deleteCheckWeightSampleSuccess,
  fetchCheckWeightSuccess,
  initCheckWeights,
  WeightsActionType,
  fetchCheckWeightSamplesSuccess,
  fetchCheckWeightSamples,
} from './weights.actions';

export interface WeightsState {
  currentCheckSampleSize: number;
  currentCheckExecutionId?: string;
  checkWeights: { [id: string]: ProductionRunCheckExecutionWeighingSampleResult | undefined };
}

export const getInitialState = (): WeightsState => ({
  currentCheckSampleSize: 1,
  checkWeights: {},
});

export const weightsReducer = (
  previousState: WeightsState = getInitialState(),
  action: Action<WeightsActionType | CheckExecutionsWebsocketActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case WeightsActionType.checkWeightInit:
      const initAction = action as ReturnType<typeof initCheckWeights>;
      nextState = produce(previousState, (draftState) => {
        const { sampleSize } = initAction.payload;
        draftState.currentCheckSampleSize = sampleSize > 0 ? sampleSize : 1;
        draftState.checkWeights = {};
      });
      break;

    case WeightsActionType.checkWeightFetchSuccess:
      const fetchSuccessAction = action as ReturnType<typeof fetchCheckWeightSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { sampleIndex, weight } = fetchSuccessAction.payload;
        if (sampleIndex < previousState.currentCheckSampleSize) {
          draftState.checkWeights[`weight${sampleIndex}`] = weight;
        }
      });
      break;

    case WeightsActionType.checkWeightClear:
      const clearAction = action as ReturnType<typeof clearCheckWeight>;
      nextState = produce(previousState, (draftState) => {
        const { sampleIndex } = clearAction.payload;
        if (sampleIndex < previousState.currentCheckSampleSize) {
          draftState.checkWeights[`weight${sampleIndex}`] = undefined;
        }
      });
      break;

    case WeightsActionType.checkWeightExecuteSuccess:
      const addSuccessAction = action as ReturnType<typeof executeCheckWeightSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { sampleNumber, weight, isTaring } = addSuccessAction.payload;

        if (sampleNumber && sampleNumber <= previousState.currentCheckSampleSize) {
          if (isTaring) {
            if (!draftState.checkWeights[`weight${sampleNumber - 1}`]) {
              const tareWeight: Partial<ProductionRunCheckExecutionWeighingSampleResult> = {
                tareValue: weight.weightDisplay,
                weighingOn: weight.weighingOn,
              };
              draftState.checkWeights[`weight${sampleNumber - 1}`] =
                tareWeight as ProductionRunCheckExecutionWeighingSampleResult;
            }
          } else {
            draftState.checkWeights[`weight${sampleNumber - 1}`] = weight;
          }
        }
      });
      break;

    case WeightsActionType.checkWeightSamplesFetch:
      const fetchSamplesAction = action as ReturnType<typeof fetchCheckWeightSamples>;
      nextState = produce(previousState, (draftState) => {
        draftState.currentCheckExecutionId = fetchSamplesAction.payload.executionId;
      });
      break;

    case WeightsActionType.checkWeightSamplesFetchSuccess:
      const fetchSamplesSuccessAction = action as ReturnType<typeof fetchCheckWeightSamplesSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { checkExecution } = fetchSamplesSuccessAction.payload;
        const samples = checkExecution.samples;

        if (
          samples &&
          0 < samples.length &&
          samples.length <= previousState.currentCheckSampleSize
        ) {
          samples.map((sample) => {
            if (sample.weightDisplay || sample.tareValue) {
              draftState.checkWeights[`weight${sample.sampleNumber - 1}`] =
                sample as ProductionRunCheckExecutionWeighingSampleResult;
            }
            return draftState;
          });
        }
      });
      break;

    case CheckExecutionsWebsocketActionType.checkExecutionChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<typeof changedCheckExecutionAction>;
        const changedCheckExecution = changedAction.payload.checkExecution;
        const { id, samples } = changedCheckExecution;

        if (id === draftState.currentCheckExecutionId) {
          draftState.checkWeights = {};
          if (samples) {
            samples.map((sample) => {
              if (sample.weightDisplay || sample.tareValue) {
                draftState.checkWeights[`weight${sample.sampleNumber - 1}`] =
                  sample as ProductionRunCheckExecutionWeighingSampleResult;
              }
              return draftState;
            });
          }
        }
      });
      break;

    case WeightsActionType.checkWeightSampleDeleteSuccess:
      const deleteAction = action as ReturnType<typeof deleteCheckWeightSampleSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { sampleNumber } = deleteAction.payload;
        if (0 < sampleNumber && sampleNumber <= previousState.currentCheckSampleSize) {
          delete draftState.checkWeights[`weight${sampleNumber - 1}`];
        }
      });
      break;

    case WeightsActionType.checkWeightStateReset:
      nextState = produce(previousState, (draftState) => {
        draftState.currentCheckSampleSize = 1;
        delete draftState.currentCheckExecutionId;
        draftState.checkWeights = {};
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
