import * as React from 'react';
import { Redirect, Switch } from 'react-router';

import { User, UserRole } from '../model';
import { ProductionOverviewComponent } from '../pages/production-overview/production-overview.page';
import { ProductionRunContentComponent } from '../pages/production-run/production-run-content.component';

import { PrivateRoute } from './private-route';
import { AppRoutePath } from './routes';

export default function productionRoutes(
  changePassword: boolean,
  loggedInUser: User | undefined
): React.ReactNode {
  return (
    <Switch>
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.productionRuns}/:id`}
        component={ProductionRunContentComponent}
        loggedInUser={loggedInUser}
        minRole={UserRole.Production}
        changePassword={changePassword}
      />
      <PrivateRoute
        exact={false}
        path={`/`}
        component={ProductionOverviewComponent}
        loggedInUser={loggedInUser}
        minRole={UserRole.Production}
        changePassword={changePassword}
      />
      <PrivateRoute
        render={() => <Redirect to={`/`} />}
        loggedInUser={loggedInUser}
        minRole={UserRole.Production}
        changePassword={changePassword}
      />
    </Switch>
  );
}
