import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { ProductionRunCheckExecution, ProductionRunCheckExecutionSampleResult } from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  sendBarcodeSample as sendBarcodeSampleAction,
  sendBarcodeSampleFailure,
  sendBarcodeSampleSuccess,
  CheckExecutionsActionType,
  fetchCheckExecution as fetchCheckExecutionAction,
  fetchCheckExecutionFailure,
  fetchCheckExecutionSuccess,
} from './check-executions.actions';

const productionRunsUrl = 'production/runs';
const checkExecutionsUrl = 'checks/executions';
const samplesUrl = 'samples';

const getCheckExecutionsUrl = (productionRunId: string, executionId: string) => {
  return `${productionRunsUrl}/${productionRunId}/${checkExecutionsUrl}/${executionId}`;
};

const getCheckExecutionSamplesUrl = (
  productionRunId: string,
  executionId: string,
  sampleNumber: number
) => {
  return `${getCheckExecutionsUrl(productionRunId, executionId)}/${samplesUrl}/${sampleNumber}`;
};

function* fetchCheckExecution(action: Action) {
  const { productionRunId, executionId } = (action as ReturnType<typeof fetchCheckExecutionAction>)
    .payload;
  try {
    const checkExecution = yield* call<[boolean, string], SagaRequest<ProductionRunCheckExecution>>(
      SagaRequestHelper.get,
      true,
      getCheckExecutionsUrl(productionRunId, executionId)
    );
    yield put(fetchCheckExecutionSuccess(checkExecution));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckExecutionFailure);
  }
}

function* sendBarcodeSample(action: Action) {
  const { barcode, productionRunId, executionId, sampleNumber } = (
    action as ReturnType<typeof sendBarcodeSampleAction>
  ).payload;
  try {
    const response = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRunCheckExecutionSampleResult>
    >(
      SagaRequestHelper.post,
      true,
      getCheckExecutionSamplesUrl(productionRunId, executionId, sampleNumber),
      { body: JSON.stringify({ barcode }) }
    );
    yield put(sendBarcodeSampleSuccess(sampleNumber, response));
  } catch (e: any) {
    yield sagaErrorHandler(e, sendBarcodeSampleFailure);
  }
}

export function* fetchCheckExecutionSaga() {
  yield takeLatest(CheckExecutionsActionType.checkExecutionFetch, fetchCheckExecution);
}

export function* sendBarcodeSampleSaga() {
  yield takeLatest(CheckExecutionsActionType.barcodeSampleSend, sendBarcodeSample);
}

export function* checkExecutionsSaga() {
  yield fork(fetchCheckExecutionSaga);
  yield fork(sendBarcodeSampleSaga);
}
