import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckAttributeType } from '../../../model';
import {
  initCheckWeights,
  confirmCheckForProductionRun,
  hasAllRequiredCheckWeightsSelector,
  checkWeightsSelector,
} from '../../../store';
import { ContainerOutsideWithHeader } from '../../structure';
import { CheckMeasurementComponent } from '../check-measurement.component';
import { CheckProps } from '../check.component';

export const VolumeCheckComponent = (props: CheckProps) => {
  const dispatch = useDispatch();
  const { productionRun, openCheckExecution, isDisruptionDialogOpen, checkAttribute } = props;

  const currentWeights = useSelector(checkWeightsSelector);
  const hasAllRequiredVolumes = useSelector(hasAllRequiredCheckWeightsSelector);
  const { sampleSize } = checkAttribute;
  useEffect(() => {
    dispatch(initCheckWeights(sampleSize));
  }, [dispatch, sampleSize, openCheckExecution, isDisruptionDialogOpen]);

  const confirmCheck = () => {
    if (hasAllRequiredVolumes) {
      const volumes = [...Array(sampleSize).keys()].map((sampleIndex) => {
        return currentWeights[`weight${sampleIndex}`]!;
      });

      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          CheckAttributeType.Volume,
          {
            volumes,
          }
        )
      );
    }
  };

  return (
    <ContainerOutsideWithHeader>
      <CheckMeasurementComponent
        checkProps={props}
        confirmCheck={confirmCheck}
        description={checkAttribute.volumeDescription}
        tareValue={checkAttribute.volumeTareValue}
      />
    </ContainerOutsideWithHeader>
  );
};
