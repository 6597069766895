export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  workspace?: string;
  role: UserRole;
  lastModified: string;
  passwordResetRequested: boolean;
}

export enum UserRole {
  Admin = 1,
  Supervisor,
  Production,
  Unauthorized,
}

export interface ProfileInfo extends User {}
