export interface ConnectedScale {
  id: string;
  name: string;
}

export interface Device {
  id: string;
  name: string;
  deviceType: DeviceType;
  // more properties are not needed yet
}

export interface VirtualDevice extends Device {}

export interface Scale extends Device {}

export enum DeviceType {
  scale = 1,
  virtual = 2,
}

export enum ScaleError {
  TareOrSetNullError = 15,
}
