import { WebsocketConfig } from './../websockets';
import {
  changedProductionRun,
  changedProductionTestRun,
  finishedProductionRun,
} from './production-runs.websocket-actions';

export const productionRunsWebsocketConfig: WebsocketConfig = {
  url: 'production/runs',
  actions: [
    {
      methodName: 'ProductionRunChanged',
      actionCreator: changedProductionRun,
    },
    {
      methodName: 'ProductionTestRunChanged',
      actionCreator: changedProductionTestRun,
    },
    {
      methodName: 'ProductionRunFinished',
      actionCreator: finishedProductionRun,
    },
  ],
};
