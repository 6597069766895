import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { irisCustomColors } from '../../../theme';

interface StylePropsWrapper {
  styleProps: {
    columnCount?: number;
    color?: string;
  };
}

export const TrafficLightCheckIcon = styled(CheckIcon, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ styleProps }) => ({
  color: styleProps.color,
}));

export const TrafficLightCloseIcon = styled(CloseIcon, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ styleProps }) => ({
  color: styleProps.color,
}));

export const TrafficLightBorder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ styleProps }) => ({
  borderColor: styleProps.color,
  borderWidth: 5,
  borderRadius: 0,
  borderStyle: 'solid',
}));

export const TrafficLightIconBackground = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(-4.25),
  marginTop: theme.spacing(-1.75),
  marginRight: theme.spacing(-0.75),
}));

export const TrafficLightAvatar = styled(Avatar)(() => ({
  backgroundColor: irisCustomColors.irisWhite,
  zIndex: 4,
}));

export const TrafficLightDescription = styled(Typography)(({ theme }) => ({
  overflowWrap: 'anywhere',
  margin: theme.spacing(2),
}));

export const TrafficLightCenterContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
