import ArrowBack from '@mui/icons-material/ArrowBack';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { Box, Button, Link, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ProductionRun } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { continueProductionRun } from '../../store';
import { irisCustomColors } from '../../theme';

export interface OwnProps {
  productionRun: ProductionRun;
}

const PREFIX = 'PauseInfoComponent';

const classes = {
  backLink: `${PREFIX}-backLink`,
  header: `${PREFIX}-header`,
  pause: `${PREFIX}-pause`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.backLink}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.pause}`]: {
    marginLeft: theme.spacing(2),
    marginBottom: 0,
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}));

export const PauseInfoComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const { productionRun } = props;
  const dispatch = useDispatch();

  const onContinue = () => {
    dispatch(continueProductionRun(productionRun.id));
  };

  return (
    <Root>
      <Box mb={3}>
        <Link
          className={classes.backLink}
          component={NavLink}
          to={AppRoutePath.root}
          underline="hover"
          data-testid="goToOverviewButton"
        >
          <ArrowBack />
          {t('data:productionRun.backLink')}
        </Link>
      </Box>
      <Box className={classes.header}>
        <PauseCircleFilledIcon fontSize="large" sx={{ color: irisCustomColors.irisGold }} />
        <Typography variant="h4" className={classes.pause}>
          <b>{t('data:check.pause')}</b>
        </Typography>
      </Box>
      <Typography>{t('data:check.productionRunPaused')}</Typography>
      <Box className={classes.buttonContainer}>
        <Button variant="contained" onClick={onContinue} data-testid="continueProdRunBtn">
          {t('data:check.continueProductionRun')}
        </Button>
      </Box>
    </Root>
  );
};
