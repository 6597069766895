import { createSelector } from 'reselect';

import { AppState } from '..';

export const usersStateSelector = (state: AppState) => state.users;
export const currentUserSelector = createSelector(usersStateSelector, (state) => state.currentUser);
export const requestResetPasswordFailed = createSelector(
  usersStateSelector,
  (state) => state.requestResetPasswordWasFailed
);
