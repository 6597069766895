import { createSelector } from 'reselect';

import { AppState } from '..';

export const productionOrdersStateSelector = (state: AppState) => state.productionOrders;
export const productionOrdersListSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.productionOrders
);

export const productionOrdersFetchedSelector = createSelector(
  productionOrdersStateSelector,
  (state) => !!state.productionOrdersFetched
);

export const productionOrdersTotalCountSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.itemsTotalCount || 0
);

export const productionOrdersCurrentPageIndexSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.currentPageIndex || 0
);

export const productionSearchTermSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.searchTerm || ''
);

export const productionOrdersSilentReloadSelector = createSelector(
  productionOrdersStateSelector,
  (state) => !!state.silentReload
);
