import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { canBePaused, isRunPausedManually } from '../../helper';
import { ProductionRun } from '../../model';
import { irisSpacing } from '../../theme';
import { dateAndTimeOptions } from '../../utils/date-utils';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';
import { ResponsiveImageComponent } from '../image/responsive-image/responsive-image.component';
import { ContainerInside, ContainerOutside } from '../structure';
import { DescriptionTableComponent } from '../table/description-table.component';

import { ProductionRunButtonsComponent } from './production-run-buttons.component';

const PREFIX = 'ProductionRunComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  container: `${PREFIX}-container`,
  tableItem: `${PREFIX}-tableItem`,
  buttons: `${PREFIX}-buttons`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    minWidth: '700px',
  },

  [`& .${classes.container}`]: {
    flexWrap: 'nowrap',
  },

  [`& .${classes.tableItem}`]: {
    width: 'inherit',
  },

  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(irisSpacing.button.space),
    marginTop: theme.spacing(irisSpacing.button.boxed.mt),
  },
}));

const getProductionRunDescription = (productionRun: ProductionRun, t: TFunction, language: any) => {
  const startDate = new Date(productionRun.started);

  return [
    {
      rowName: t('data:productionRun.startTime'),
      rowValue: `${startDate.toLocaleString(language, dateAndTimeOptions)} ${t('common:time')}`,
    },
    {
      rowName: t('data:productionOrder.amount'),
      rowValue: productionRun.amount,
    },
    {
      rowName: t('data:article.article'),
      rowValue: `${productionRun.article.articleNumber} | ${productionRun.article.name} ${
        productionRun.article.nameSuffix ? ' | ' + productionRun.article.nameSuffix : ''
      }`,
    },
    {
      rowName: t('data:productionOrder.batchNumber'),
      rowValue: productionRun.batchNumber,
    },
  ];
};

const ButtonColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
}));

const ButtonRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
  flexWrap: 'wrap',
  gap: theme.spacing(irisSpacing.button.space),
}));

export interface OwnProps {
  productionRun: ProductionRun;
  stopProductionRun?: () => void;
  appendChecks?: () => void;
}

export const ProductionRunComponent = (props: OwnProps) => {
  const { t, i18n } = useTranslation(['data']);
  const { productionRun, stopProductionRun, appendChecks } = props;

  const pauseAllowed = canBePaused(productionRun);
  const paused = isRunPausedManually(productionRun);

  const hasButtons = (!paused && pauseAllowed) || appendChecks || stopProductionRun;

  return (
    <ContainerOutside>
      <StyledPaper className={classes.paper}>
        <ContainerInside>
          <Typography variant="h3">{productionRun.orderNumber}</Typography>
          <GridContainer level={GridLevel.InfoPaper}>
            <GridItem s={4}>
              <ResponsiveImageComponent
                imageUrl={productionRun.article.imageUrl}
                altText={t('data:article.image')}
              />
            </GridItem>
            <GridItem s={8} l={4}>
              <Box>
                <Box>
                  <DescriptionTableComponent
                    tableData={getProductionRunDescription(productionRun, t, i18n.language)}
                  />
                </Box>
              </Box>
            </GridItem>
            <GridItem l={4}>
              {!productionRun.disrupted && hasButtons && (
                <ButtonColumn>
                  <ButtonRow>
                    <ProductionRunButtonsComponent
                      productionRun={productionRun}
                      stopProductionRun={stopProductionRun}
                      appendChecks={appendChecks}
                    />
                  </ButtonRow>
                </ButtonColumn>
              )}
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </StyledPaper>
    </ContainerOutside>
  );
};
