import { Typography } from '@mui/material';
import React from 'react';

export interface OwnProps {
  children: React.ReactNode;
  headlineText: string;
}

export const ContentLayoutComponent = (props: OwnProps) => {
  const { headlineText, children } = props;

  return (
    <>
      <Typography variant="h1">{headlineText}</Typography>
      {children}
    </>
  );
};
