import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';

import { UserRole } from '../../model';
import { PrivateRoute } from '../../routes/private-route';
import { AppRoutePath } from '../../routes/routes';
import { changePasswordAfterResetSelector, loggedInUserSelector } from '../../store';
import { PreLayoutComponent } from '../layout/pre-layout.component';

export const LoginCheckComponent = () => {
  const loggedInUser = useSelector(loggedInUserSelector);
  const changePassword = useSelector(changePasswordAfterResetSelector);

  return (
    <Switch>
      <PrivateRoute
        path={`/`}
        component={PreLayoutComponent}
        loggedInUser={loggedInUser}
        minRole={UserRole.Production}
        changePassword={changePassword}
      />
      <PrivateRoute
        render={() => <Redirect to={`/${AppRoutePath.login}`} />}
        loggedInUser={loggedInUser}
        minRole={UserRole.Production}
        changePassword={changePassword}
      />
    </Switch>
  );
};
