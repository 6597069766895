import { Box, Button, Grid, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoboxComponent } from '../../components/infobox/infobox.component';
import { irisSpacing } from '../../theme';

const PREFIX = 'CheckSkipComponent';

const classes = {
  root: `${PREFIX}-root`,
  buttons: `${PREFIX}-buttons`,
};

const Root = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.buttons}`]: {
    display: 'flex',
    '& button:not(:last-child)': {
      marginRight: theme.spacing(irisSpacing.button.space),
    },
  },
}));

export interface OwnProps {
  submit: (reason: string) => void;
  cancel: () => void;
}

type FormData = {
  skipReason: string;
};

const getFormValues = (): FormData => ({
  skipReason: '',
});

export const CheckSkipComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);

  const { handleSubmit, errors, control } = useForm<FormData>({
    defaultValues: getFormValues(),
  });

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit(formData.skipReason);
  });

  return (
    <Root onSubmit={onSubmit} className={classes.root}>
      <Grid container direction="column" spacing={irisSpacing.input.space}>
        <Grid item xs={12}>
          <InfoboxComponent headline={t('data:check.skipReasonRequest')} type={'error'} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={<TextField required />}
            control={control}
            name="skipReason"
            label={t('data:check.describeSkipReason')}
            placeholder={t('form:multilineTextPlaceholder')}
            multiline
            rows={6}
            fullWidth
            variant="outlined"
            inputProps={{
              'data-testid': 'skipReason-input',
            }}
            rules={{
              required: { value: true, message: t('form:fieldIsRequired') },
              validate: (value: string) =>
                value.trim() ? true : (t('form:fieldIsRequired') as string),
              minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
              maxLength: { value: 300, message: t('form:maxLength', { max: '300' }) },
            }}
            error={errors.skipReason !== undefined}
            helperText={errors.skipReason && errors.skipReason.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.buttons}>
            <Button
              data-testid="cancel-btn"
              color="secondary"
              variant="contained"
              onClick={() => props.cancel()}
            >
              {t('form:cancel')}
            </Button>
            <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
              {t('form:submitAndContinue')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
};
