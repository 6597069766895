import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NumericFormat } from 'react-number-format';

import { VOLUME_ENTITY, WEIGHT_ENTITY } from '../../../constants';
import i18n from '../../../i18next';
import {
  CheckAttribute,
  ProductionRun,
  ProductionRunCheckExecutionWeighingSampleResult,
} from '../../../model';
import { CheckResultIconComponent } from '../../check-result-icon/check-result-icon.component';
import { CheckInfoElement } from '../../structure';

export const checkInfo = (checkAttribute: CheckAttribute, description?: string) => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">{checkAttribute.name}</Typography>
      </Box>
      {description && (
        <CheckInfoElement>
          <Box>
            <Typography>{description}</Typography>
          </Box>
        </CheckInfoElement>
      )}
    </>
  );
};

export const scaleInfo = (productionRun: ProductionRun) => {
  return productionRun.scaleDevice ? (
    <CheckInfoElement>
      <Typography>
        {i18n.t('data:check.pleaseUseDevice')}
        <span style={{ fontWeight: 'bold' }}>
          {` ${i18n.t('data:check.scaleName', {
            scaleName: productionRun.scaleDevice!.name,
          })}`}
        </span>
        {'.'}
      </Typography>
    </CheckInfoElement>
  ) : undefined;
};

export const formattedResult = (value?: number, suffix?: string) => {
  return value || value === 0 ? (
    <>
      <NumericFormat
        value={value}
        decimalScale={3}
        decimalSeparator={(i18n.t('form:decimalSeparator') as string) || ','}
        displayType="text"
      />
      {suffix && <span style={{ marginLeft: '5px', marginRight: '5px' }}>{`${suffix}`}</span>}
    </>
  ) : undefined;
};

export const weightResult = (sample?: ProductionRunCheckExecutionWeighingSampleResult) => {
  return (
    <>
      {sample?.volumeDisplay && (
        <>
          {formattedResult(sample?.volumeDisplay, `${VOLUME_ENTITY}`)}

          <span style={{ marginRight: '5px' }}>{`/`}</span>
        </>
      )}
      <>{formattedResult(sample?.weightDisplay, `${WEIGHT_ENTITY}`)}</>
    </>
  );
};

const linkClasses = {
  disabledLink: `weighingMeasurement-disabledLink`,
};

const LinkRoot = styled('div')(({ theme }) => ({
  [`& .${linkClasses.disabledLink}`]: {
    color: theme.palette.text.disabled,
    textDecorationColor: theme.palette.text.disabled,
    pointerEvents: 'none',
  },
}));

export const weightMeasurementLink = (
  sampleIndex: number,
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void,
  disabled?: boolean
) => {
  return (
    <LinkRoot>
      <Link
        component="button"
        variant="body2"
        underline="always"
        color="secondary"
        onClick={() => executeMeasurement(sampleIndex, false)}
        data-testid={`executeMeasurementBtn${sampleIndex}`}
        disabled={!!disabled}
        className={!!disabled ? linkClasses.disabledLink : undefined}
      >
        {i18n.t('data:check.executeMeasurement')}
      </Link>
    </LinkRoot>
  );
};

export const weightMeasurementContent = (
  weightDisplay: number | undefined,
  netResult: JSX.Element,
  measurementLink: JSX.Element
) => {
  return <>{weightDisplay || weightDisplay === 0 ? netResult : measurementLink}</>;
};

export const checkResultRatingWeighing = (
  sampleIndex: number,
  sample: ProductionRunCheckExecutionWeighingSampleResult | undefined
) => {
  return (
    <>
      {sample && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!!sample?.checkResult && (
            <CheckResultIconComponent key={sampleIndex} checkResult={sample!.checkResult!} />
          )}
          {sample?.fertigPackVCategory &&
            i18n.t([
              `data:check.fertigPackVCategories.${sample?.fertigPackVCategory}`,
              `data:check.fertigPackVCategories.notFound`,
            ])}
        </div>
      )}
    </>
  );
};
