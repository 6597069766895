import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Typography, styled, Box } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { closeHelpDialog } from '../../../store';
import { irisCustomColors } from '../../../theme';

const PREFIX = 'HelpInfoComponent';

const classes = {
  header: `${PREFIX}-header`,
  help: `${PREFIX}-help`,
  stepSpacing: `${PREFIX}-stepSpacing`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.help}`]: {
    marginLeft: theme.spacing(2),
    marginBottom: 0,
  },
  [`& .${classes.stepSpacing}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}));

export const HelpInfoComponent = () => {
  const { t } = useTranslation(['data', 'common']);
  const dispatch = useDispatch();

  const handleCloseHelpDialog = () => {
    dispatch(closeHelpDialog());
  };

  return (
    <Root data-testid="helpInfo">
      <Box className={classes.header}>
        <HelpOutlineIcon fontSize="large" sx={{ color: irisCustomColors.irisGreen }} />
        <Typography variant="h3" className={classes.help}>
          <b>{t('data:check.helpPopover.help')}</b>
        </Typography>
      </Box>
      <Typography className={classes.stepSpacing}>
        {t('data:check.helpPopover.actionOrder')}
      </Typography>
      <Typography>
        <b>{t('data:check.helpPopover.numbering')}</b>
      </Typography>
      <Typography className={classes.stepSpacing}>
        {t('data:check.helpPopover.numberingDescription')}
      </Typography>
      <Typography>
        <b>{t('data:check.helpPopover.getTare')}</b>
      </Typography>
      <Typography className={classes.stepSpacing}>
        {t('data:check.helpPopover.getTareDescription')}
      </Typography>
      <Typography>
        <b>{t('data:check.helpPopover.fillBundle')}</b>
      </Typography>
      <Typography className={classes.stepSpacing}>
        {t('data:check.helpPopover.fillBundleDescription')}
      </Typography>
      <Typography>
        <b>{t('data:check.helpPopover.executeCheck')}</b>
      </Typography>
      <Typography>{t('data:check.helpPopover.executeCheckDescription')}</Typography>

      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleCloseHelpDialog}
          data-testid="closeHelpDialogBtn"
        >
          {t('common:close')}
        </Button>
      </Box>
    </Root>
  );
};
