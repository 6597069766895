import { styled } from '@mui/material/styles';

const PREFIX = 'StyledCheckMeasurement';
export const checkMeasurementClasses = {
  check: `${PREFIX}-check`,
  headline: `${PREFIX}-headline`,
  paper: `${PREFIX}-paper`,
  sampleContainer: `${PREFIX}-sampleContainer`,
  suffix: `${PREFIX}-suffix`,
  bold: `${PREFIX}-bold`,
  separator: `${PREFIX}-separator`,
};

export const CheckMeasurementRoot = styled('div')(({ theme }) => ({
  [`& .${checkMeasurementClasses.check}`]: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'column',
  },

  [`& .${checkMeasurementClasses.headline}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${checkMeasurementClasses.paper}`]: {
    minWidth: '700px',
  },
  [`& .${checkMeasurementClasses.sampleContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${checkMeasurementClasses.suffix}`]: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  [`& .${checkMeasurementClasses.bold}`]: {
    fontWeight: 'bold',
  },
  [`& .${checkMeasurementClasses.separator}`]: {
    marginRight: '5px',
  },
}));
