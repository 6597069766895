import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getCheckComponent } from '../../components/checks/check.component';
import { ChecksProgressComponent } from '../../components/checks/checks-progress.component';
import { HelpDialogComponent } from '../../components/checks/fertigpackv-check/help-dialog.component';
import { ViolationDialogComponent } from '../../components/fertigpackv/violation-dialog.component';
import { IssuesComponent } from '../../components/issue/issues.component';
import { ContentLayoutComponent } from '../../components/layout/content-layout.component';
import { PauseDialogComponent } from '../../components/pause-popover/pause-dialog.component';
import { ProductionRunComponent } from '../../components/production/production-run.component';
import { ContainerOutside } from '../../components/structure';
import { hasActiveTareDetermination, hasActiveTestRun } from '../../helper';
import {
  IssueCode,
  OpenCheckExecution,
  ProductionRun,
  ProductionRunStatus,
  TareMode,
} from '../../model';
import { openSkipCheckDialog, closeSkipCheckDialog } from '../../store';

import { CheckSkipDialogComponent } from './check-skip-dialog.component';

const PREFIX = 'CheckPage';

const classes = {
  forwardLink: `${PREFIX}-forwardLink`,
  linkContainer: `${PREFIX}-linkContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.forwardLink}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.linkContainer}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  openCheckExecution: OpenCheckExecution;
  isDisruptionDialogOpen: boolean;
}

export const CheckPage = (props: OwnProps) => {
  const { productionRun, openCheckExecution } = props;
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();

  const testRunActive = hasActiveTestRun(productionRun);
  const tareDeterminationActive = hasActiveTareDetermination(productionRun);

  const checkAttribute = productionRun.checks.find(
    (c) => c.id === openCheckExecution.productionRunCheckId
  )!.checkAttribute;

  const checkProps = {
    ...props,
    checkAttribute,
  };

  const handleOpenSkipCheckDialog = () => {
    dispatch(openSkipCheckDialog());
  };

  const handleCloseSkipCheckDialog = () => {
    dispatch(closeSkipCheckDialog());
  };

  return (
    <Root>
      <ContentLayoutComponent headlineText={t('data:check.executeChecks')}>
        <PauseDialogComponent productionRun={productionRun} />
        {productionRun.article.tareMode === TareMode.IndividualTare && <HelpDialogComponent />}
        {testRunActive || tareDeterminationActive || (
          <>
            <ViolationDialogComponent productionRun={productionRun} />
            <CheckSkipDialogComponent
              handleCancel={handleCloseSkipCheckDialog}
              productionRunId={productionRun.id}
              openCheckExecution={openCheckExecution}
            />
            <IssuesComponent
              issues={productionRun.issues}
              allowedIssueCodes={[IssueCode.FertigPackVTU1Violation]}
              iconSize={'medium'}
              elementStyle={'infobox'}
            />
          </>
        )}
        <ProductionRunComponent productionRun={productionRun} />
        <Typography variant="h2" data-testid={'currentCheckHeader'}>
          {testRunActive || tareDeterminationActive
            ? ''
            : productionRun.status === ProductionRunStatus.Finishing
            ? t('data:check.finishingChecks')
            : t('data:check.currentCheck')}
        </Typography>
        {getCheckComponent(checkProps)}
        {testRunActive || tareDeterminationActive || (
          <>
            <ContainerOutside>
              <ChecksProgressComponent
                executed={productionRun.checksDone}
                total={productionRun.checksDone + productionRun.openCheckExecutions.length}
              />
            </ContainerOutside>
            <Box className={classes.linkContainer} mt={3}>
              <Link
                className={classes.forwardLink}
                component="button"
                variant="body2"
                underline="none"
                onClick={() => handleOpenSkipCheckDialog()}
              >
                {t('data:check.skipCheck')}
                <ArrowForward />
              </Link>
            </Box>
          </>
        )}
      </ContentLayoutComponent>
    </Root>
  );
};
