import { CheckAttribute, CheckAttributeType, ProductionRunArticle, TareMode } from '../../model';

export enum WeighingCategory {
  Unknown = 0,
  FertigPackV = 1,
  Weight = 2,
}

export const getWeighingCategory = (checkAttributeType: CheckAttributeType) => {
  switch (checkAttributeType) {
    case CheckAttributeType.FertigPackV:
    case CheckAttributeType.TestRun:
    case CheckAttributeType.Tare:
      return WeighingCategory.FertigPackV;
    case CheckAttributeType.Weight:
      return WeighingCategory.Weight;
    default:
      return WeighingCategory.Unknown;
  }
};

export const getTare = (checkAttribute: CheckAttribute, article: ProductionRunArticle) => {
  switch (getWeighingCategory(checkAttribute.checkAttributeType)) {
    case WeighingCategory.FertigPackV:
      return article.tareMode !== TareMode.IndividualTare && article.tareValue
        ? article.tareValue
        : 0;
    case WeighingCategory.Weight:
      return checkAttribute.weightTareValue;
    default:
      return undefined;
  }
};
