import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPixels, irisSpacing } from '../../theme';

const VerticalFlexBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const StretchContainer = styled('div')(({ theme }) => ({
  width: `calc(100% + ${getPixels(irisSpacing.container.p * 2)}px)`,
}));

export interface MeasurementColumn {
  label: string;
  content: (sampleIndex: number) => JSX.Element;
}

export interface OwnProps {
  sampleSize: number;
  tareColumn?: MeasurementColumn;
  resultColumn?: MeasurementColumn;
  ratingColumn?: MeasurementColumn;
  actionColumn?: MeasurementColumn;
}

export const CheckMeasurementTableComponent = (props: OwnProps) => {
  const { sampleSize, tareColumn, resultColumn, ratingColumn, actionColumn } = props;
  const { t } = useTranslation(['data']);

  const hasTareColumn = !!tareColumn;
  const hasResultColumn = !!resultColumn;
  const hasRatingColumn = !!ratingColumn;
  const hasActionColumn = !!actionColumn;

  const getColWidth = () => {
    let colCount = 1;
    colCount += hasTareColumn ? 1 : 0;
    colCount += hasResultColumn ? 1 : 0;
    colCount += hasRatingColumn ? 1 : 0;
    colCount += hasActionColumn ? 1 : 0;
    const widthPercent = Math.round(100 / colCount);
    return `${widthPercent}%`;
  };
  const colWidth = getColWidth();

  return (
    <VerticalFlexBox>
      <StretchContainer>
        <TableContainer component={Paper}>
          <Table data-testid="checkMeasurementTable">
            <TableHead>
              <TableRow key={0}>
                <TableCell width={colWidth} align="center">
                  {t('data:check.sample')}
                </TableCell>
                {hasTareColumn && <TableCell width={colWidth}>{tareColumn.label}</TableCell>}
                {hasResultColumn && <TableCell width={colWidth}>{resultColumn.label}</TableCell>}
                {hasRatingColumn && <TableCell width={colWidth}>{ratingColumn.label}</TableCell>}
                {hasActionColumn && <TableCell width={colWidth}>{actionColumn.label}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(sampleSize || 1).keys()].map((sampleIndex) => {
                return (
                  <TableRow key={sampleIndex + 1} data-testid="checkTableRow">
                    <TableCell width={colWidth} align="center">
                      {sampleIndex + 1}
                    </TableCell>
                    {hasTareColumn && (
                      <TableCell width={colWidth}>{tareColumn.content(sampleIndex)}</TableCell>
                    )}
                    {hasResultColumn && (
                      <TableCell width={colWidth}>{resultColumn.content(sampleIndex)}</TableCell>
                    )}
                    {hasRatingColumn && (
                      <TableCell width={colWidth}>{ratingColumn.content(sampleIndex)}</TableCell>
                    )}
                    {hasActionColumn && (
                      <TableCell width={colWidth}>{actionColumn.content(sampleIndex)}</TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StretchContainer>
    </VerticalFlexBox>
  );
};
