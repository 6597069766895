import React from 'react';

import { LayoutComponent } from '../layout/layout.component';
import { SnackbarComponent } from '../snackbar/snackbar.component';

export const PreLayoutComponent = () => {
  return (
    <>
      <SnackbarComponent />
      <LayoutComponent />
    </>
  );
};
