import { ProductionRunCheckExecution, ProductionRunCheckExecutionSampleResult } from '../../model';

export enum CheckExecutionsActionType {
  checkExecutionSamplesInit = 'checkExecution/init',
  checkExecutionSamplesReset = 'checkExecution/reset',
  checkExecutionFetch = 'checkExecution/fetch',
  checkExecutionFetchSuccess = 'checkExecution/fetch/success',
  checkExecutionFetchFailure = 'checkExecution/fetch/failure',

  barcodeSampleSend = 'barcodeSample/send',
  barcodeSampleSendSuccess = 'barcodeSample/send/success',
  barcodeSampleSendFailure = 'barcodeSample/send/failure',
}

export const initCheckExecutionSamples = (sampleSize: number) => ({
  type: CheckExecutionsActionType.checkExecutionSamplesInit,
  payload: { sampleSize },
});

export const resetCheckExecutionSamples = () => ({
  type: CheckExecutionsActionType.checkExecutionSamplesReset,
});

export const fetchCheckExecution = (productionRunId: string, executionId: string) => ({
  type: CheckExecutionsActionType.checkExecutionFetch,
  payload: { productionRunId, executionId },
});

export const fetchCheckExecutionSuccess = (checkExecution: ProductionRunCheckExecution) => ({
  type: CheckExecutionsActionType.checkExecutionFetchSuccess,
  payload: { checkExecution },
});

export const fetchCheckExecutionFailure = () => ({
  type: CheckExecutionsActionType.checkExecutionFetchFailure,
});

export const sendBarcodeSample = (
  barcode: string,
  productionRunId: string,
  executionId: string,
  sampleNumber: number
) => ({
  type: CheckExecutionsActionType.barcodeSampleSend,
  payload: { barcode, productionRunId, executionId, sampleNumber },
});

export const sendBarcodeSampleSuccess = (
  sampleNumber: number,
  sampleResult: ProductionRunCheckExecutionSampleResult
) => ({
  type: CheckExecutionsActionType.barcodeSampleSendSuccess,
  payload: { sampleNumber, sampleResult },
});

export const sendBarcodeSampleFailure = () => ({
  type: CheckExecutionsActionType.barcodeSampleSendFailure,
});
