import { User } from '../model';

export interface LoggedInUserData {
  accessToken: string;
  refreshToken: string;
  resetPassword: boolean;
  user: User;
}

const userDataStorageKey = 'userData';

export const getUser = async (): Promise<User | undefined> => {
  const userDataString = await sessionStorage.getItem(userDataStorageKey);
  if (!userDataString) {
    return undefined;
  }
  const userData = JSON.parse(userDataString) as LoggedInUserData;
  return userData.user;
};

export const getRefreshToken = async (): Promise<string | undefined> => {
  const userDataString = await sessionStorage.getItem(userDataStorageKey);
  if (!userDataString) {
    return undefined;
  }
  const userData = JSON.parse(userDataString) as LoggedInUserData;
  return userData.refreshToken;
};

export const getAccessToken = async (): Promise<string | undefined> => {
  const userDataString = await sessionStorage.getItem(userDataStorageKey);
  if (!userDataString) {
    return undefined;
  }
  const userData = JSON.parse(userDataString) as LoggedInUserData;
  return userData.accessToken;
};

export const getResetPassword = async (): Promise<boolean> => {
  const userDataString = await sessionStorage.getItem(userDataStorageKey);
  if (!userDataString) {
    return false;
  }
  const userData = JSON.parse(userDataString) as LoggedInUserData;
  return userData.resetPassword;
};

export const setResetPassword = async (resetPassword: boolean): Promise<undefined> => {
  const userDataString = await sessionStorage.getItem(userDataStorageKey);
  if (!userDataString) {
    return;
  }
  const userData = JSON.parse(userDataString) as LoggedInUserData;
  userData.resetPassword = resetPassword;
  await login(userData);
};

export const login = async (userData: LoggedInUserData) => {
  await sessionStorage.setItem(userDataStorageKey, JSON.stringify(userData));
};

export const logout = async () => {
  await sessionStorage.removeItem(userDataStorageKey);
};
