import produce from 'immer';
import { Action } from 'redux';

import { InternalErrorCode } from '../../http/http-error-codes';
import { AuthenticationActionType } from '../authentication';
import { CheckExecutionsActionType } from '../check-executions';
import { DevicesActionType, setScaleTareValueFailure } from '../devices';
import { ProductionOrdersActionType } from '../production-orders';
import {
  confirmCheckForProductionRunSuccess,
  ProductionRunsActionType,
  stopProductionRunSuccess,
} from '../production-runs';
import { executeCheckWeightFailure, fetchCheckWeightFailure, WeightsActionType } from '../weights';

import { Notification, NotificationType, ProductionRunStatus, ScaleError } from './../../model';
import { LinesActionType } from './../lines';
import { NotificationsActionType } from './notifications.actions';

export interface NotificationsState {
  currentNotification?: Notification;
}

export const getInitialState = (): NotificationsState => ({});

const addErrorNotification = (
  draftState: NotificationsState,
  type: any,
  errorCode?: InternalErrorCode
) => {
  draftState.currentNotification = errorCode
    ? {
        messageKey: type,
        type: NotificationType.error,
        errorCode: errorCode,
      }
    : {
        messageKey: type,
        type: NotificationType.error,
      };
};

export const notificationsReducer = (
  previousState: NotificationsState = getInitialState(),
  action: Action
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ProductionOrdersActionType.productionOrderStartFailure:
    case ProductionOrdersActionType.productionOrdersFetchAllFailure:
    case ProductionRunsActionType.productionRunsFetchAllFailure:
    case ProductionRunsActionType.productionRunFetchFailure:
    case ProductionRunsActionType.productionRunStopFailure:
    case ProductionRunsActionType.productionRunCheckConfirmFailure:
    case ProductionRunsActionType.productionRunCheckSkipFailure:
    case ProductionRunsActionType.productionRunAppendChecksFailure:
    case ProductionRunsActionType.productionRunDisruptionStartFailure:
    case ProductionRunsActionType.productionRunDisruptionResolveFailure:
    case ProductionRunsActionType.productionRunCalcFertigPackTolerancesFailure:
    case LinesActionType.linesFetchAllFailure:
    case WeightsActionType.checkWeightSamplesFetchFailure:
    case WeightsActionType.checkWeightSampleDeleteFailure:
    case AuthenticationActionType.profileInfoFetchFailure:
    case ProductionRunsActionType.productionRunPauseFailure:
    case ProductionRunsActionType.productionRunContinueFailure:
    case CheckExecutionsActionType.barcodeSampleSendFailure:
      nextState = produce(previousState, (draftState) => {
        addErrorNotification(draftState, type);
      });
      break;
    case ProductionRunsActionType.productionRunCheckConfirmSuccess:
    case ProductionRunsActionType.productionRunStopSuccess:
    case ProductionRunsActionType.productionRunCheckSkipSuccess:
    case ProductionRunsActionType.productionRunDisruptionResolveSuccess:
      nextState = produce(previousState, (draftState) => {
        const productionRunAction = action as ReturnType<
          typeof stopProductionRunSuccess | typeof confirmCheckForProductionRunSuccess
        >;
        const { productionRun } = productionRunAction.payload;
        if (productionRun.status === ProductionRunStatus.Done) {
          draftState.currentNotification = {
            messageKey: ProductionRunsActionType.productionRunStopSuccess,
            type: NotificationType.success,
          };
        }
      });
      break;
    case NotificationsActionType.notificationClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentNotification;
      });
      break;

    case DevicesActionType.scaleSetTareFailure:
      const deviceAction = action as ReturnType<typeof setScaleTareValueFailure>;
      nextState = produce(previousState, (draftState) => {
        if (!deviceAction.payload?.cleanup) {
          draftState.currentNotification = {
            messageKey: type,
            type: NotificationType.error,
          };
        }
      });
      break;

    case WeightsActionType.checkWeightFetchFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof fetchCheckWeightFailure>;
        const { e } = failureAction.payload;
        if (
          e.internalErrorCode &&
          e.internalErrorCode === InternalErrorCode.StaticScaleCommunicationFailed &&
          e.data?.data?.scaleErrorCode &&
          e.data.data.scaleErrorCode === ScaleError.TareOrSetNullError
        ) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;

    case WeightsActionType.checkWeightExecuteFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof executeCheckWeightFailure>;
        const { e } = failureAction.payload;
        if (
          e.internalErrorCode ===
            InternalErrorCode.ProductionRunCheckExecutionTareValueNotInRange ||
          e.internalErrorCode ===
            InternalErrorCode.ProductionRunCheckExecutionSampleAlreadyDoneException ||
          (e.internalErrorCode &&
            e.internalErrorCode === InternalErrorCode.StaticScaleCommunicationFailed &&
            e.data?.data?.scaleErrorCode &&
            e.data.data.scaleErrorCode === ScaleError.TareOrSetNullError) ||
          e.internalErrorCode === InternalErrorCode.ProductionRunCheckExecutionInvalidWeighingbridge
        ) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
