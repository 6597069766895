import { Button, Link, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { canBePaused, isRunPausedManually } from '../../helper';
import { ProductionRun } from '../../model';
import { pauseProductionRun } from '../../store';
import { irisSpacing, Screen } from '../../theme';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';

const ResponsiveContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(irisSpacing.button.space),
  flexDirection: 'row-reverse',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',

  [theme.breakpoints.up(Screen.medium)]: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  [theme.breakpoints.up(Screen.large)]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  stopProductionRun?: () => void;
  appendChecks?: () => void;
}

export const ProductionRunButtonsComponent = (props: OwnProps) => {
  const { productionRun, stopProductionRun, appendChecks } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const smallScreen = !useMediaQuery((theme: Theme) => theme.breakpoints.up(Screen.medium));

  const pauseAllowed = canBePaused(productionRun);
  const paused = isRunPausedManually(productionRun);

  const pauseRun = () => {
    dispatch(pauseProductionRun(productionRun.id));
  };

  return (
    <GridContainer level={GridLevel.InfoPaper}>
      {smallScreen && <GridItem s={3} m={0} />}
      <GridItem s={9} m={12}>
        <ResponsiveContainer>
          {!productionRun.openCheckExecutions.length && (
            <>
              {stopProductionRun && (
                <Button
                  color="primary"
                  variant="contained"
                  data-testid="stopProductionRunBtn"
                  onClick={() => stopProductionRun()}
                >
                  {t('data:productionRun.stopProductionRun')}
                </Button>
              )}
              {appendChecks && productionRun.checksAppendable && (
                <Button
                  color="secondary"
                  variant="contained"
                  data-testid="appendChecksBtn"
                  onClick={() => appendChecks()}
                >
                  {t('data:productionRun.appendChecks')}
                </Button>
              )}
            </>
          )}
          {!paused && pauseAllowed && (
            <Link
              component="button"
              variant="body2"
              underline="always"
              color="secondary"
              fontWeight="medium"
              onClick={() => pauseRun()}
              data-testid={'pauseBtn'}
              whiteSpace={'nowrap'}
            >
              {t('data:productionRun.pauseProductionRun')}
            </Link>
          )}
        </ResponsiveContainer>
      </GridItem>
    </GridContainer>
  );
};
