import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ReactComponent as logoutIcon } from '../../assets/img/logout-icon.svg';
import { AppRoutePath } from '../../routes/routes';
import { loggedInUserSelector, logout } from '../../store';
import { irisCustomColors } from '../../theme';

const PREFIX = 'UserProfileComponent';

const classes = {
  container: `${PREFIX}-container`,
  linkButton: `${PREFIX}-linkButton`,
  linkIcon: `${PREFIX}-linkIcon`,
  logoutContainer: `${PREFIX}-logoutContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.linkButton}`]: {
    display: 'flex',
    alignItems: 'center',
    color: irisCustomColors.irisBlack,
    textDecoration: 'none',
    '&.active .MuiTypography-body2, &.active svg': {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.linkIcon}`]: {
    marginLeft: '4px',
    marginRight: theme.spacing(1.5),
  },

  [`& .${classes.logoutContainer}`]: {
    marginLeft: theme.spacing(1.5),
    marginRight: '4px',
  },
}));

export type InfoboxType = 'error' | 'warning';

export const UserProfileComponent = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(loggedInUserSelector);

  const logoutUser = () => {
    dispatch(logout());
  };

  return (
    <Root>
      {currentUser && (
        <Box className={classes.container}>
          <NavLink
            activeClassName="active"
            className={classes.linkButton}
            to={`/${AppRoutePath.me}`}
            data-testid="profile-btn"
          >
            <Typography
              color={irisCustomColors.irisWhite}
              variant="body2"
            >{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
          </NavLink>
          <Box className={classes.logoutContainer}>
            <Link
              component="button"
              type="button"
              onClick={() => logoutUser()}
              data-testid="logout-btn"
              underline="hover"
            >
              <SvgIcon
                fontSize="large"
                component={logoutIcon}
                style={{ color: irisCustomColors.irisWhite }}
              />
            </Link>
          </Box>
        </Box>
      )}
    </Root>
  );
};
