import * as React from 'react';

import { dataTableClasses, DataTableRoot } from '../data-table/data-table.styles';

export interface TableData {
  rowName: string;
  rowValue?: string | number;
  rowValueClassName?: string;
}

export interface OwnProps {
  tableData: TableData[];
}

export const DescriptionTableComponent = (props: OwnProps) => {
  const { tableData } = props;
  return (
    <DataTableRoot>
      <table className={dataTableClasses.dataTable}>
        <tbody>
          {tableData.map((data) => (
            <tr key={data.rowName}>
              <td>{data.rowName}:</td>
              <td className={data.rowValueClassName}>{data.rowValue ?? '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataTableRoot>
  );
};
