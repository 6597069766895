import { WebsocketConfig } from '../websockets';

import { changedCheckExecution } from './check-executions.websocket-actions';

export const checkExecutionsWebsocketConfig: WebsocketConfig = {
  url: 'production/executions',
  actions: [
    {
      methodName: 'ProductionRunCheckExecutionChanged',
      actionCreator: changedCheckExecution,
    },
  ],
};
