import { Box, Button, Link, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { AppRoutePath } from '../../../routes/routes';
import {
  clearLoginFailure,
  login,
  loginFailedSelector,
  loginRoleInsufficientSelector,
  loginUndefinedErrorSelector,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { InfoboxComponent } from '../../infobox/infobox.component';

interface FormData {
  username: string;
  password: string;
}

export const LoginFormComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'form', 'common']);
  const loginFailed = useSelector(loginFailedSelector);
  const loginUndefinedError = useSelector(loginUndefinedErrorSelector);
  const loginRoleInsufficient = useSelector(loginRoleInsufficientSelector);

  const { handleSubmit, errors, control } = useForm<FormData>();

  const onSubmit = handleSubmit((formData: FormData) => {
    dispatch(login(formData.username, formData.password));
  });

  useEffect(() => {
    return () => {
      dispatch(clearLoginFailure());
    };
  }, [dispatch]);

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column">
        <Controller
          defaultValue=""
          as={<TextField fullWidth={true} variant="outlined" autoComplete="username" />}
          control={control}
          name="username"
          label={t('login:username')}
          inputProps={{
            'data-testid': 'username-input',
          }}
          rules={{
            required: { value: true, message: t('form:fieldIsRequired') },
            validate: (value: string) =>
              value.trim() ? true : (t('form:fieldIsRequired') as string),
          }}
          error={errors.username !== undefined}
          helperText={errors.username && errors.username.message}
        />
        <Box mt={irisSpacing.input.space}>
          <Controller
            defaultValue=""
            as={
              <TextField
                fullWidth={true}
                variant="outlined"
                type="password"
                autoComplete="current-password"
              />
            }
            control={control}
            name="password"
            label={t('login:password')}
            inputProps={{
              'data-testid': 'password-input',
            }}
            rules={{
              required: { value: true, message: t('form:fieldIsRequired') },
              validate: (value: string) =>
                value.trim() ? true : (t('form:fieldIsRequired') as string),
            }}
            error={errors.password !== undefined}
            helperText={errors.password && errors.password.message}
          />
        </Box>
        {loginFailed && (
          <Box mt={irisSpacing.input.space}>
            <InfoboxComponent type="error" headline={t('login:loginFailed')} />
          </Box>
        )}
        {loginUndefinedError && (
          <Box mt={irisSpacing.input.space}>
            <InfoboxComponent type="error" headline={t('login:undefinedError')} />
          </Box>
        )}
        {loginRoleInsufficient && (
          <Box mt={irisSpacing.input.space}>
            <InfoboxComponent type="error" headline={t('login:loginRoleInsufficient')} />
          </Box>
        )}

        <Box mt={irisSpacing.button.boxed.mt} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Typography>{t('login:forgotPassword')}</Typography>
            <Link
              to={`/${AppRoutePath.requestResetPassword}`}
              component={NavLink}
              underline="hover"
            >
              {t('login:contactAdmin')}
            </Link>
          </Box>
          <Button variant="contained" color="primary" data-testid="submit-btn" type="submit">
            {t('login:login')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
