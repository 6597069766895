import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface OwnProps {
  handleClick: () => void;
  disabled?: boolean;
  customText?: string;
}

export const ConfirmButtonComponent = (props: OwnProps) => {
  const { handleClick, disabled, customText } = props;
  const { t } = useTranslation();

  return (
    <Button color="primary" variant="contained" disabled={disabled} onClick={() => handleClick()}>
      {customText || t('data:check.confirm')}
    </Button>
  );
};
