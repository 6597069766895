import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { License } from '../../model';

import { LicenseActionType, fetchLicenseSuccess, fetchLicenseFailure } from './license.actions';

const licenseUrl = 'license';

function* getLicense() {
  try {
    const license = yield* call<[boolean, string], SagaRequest<License>>(
      SagaRequestHelper.get,
      false,
      licenseUrl
    );
    yield put(fetchLicenseSuccess(license));
  } catch (e: any) {
    yield put(fetchLicenseFailure());
  }
}

export function* fetchLicenseSaga() {
  yield takeLatest(LicenseActionType.licenseFetch, getLicense);
}

export function* licenseSaga() {
  yield fork(fetchLicenseSaga);
}
