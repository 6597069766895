import { Action } from 'redux';
import { fork, takeLatest } from 'redux-saga/effects';
import { call, put } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { Scale } from '../../model';

import {
  setScaleTareValue as setScaleTareValueAction,
  setScaleTareValueFailure,
  setScaleTareValueSuccess,
  DevicesActionType,
} from './devices.actions';

const devicesUrl = 'devices';
const scalesUrl = 'scales';
const taringUrl = 'taring';

function* setTareValue(action: Action) {
  const { tareValue, scaleId, cleanup } = (action as ReturnType<typeof setScaleTareValueAction>)
    .payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<Scale>>(
      SagaRequestHelper.put,
      true,
      `${devicesUrl}/${scalesUrl}/${scaleId}/${taringUrl}`,
      { body: JSON.stringify({ tareValue }) }
    );
    yield put(setScaleTareValueSuccess());
  } catch (e: any) {
    yield put(setScaleTareValueFailure(cleanup));
  }
}

export function* setTareValueSaga() {
  yield takeLatest(DevicesActionType.scaleSetTare, setTareValue);
}

export function* devicesSaga() {
  yield fork(setTareValueSaga);
}
