import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import localForage from 'localforage';
import log from 'loglevel';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_LINE_ID } from '../../constants';
import { Line } from '../../model';
import { lineListSelector, currentLineSelector, changeLine, initLine } from '../../store';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
}));

export const LineSelectComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const lines = useSelector(lineListSelector);
  const currentLine = useSelector(currentLineSelector);

  useEffect(() => {
    async function getItem() {
      try {
        const chosenLineId = await localForage.getItem('chosenLineId');
        chosenLineId && dispatch(initLine(chosenLineId as string));
      } catch (e: any) {
        log.debug(e);
      }
    }
    getItem();
  }, [dispatch]);

  const handleLineChange = async (event: SelectChangeEvent<string>) => {
    event.target.value && dispatch(changeLine(event.target.value));
    try {
      await localForage.setItem('chosenLineId', event.target.value);
    } catch (e: any) {
      log.debug(e);
    }
  };

  const getLineName = (line: Line) => {
    return line.id === DEFAULT_LINE_ID ? t('data:line.allLines') : line.name;
  };

  return (
    <>
      {lines.length > 0 && (
        <StyledFormControl>
          <InputLabel id="lines-label" variant="outlined">
            {t('data:line.line')}
          </InputLabel>
          <Select
            id="mui-component-line-select"
            labelId="lines-label"
            label={t('data:line.line')}
            value={currentLine.id}
            onChange={handleLineChange}
            variant="outlined"
            data-testid={'lineSelect'}
          >
            {lines.map((line) => (
              <MenuItem key={line.id} value={line.id}>
                {getLineName(line)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      )}
    </>
  );
};
