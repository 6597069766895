import * as signalR from '@microsoft/signalr';
import log from 'loglevel';
import { Action, Dispatch, MiddlewareAPI, AnyAction } from 'redux';

import { requestNewTokens } from '../../http/request';
import { isLoggedInSelector } from '../authentication';

import { getAccessToken } from './../../auth/auth.service';
import { WebsocketConfig } from './websockets-config';
import {
  WebsocketActionType,
  startWsConnection,
  addConnection,
  removeConnection,
} from './websockets.actions';
import { websocketConnectionSelector, websocketConnectionsSelector } from './websockets.selectors';

const backendUrl = `${
  process.env.REACT_APP_BACKEND_HOST && process.env.REACT_APP_BACKEND_PORT
    ? `${process.env.REACT_APP_BACKEND_WS_PROTOCOL || 'wss'}://${
        process.env.REACT_APP_BACKEND_HOST
      }:${process.env.REACT_APP_BACKEND_PORT}`
    : ''
}/ws`;

const buildUrl = (url: string) => {
  return `${backendUrl}/${url}`;
};

const start = async (dispatch: Dispatch, url: string, connection: signalR.HubConnection) => {
  try {
    await connection.start();
    log.debug(`${url} connected`);
    dispatch(addConnection(url, connection));
  } catch (e: any) {
    log.debug(e);
    setTimeout(() => start(dispatch, url, connection), 5000);
  }
};

const updateTokens = async () => {
  try {
    await requestNewTokens(true);
  } catch (e: any) {
    log.debug();
  }
};

export const connect = (dispatch: Dispatch, config: WebsocketConfig) => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(buildUrl(config.url), {
      transport: signalR.HttpTransportType.WebSockets,
      skipNegotiation: true,
      accessTokenFactory: getAccessToken as () => Promise<string>,
    })
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (ctx) => {
        if (ctx.elapsedMilliseconds > 5000) {
          updateTokens();
        }
        return 5000;
      },
    })
    .build();

  for (const action of config.actions) {
    connection.on(action.methodName, (...args: any) => {
      log.debug(action.methodName);
      log.debug(...args);
      dispatch(action.actionCreator(...args));
    });
  }

  start(dispatch, config.url, connection);
  return connection;
};

const stop = async (dispatch: Dispatch, url: string, connection: signalR.HubConnection) => {
  try {
    await connection.stop();
    log.debug(`${connection.baseUrl} disconnected`);
    dispatch(removeConnection(url));
  } catch (e: any) {
    log.debug(e);
    setTimeout(() => stop(dispatch, url, connection), 5000);
  }
};

const disconnectAll = (dispatch: Dispatch, connections: [string, signalR.HubConnection][]) => {
  for (let [url, connection] of connections) {
    stop(dispatch, url, connection);
  }
};

const getConnectionFromState = (api: MiddlewareAPI<Dispatch<AnyAction>, any>, url: string) => {
  const state = api.getState();
  return websocketConnectionSelector(url)(state);
};

const getConnectionsFromState = (api: MiddlewareAPI<Dispatch<AnyAction>, any>) => {
  const state = api.getState();
  return websocketConnectionsSelector(state);
};

export const websocketsMiddleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: Action<WebsocketActionType>) => {
    const { type } = action;

    switch (type) {
      case WebsocketActionType.connectionStart:
        const startAction = action as ReturnType<typeof startWsConnection>;
        const state = api.getState();
        const isLoggedIn = isLoggedInSelector(state);
        if (isLoggedIn && !getConnectionFromState(api, startAction.payload.config.url)) {
          connect(api.dispatch, startAction.payload.config);
        }
        break;
      case WebsocketActionType.connectionsStop:
        const connections = getConnectionsFromState(api);
        disconnectAll(api.dispatch, connections);
        break;
    }

    return next(action);
  };
