import { ProductionRun } from '../../model';

export enum ProductionRunsWebsocketActionType {
  productionRunChanged = 'productionRun/changed/',
  productionTestRunChanged = 'productionRun/testRunChanged/',
  productionRunFinished = 'productionRun/finished/',
}

export const changedProductionRun = (productionRun: ProductionRun) => ({
  type: ProductionRunsWebsocketActionType.productionRunChanged,
  payload: { productionRun },
});

export const changedProductionTestRun = (productionRun: ProductionRun) => ({
  type: ProductionRunsWebsocketActionType.productionTestRunChanged,
  payload: { productionRun },
});

export const finishedProductionRun = (productionRun: ProductionRun) => ({
  type: ProductionRunsWebsocketActionType.productionRunFinished,
  payload: { productionRun },
});
