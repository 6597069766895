interface ItemWithId {
  id: string;
}

interface ItemWithIdAndOrder extends ItemWithId {
  order: number;
}

export const addItemToFirstPositionInArray = <T extends ItemWithId>(
  stateArray: T[] | undefined,
  item: T
) => {
  if (!stateArray) {
    return [item];
  }
  const foundIndex = stateArray.findIndex((foundItem: T) => foundItem.id === item.id);
  if (foundIndex !== -1) {
    stateArray.splice(foundIndex, 1);
  }
  stateArray.unshift(item);
  return stateArray;
};

export const addItemToArray = <T extends ItemWithId>(stateArray: T[] | undefined, item: T) => {
  if (!stateArray) {
    return [item];
  }
  const foundIndex = stateArray.findIndex((foundItem: T) => foundItem.id === item.id);
  if (foundIndex !== -1) {
    stateArray.splice(foundIndex, 1);
  }
  stateArray.push(item);
  return stateArray;
};

export const deleteItemFromArray = <T extends ItemWithId>(
  stateArray: T[] | undefined,
  id: string
) => {
  if (stateArray) {
    const foundIndex = stateArray.findIndex((item: T) => item.id === id);
    if (foundIndex !== -1) {
      stateArray.splice(foundIndex, 1);
    }
  }
  return stateArray;
};

export const updateArrayWithChangedItem = <T extends ItemWithId>(
  changedItem: T,
  stateArray: T[] | undefined
) => {
  if (!stateArray) {
    return [changedItem];
  }
  const itemIndex = stateArray.findIndex((item: T) => item.id === changedItem.id);
  if (itemIndex !== -1) {
    stateArray[itemIndex] = changedItem;
  } else {
    stateArray.push(changedItem);
  }
  return stateArray;
};

export const mergeItemIntoArray = <T extends ItemWithIdAndOrder>(
  stateArray: T[] | undefined,
  changedItem: T
) => {
  if (!stateArray) {
    return [changedItem];
  }
  const itemIndex = stateArray.findIndex((item: T) => item.id === changedItem.id);
  if (itemIndex !== -1) {
    stateArray.splice(itemIndex, 1);
  }
  const successorIndex = stateArray.findIndex((item: T) => item.order > changedItem.order);
  if (successorIndex !== -1) {
    stateArray.splice(successorIndex, 0, changedItem);
  } else {
    stateArray.push(changedItem);
  }
  return stateArray;
};

export const updateItemInArray = <T extends ItemWithId>(
  stateArray: T[] | undefined,
  changedItem: T
) => {
  if (stateArray) {
    const foundIndex = stateArray.findIndex((item: T) => item.id === changedItem.id);
    if (foundIndex !== -1) {
      stateArray.splice(foundIndex, 1, changedItem);
    }
  }
  return stateArray;
};
