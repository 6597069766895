import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

export interface OwnProps {
  value?: string | number;
  decimalScale?: number;
  suffix?: string;
}
export const FormattedNumberOutputComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form']);
  const { value, decimalScale, suffix } = props;

  return (
    <NumericFormat
      value={value}
      decimalScale={decimalScale ?? 3}
      decimalSeparator={(t('form:decimalSeparator') as string) || ','}
      displayType="text"
      suffix={suffix}
    />
  );
};
