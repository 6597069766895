import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { irisCustomColors } from '../../theme';

export const SuccessButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
}));

export const ErrorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

export const DisruptionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  border: '1px solid',
  backgroundColor: irisCustomColors.irisWhite,
  '&:hover': {
    backgroundColor: irisCustomColors.irisGrayBright,
    borderColor: theme.palette.error.main,
  },
}));
