import { ProductionOrder, ProductionRun, QueryParams } from '../../model';

export enum ProductionOrdersActionType {
  productionOrdersFetchAll = 'productionOrders/fetchAll/',
  productionOrdersFetchAllSuccess = 'productionOrders/fetchAll/success',
  productionOrdersFetchAllFailure = 'productionOrders/fetchAll/failure',
  productionOrdersReloadSilent = 'productionOrders/reloadSilent/',
  productionOrdersReloadSilentSuccess = 'productionOrders/reloadSilent/success',
  productionOrdersReloadSilentFailure = 'productionOrders/reloadSilent/failure',
  productionOrderStart = 'productionOrder/start/',
  productionOrderStartSuccess = 'productionOrder/start/success',
  productionOrderStartFailure = 'productionOrder/start/failure',
  productionOrderRemove = 'productionOrder/remove/',
  productionOrderSetSearchTerm = 'production/setSearchTerm/',
  productionOrdersCurrentPageSet = 'productionOrders/setPage/',
}

export const fetchProductionOrders = (query: QueryParams) => ({
  type: ProductionOrdersActionType.productionOrdersFetchAll,
  payload: { query },
});

export const fetchProductionOrdersSuccess = (
  productionOrders: ProductionOrder[],
  total: number
) => ({
  type: ProductionOrdersActionType.productionOrdersFetchAllSuccess,
  payload: { productionOrders, total },
});

export const fetchProductionOrdersFailure = () => ({
  type: ProductionOrdersActionType.productionOrdersFetchAllFailure,
});

export const reloadProductionOrdersSilent = (query: QueryParams) => ({
  type: ProductionOrdersActionType.productionOrdersReloadSilent,
  payload: { query },
});

export const reloadProductionOrdersSilentSuccess = (
  productionOrders: ProductionOrder[],
  total: number
) => ({
  type: ProductionOrdersActionType.productionOrdersReloadSilentSuccess,
  payload: { productionOrders, total },
});

export const reloadProductionOrdersSilentFailure = () => ({
  type: ProductionOrdersActionType.productionOrdersReloadSilentFailure,
});

export const startProductionOrder = (id: string) => ({
  type: ProductionOrdersActionType.productionOrderStart,
  payload: { id },
});

export const startProductionOrderSuccess = (productionRun: ProductionRun, orderId: string) => ({
  type: ProductionOrdersActionType.productionOrderStartSuccess,
  payload: { productionRun, orderId },
});

export const startProductionOrderFailure = () => ({
  type: ProductionOrdersActionType.productionOrderStartFailure,
});

export const removeProductionOrder = (id: string) => ({
  type: ProductionOrdersActionType.productionOrderRemove,
  payload: { id },
});

export const setSearchTermProduction = (searchTerm?: string) => ({
  type: ProductionOrdersActionType.productionOrderSetSearchTerm,
  payload: { searchTerm },
});

export const setProductionOrdersCurrentPageIndex = (pageIndex: number) => ({
  type: ProductionOrdersActionType.productionOrdersCurrentPageSet,
  payload: { pageIndex },
});
