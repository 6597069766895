import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AppRoutePath } from './routes';

export interface OwnProps extends RouteProps {
  isLoggedIn: boolean;
}

export const PublicRoute: FunctionComponent<OwnProps> = ({
  component: Component,
  isLoggedIn,
  ...rest
}) => {
  if (isLoggedIn) {
    return <Redirect to={`${AppRoutePath.root}`} />;
  }
  return <Route {...rest} component={Component} />;
};
