export const backendUrl = `${
  process.env.REACT_APP_BACKEND_HOST && process.env.REACT_APP_BACKEND_PORT
    ? `${process.env.REACT_APP_BACKEND_PROTOCOL || 'https'}://${
        process.env.REACT_APP_BACKEND_HOST
      }:${process.env.REACT_APP_BACKEND_PORT}`
    : ''
}`;

export const buildApiUrl = (url: string) => {
  return `${backendUrl}/api/${url}`;
};
