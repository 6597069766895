import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { Line } from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import { LinesActionType, fetchLinesSuccess, fetchLinesFailure } from './lines.actions';

const linesUrl = 'lines';

function* getLines() {
  try {
    const response = yield* call<[boolean, string], SagaRequest<{ data: Line[] }>>(
      SagaRequestHelper.get,
      true,
      linesUrl
    );
    yield put(fetchLinesSuccess(response.data));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchLinesFailure);
  }
}

export function* fetchLinesSaga() {
  yield takeLatest(LinesActionType.linesFetchAll, getLines);
}

export function* linesSaga() {
  yield fork(fetchLinesSaga);
}
