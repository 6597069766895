import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDebounce from '../../hooks/debounce.hook';

export interface OwnProps {
  onSearch: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const SearchFieldComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common', 'data']);
  const { defaultValue, onSearch, placeholder, disabled } = props;
  const defaultInput = defaultValue || '';
  const [searchTerm, setSearchTerm] = useState<string>(defaultInput);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <TextField
      defaultValue={defaultInput}
      id="searchField"
      data-testid="searchField"
      variant="outlined"
      type="search"
      fullWidth
      placeholder={placeholder ? placeholder : t('common:search')}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="large" />
          </InputAdornment>
        ),
        onChange: handleChange,
      }}
    />
  );
};
