import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { irisCustomColors } from '../../theme';

export const SampleBannerWidth = 240;

const PREFIX = 'SampleBannerComponent';

const classes = {
  banner: `${PREFIX}-banner`,
  shadow: `${PREFIX}-shadow`,
  triangle: `${PREFIX}-triangle`,
  bannerBox: `${PREFIX}-bannerBox`,
  sampleAmount: `${PREFIX}-sampleAmount`,
  samples: `${PREFIX}-samples`,
  centered: `${PREFIX}-centered`,
};

interface StylePropsWrapper {
  styleProps: {
    backgroundColor: string;
    textColor: string;
  };
}

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  color: styleProps.textColor,

  [`& .${classes.shadow}`]: {
    filter: 'drop-shadow(0px 10px 16px #00000029)',
  },

  [`& .${classes.banner}`]: {
    width: SampleBannerWidth,
    height: 54,
    background: styleProps.backgroundColor,
    position: 'absolute',
    clipPath: 'polygon(0 0, 100% 0,100% 100%, 15% 100%)',
    right: -12,
  },

  [`& .${classes.triangle}`]: {
    position: 'absolute',
    borderTop: '19px solid #7a5f45',
    borderRight: '12px solid transparent',
    top: 53.8,
    right: -12,
  },

  [`& .${classes.bannerBox}`]: {
    marginTop: 12,
    marginBottom: 9,
    marginLeft: 58,
    marginRight: 30,
  },

  [`& .${classes.sampleAmount}`]: {
    fontSize: 36,
    fontWeight: 'bold',
    display: 'inline-block',
  },

  [`& .${classes.samples}`]: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-block',
  },

  [`& .${classes.centered}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export interface OwnProps {
  sampleSize: number;
  customText?: string;
  customTextColor?: string;
  customBackgroundColor?: string;
}

export const SampleBannerComponent = (props: OwnProps) => {
  const { sampleSize, customText, customBackgroundColor, customTextColor } = props;
  const { t } = useTranslation(['data', 'form']);

  const backgroundColor = customBackgroundColor || irisCustomColors.irisGold;
  const textColor = customTextColor || irisCustomColors.irisBlack;

  return (
    <Root styleProps={{ backgroundColor, textColor }} data-testid="sampleBanner">
      <Box className={classes.shadow}>
        <Box className={classes.banner}>
          <div className={`${classes.bannerBox} ${customText ? classes.centered : ''}`}>
            <Typography
              className={classes.sampleAmount}
              data-testid={`${customText ? 'customBannerText' : 'sampleSizeNumber'}`}
            >
              {customText || sampleSize}
            </Typography>
            {!customText && (
              <Typography className={classes.samples} data-testid="sampleSizeText">
                &nbsp;
                {t('data:check.sampleBanner')}
              </Typography>
            )}
          </div>
        </Box>
        <Box className={classes.triangle}></Box>
      </Box>
    </Root>
  );
};
