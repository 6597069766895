import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import noImagePlaceholder from '../../../assets/img/no-image-placeholder.svg';
import { IMAGE_RATIO } from '../../../constants';
import { backendUrl } from '../../../http';

export interface ResponsiveProps {
  imageUrl?: string;
  altText?: string;
}

const CenteringContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 0,
}));

const LimitingContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 650,
  maxHeight: 650 / IMAGE_RATIO,
}));

const RatioContainer = styled('div')(({ theme }) => ({
  width: '100%',
  aspectRatio: `${IMAGE_RATIO}`,
}));

const FillingImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

export const ResponsiveImageComponent = (props: ResponsiveProps) => {
  const { imageUrl, altText } = props;
  const { t } = useTranslation(['form']);

  return (
    <CenteringContainer>
      <LimitingContainer>
        <RatioContainer>
          <FillingImage
            alt={altText || t('form:image')}
            src={imageUrl ? `${backendUrl}${imageUrl}` : noImagePlaceholder}
          />
        </RatioContainer>
      </LimitingContainer>
    </CenteringContainer>
  );
};
